import React from "react";

const Help = () => {
    return(<>
        <div className="row row-inner">
            <h1>Help</h1>
        </div>
    </>)
}
export default Help;
