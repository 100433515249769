import { keepPreviousData, useQuery } from '@tanstack/react-query';

import bankServiceInstance from '../../services/bank-service';

function useGetBankAccountTransactions(data) {

  return useQuery({
    queryKey: [bankServiceInstance.queryKeys.bankAccountTransactions + data?.accountId + data?.page],
    queryFn: () => bankServiceInstance.bankAccountTransactions(data),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetBankAccountTransactions;