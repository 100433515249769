import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { errorToast, successToast } from '../../utils/toastMassage';
import debtServiceInstance from '../../services/debt-service';

function useEditDbt() {

  const navigate = useNavigate();

  return useMutation({
    mutationFn: debtServiceInstance.editDbt,
    onSuccess: (data) => {
      successToast('Update Successfully');
      // setTimeout(() => {
      //   // window.localStorage.setItem('isLogedIn', true);
      //   navigate('/investment');
      // }, 1000);
    },
    onError: (error) => {
      console.log(error,'modified errr');
      errorToast('Something went wrong, please try again later')
    },
  });

}

export default useEditDbt;