import { useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import insuranceServiceInstance from '../../services/insurance-service';


function useAddInsurance() {

  return useMutation({
    mutationFn: insuranceServiceInstance.addInsurance,
    onSuccess: (data) => {
      successToast('Added Successfully')
    },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useAddInsurance;
