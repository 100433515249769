import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetPlanningRetirmentGraph(data) {

    return useQuery({
        queryKey: [PlanningServiceInstance.queryKeys.getPlanningRetirmentGraph + data?.age + data?.simulate ],
        queryFn: () => PlanningServiceInstance.getPlanningRetirmentGraph(data),
        refetchOnWindowFocus: false

    });
}

export default useGetPlanningRetirmentGraph;