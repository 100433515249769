import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useEditInvestment from "../hooks/investment/edit-investment";
import { FinIcon } from '../components/Common';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const FixedSchema = Yup.object().shape({

    couponRate: Yup.number()
        .min(0, 'Coupon Rate must be at least 0.')
        .max(30, 'This value is not allowed. Please fill between 0 and 30.')
        .required('Please enter Coupon Rate.').typeError("Coupon Rate Must be a number"),
    paymentFreq: Yup.string().trim().required('Payment Frequency is required'),
    category: Yup.string().required('Category is required.'),
    nextInterestPaymentDate:  Yup.string()
    .required('Please enter Next Interest Payment Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
    }),
    maturityDate:  Yup.string()
    .required('Please enter Maturity Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
    }),
    isTaxable: Yup.boolean(),
    investmentType: Yup.string().trim().required('Investment Type is required.'),
    buyingPrice: Yup.number().required('Please enter Invested Value.').typeError("Invested Value must be a number"),
    rating: Yup.string().trim().required('Rating is required.'),
    purchaseDate:  Yup.string()
    .required('Please enter Purchase Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
    }),
});


const FixedIncomeEdit = () => {
    const { mutate } = useEditInvestment();
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const location = useLocation()
    const item = location.state?.item
    const activeTab = location.state?.activeTab
    console.log("item", item)
    const navigate = useNavigate()
    const handleBackToInveshment = () => {
        navigate({
            pathname: '/investment',
            search: `?tab=${activeTab}`,
        });

        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });
        }, 100);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            investmentId: item.investmentId,
            instrumentName: item?.instrumentName || '',
            couponRate: item?.couponRate || '',
            category: item?.category || '',
            paymentFreq: item?.paymentFreq || '',
            nextInterestPaymentDate: formatDate(item?.nextInterestPaymentDate) || '',
            maturityDate: formatDate(item?.maturityDate) || '',
            isTaxable: item?.taxableType === "taxable" ? true : false, 
            investmentType: item?.investmentType || 'BONDS',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: formatDate(item?.purchasedDate) || '',
            rating: item?.rating || "",
        },
        validationSchema: FixedSchema,
        onSubmit: values => {
            console.log("data", values)
            try {
                const payload = {

                    paymentFreq: values.paymentFreq,
                    isTaxable: values.isTaxable,
                    category: values.category,
                    investmentName: values.name,
                    buyingPrice: Number(values.buyingPrice),
                    purchaseDate: values.purchaseDate,
                    quantity: values.quantity,
                    couponRate: values.couponRate,
                    nextInterestPaymentDate: values.nextInterestPaymentDate,
                    maturityDate: values.maturityDate,
                    rating: values.rating
                };
                const dataToSend = {
                    payload,
                    investmentId: values.investmentId,
                };


                mutate(dataToSend, {
                    onSuccess: (data) => {
                        formik.resetForm();
                        console.log('Successfully submitted:', data);
                        navigate({
                            pathname: '/investment',
                            search: `?tab=${activeTab}`
                        });
                    },
                    onError: (error) => {
                        console.log('Submission error:', error.response?.data || error.message);
                        console.error('Full error object:', error);
                    },
                });

            } catch (error) {
                console.error('An error occurred during submission:', error);
            }
        },
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Fixed Income</h3>
                <p className="text-b200 small">Update fixed-income basic details.</p>
            </div>
            <div className="col-auto">
                <button onClick={handleBackToInveshment} className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </button>
            </div>
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="instrumentName" className="small inter-500 text-b200 mb-2">Instrument Name</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="instrumentName"
                                name="instrumentName"
                                value={formik.values.instrumentName}
                                readOnly
                            />
                        </div>


                        <div className="col">
                            <label htmlFor="couponRate" className="small inter-500 text-b200 mb-2">Coupon Rate (%)</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="couponRate"
                                name="couponRate"
                                onChange={formik.handleChange}
                                value={formik.values.couponRate}
                            />
                            {formik.errors.couponRate && formik.touched.couponRate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.couponRate}
                                </p>
                            ) : null}
                        </div>


                        <div className="col">
                            <label htmlFor="rating" className="small inter-500 text-b200 mb-2">Rating</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="rating"
                                name="rating"
                                onChange={formik.handleChange}
                                value={formik.values.rating}
                            />
                            {formik.errors.rating && formik.touched.rating ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.rating}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="category" className="small inter-500 text-b200 mb-2">Category</label>
                            <select
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                value={formik.values.category}
                            >
                                <option value="Fixed_Deposit">Fixed Deposit</option>
                                <option value="Corporate">Corporate</option>
                                <option value="Government">Government</option>
                            </select>
                            {formik.errors.category && formik.touched.category ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.category}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="paymentFreq" className="small inter-500 text-b200 mb-2">Payment Frequency</label>
                            <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="paymentFreq"
                                name="paymentFreq"
                                onChange={formik.handleChange}
                                value={formik.values.paymentFreq}
                            >
                                <option value="">-- Select --</option>
                                <option value="Semi Annually">Semi Annually</option>
                                <option value="Annually">Annually</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Quarterly">Quarterly</option>
                            </select>
                            {formik.errors.paymentFreq && formik.touched.paymentFreq ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.paymentFreq}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="nextInterestPaymentDate" className="small inter-500 text-b200 mb-2">Next Interest Payment Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="nextInterestPaymentDate"
                                name="nextInterestPaymentDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.nextInterestPaymentDate}
                            />
                            {formik.errors.nextInterestPaymentDate && formik.touched.nextInterestPaymentDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.nextInterestPaymentDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="maturityDate"
                                name="maturityDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.maturityDate}
                            />
                            {formik.errors.maturityDate && formik.touched.maturityDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.maturityDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="isTaxable" className="small inter-500 text-b200 mb-2">Is Taxable</label>
                            <select
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="isTaxable"
                                name="isTaxable"
                                onChange={(e) => formik.setFieldValue('isTaxable', e.target.value === "true")}
                                value={formik.values.isTaxable ? "true" : "false"} // Ensure boolean mapping
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {formik.errors.isTaxable && formik.touched.isTaxable ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.isTaxable}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="investmentType" className="small inter-500 text-b200 mb-2">Investment Type<strong className="text-danger">*</strong></label>
                            <input
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="investmentType"
                                name="investmentType"
                                value={formik.values.investmentType}
                                readOnly
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Invested Value</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="buyingPrice"
                                name="buyingPrice"
                                onChange={formik.handleChange}
                                value={formik.values.buyingPrice}
                            />
                            {formik.errors.buyingPrice && formik.touched.buyingPrice ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.buyingPrice}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="purchaseDate"
                                name="purchaseDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.purchaseDate}
                            />
                            {formik.errors.purchaseDate && formik.touched.purchaseDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.purchaseDate}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-2 mt-xl-4">
                        <div className="col">
                            <button
                                type="button"
                                className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                                // disabled={usePostInvestmentMutation?.isPending}
                                onClick={formik.handleSubmit}
                            >
                                <FinIcon name="save" className="ts-1p5 me-3" />
                                Update Fixed Income
                                {/* {usePostInvestmentMutation?.isPending && (
                            <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )} */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FixedIncomeEdit;
