import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import useUserLogin from "../hooks/auth-service/user-login";
import 'react-toastify/dist/ReactToastify.css';

import { loginSchema } from "../utils/validationSchema";
import { FinIcon, PasswordView } from "../components/Common";

const Login = () => {

	// init
	const loc = useLocation();

	//api call
	const useUserLoginMutation = useUserLogin()

	function isTenDigitNumber(str) {
		// Check if the string consists of exactly 10 digits
		return /^\d{10}$/.test(str);
	}

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { emailmobile: "", password: "" },
		validationSchema: loginSchema,
		onSubmit: values => {
			// console.log(values, isTenDigitNumber(formik.values.emailmobile));
			let payload = isTenDigitNumber(formik.values.emailmobile) ? {
				mobile: formik.values.emailmobile,
				password: formik.values.password,
			} : {
				email: formik.values.emailmobile,
				password: formik.values.password,
			}

			useUserLoginMutation.mutate(payload)
		}
	})


	return (<>
		<section className="entry-section">
			<div className="entry-leftbox">
				<img src="images/lines-bg.svg" tabIndex={-1} alt="line pattern" className="entry-bgpattern" />
				<div className="d-flex flex-column position-relative h-100">
					<img src="images/finactive-logo.svg"
						width={137}
						height={18}
						alt="Finactive Logo"
						className="entry-logo"
					/>
					<div className="py-4 my-auto">
						<div className="ratio ratio-1x1 d-block">
							<img src="images/entry-poster.png" alt="poster" />
						</div>
						<h1>
							All your investments in <span>one centralized location.</span>
						</h1>
						<p>
							Seamlessly organize and monitor all your investments in a single centralized hub for enhanced financial control.
						</p>
					</div>
					<div className="d-flex justify-content-between">
						<div className="entry-pointbox">
							<span>
								<img src="icons/icon-star-fill.svg" alt="icon-star" />
							</span>
							<small>5.0 Rating with 1,000 Reviews</small>
						</div>
						<div className="entry-pointbox">
							<span>
								<img src="icons/icon-app-web.svg" alt="icon-app-web" />
							</span>
							<small>Available on App and Web</small>
						</div>
						<div className="entry-pointbox">
							<span>
								<img src="icons/icon-simulator.svg" alt="icon-simulator" />
							</span>
							<small>Simulator Access</small>
						</div>
						<div className="entry-pointbox">
							<span>
								<img src="icons/icon-man-rupee.svg" alt="icon-man-rupee" />
							</span>
							<small>Track Net Worth</small>
						</div>
					</div>
				</div>
			</div>
			<div className="entry-rightbox">
				<form className="entry-controls">
					{/* Login Section | Section */}
					{
						loc.pathname === '/' && <>
							<h2 className="er-heading">Welcome back!</h2>
							<p className="er-paragraph mb-4">
								Begin enhancing your financial management with speed and precision.
							</p>

							<div className="control-withicon mb-3">
								<span className="control-icon">
									<FinIcon name="mobemail" />
								</span>
								<input type="text" className="form-control form-control-fin"
									defaultValue={formik.values.emailmobile}
									onBlur={formik.handleBlur('emailmobile')}
									onInput={formik.handleChange('emailmobile')}
									placeholder="Email or mobile number"
									name="username"
									autoComplete="username"
								/>
							</div>
							{(formik.errors.emailmobile && formik.touched.emailmobile) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
								{formik.errors.emailmobile}
							</p> : null}
							<p className="er-paragraph mh-2">

							</p>
							<div className="control-withicon mb-3">
								<span className="control-icon">
									<FinIcon name="lock" />
								</span>
								<input type="password" id="pass" className="form-control form-control-fin"
									defaultValue={formik.values.password}
									onInput={formik.handleChange('password')}
									placeholder="Your password"
									name="password"
									autoComplete="current-password"
								/>
								<PasswordView passingid="pass" />
							</div>
							<div className="text-end mb-3 mb-xl-4">
								<Link to={'/forgot-password'} className="inter-600 text-link">Forgot password?</Link>
							</div>
							<button type="button" className="btn btn-primary btn-fin w-100" disabled={useUserLoginMutation?.isPending} onClick={formik.handleSubmit}>Login {useUserLoginMutation?.isPending && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>

							<div className="or-line py-3 py-lg-4">or</div>
							<div className="d-flex gap-3 gap-lg-4">
								<Link to={'/'} className="btn btn-outline-light btn-fin w-50 text-b300 d-flex align-items-center justify-content-center gap-2">
									<img src="icons/google-icon.svg" className="mmwh-25" alt="Google Icon" />
									<span>Google</span>
								</Link>
								<Link to={'/'} className="btn btn-outline-light btn-fin w-50 text-b300 d-flex align-items-center justify-content-center gap-2">
									<img src="icons/facebook-icon.svg" className="mmwh-25" alt="Facebook Icon" />
									<span>Facebook</span>
								</Link>
							</div>
							<div className="text-center text-b100 mt-4 mt-xl-5">
								Don’t have an account? <Link to={'/forgot-password'} className="inter-600 text-link">Sign Up</Link>
							</div>
						</>
					}

					{/* Forgot Password | Section */}
					{
						loc.pathname === '/forgot-password' && <>
							<h2 className="er-heading">Forgot password</h2>
							<p className="er-paragraph mb-4">
								Please enter your registered mobile number for reset password.
							</p>
							<div className="control-withicon mb-3 mb-xl-4">
								<span className="control-icon">
									<FinIcon name="mobile" />
								</span>
								<input type="tel" className="form-control form-control-fin" placeholder="Enter mobile number" />
							</div>
							<button type="button" className="btn btn-primary btn-fin w-100">Send Verification Code</button>
							<div className="text-b100 mt-4 mt-xl-5">
								Back to <Link to={'/login'} className="inter-600 text-link">Sign In</Link>
							</div>
						</>
					}

				</form>
			</div>
		</section>
	</>)
}
export default Login;
