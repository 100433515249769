import React from "react";
import Chart from 'react-apexcharts';
import { formatNumberWithCommas } from "../utils/common";
const LoanJourneyChart = ({ ...props }) => {

    console.log(props?.data)
    var ttl = props.data.loanValue
    const state = {
        series: [{
            name: 'Interest',
            type: "area",
            data: props.data.journey.map((itm) => {
                return (itm.totalInterestPaid)
            })
        }, {
            name: 'Capital',
            type: "area",
            data: props.data.journey.map((itm) => {
                return (itm.totalPrincipalPaid + itm.totalInterestPaid)
            })
        }, {
            name: 'Loan',
            type: "area",
            data: props.data.journey.map((itm, index) => {

                if (ttl > itm.emi) {
                    ttl -= itm.principalPaid;
                    // console.log( 'ttl',ttl, itm.emi)

                    return (ttl)

                }
                else {
                    // console.log( 'ttl',ttl, itm.emi)

                    return 0

                }

                //                    return 1000000 -(1000*index)

            })
        }],
        options: {
            chart: {
                type: 'area',
                columnWidth: '45%',
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: ['1', '1', '1'],
                curve: 'smooth'
            },
            labels: props.data.journey.map((itm) => {
                return itm.date
            }),
            yaxis: [
                {
                    labels: {
                        formatter: function (val) {
                            return (  formatNumberWithCommas (Math.floor( val))  );
                        }
                    }
                }
            ],
            xaxis: {
                // type: 'date',
                type: 'datetime',
                categories: [],
            },
            colors: ['#FF007A', '#00DA71', '#0065FF'],
            legend: { show: false },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.4
                }
            }
        }
    }

    return (
        <Chart options={state.options} series={state.series} height={props.height} />
    )
}

export default LoanJourneyChart;