import React, { useState } from "react";

import AccountSettings from "../components/AccountSettings";
import PersonalInformation from "../components/PersonalInformation";
import EmailPreferences from "../components/EmailPreferences";
import SecuritySettings from "../components/SecuritySettings";
import FinancialAccounts from "../components/FinancialAccounts";
import AuthorisedDevices from "../components/AuthorisedDevices";

const Settings = () => {
    
    const [showhide, setShowhide] = useState({
        account_settings: true,
        personal_information: false,
        email_preferences: false,
        security_settings: false,
        financial_accounts: false,
        authorised_devices: false
    });

    const showhideMethod = (tf) => {
        setShowhide(prevState => {
            const nextState = {}
            Object.keys(prevState).forEach(key => {
              nextState[key] = false
            })
            return {...nextState, ...tf}
        })
    }

    return(<>
        {
            showhide.account_settings && <AccountSettings passingMethod={showhideMethod}/>
        }
        {
            showhide.personal_information && <PersonalInformation passingMethod={showhideMethod}/>
        }
        { 
            showhide.email_preferences && <EmailPreferences passingMethod={showhideMethod}/> 
        }
        { 
            showhide.security_settings && <SecuritySettings passingMethod={showhideMethod}/> 
        }
        { 
            showhide.financial_accounts && <FinancialAccounts passingMethod={showhideMethod}/> 
        }
        { 
            showhide.authorised_devices && <AuthorisedDevices passingMethod={showhideMethod}/> 
        }
    </>)
}
export default Settings;
