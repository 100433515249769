import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FinIcon } from './Common';
import useUpdateUser from "../hooks/user-service/put-User";
import useGetUser from "../hooks/user-service/get-User";
const PersonalInfoSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().required("Date of Birth is required"),
    dependentsCount: Yup.number().min(0, "Must be 0 or more").required("Dependents count is required"),
    isMarried: Yup.boolean().required("Marital status is required"),
    isIncomeStable: Yup.boolean().required("Income stability status is required"),
});

const PersonalInformation = ({ userId }) => {

    const { data: userData, isLoading, isFetching, refetch :refetchProfileinformation } = useGetUser({
        mobile: "9039730708",
        password: '12345678'
    });
    
    const item = userData?.data?.result || {};
    
    const { mutate } = useUpdateUser();

    return (
        <div>
            {isLoading || isFetching ? (
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100vh' 
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        firstName: item?.firstName || "",
                        lastName: item?.lastName || "",
                        dateOfBirth: item?.dob || "",
                        dependentsCount: item?.dependentsCount || 0,
                        isMarried: item?.isMarried || false,
                        isIncomeStable: item?.isIncomeStable || false,
                        mobile: item?.mobile || "",  
                        email: item?.email || "",    
                    }}
                    enableReinitialize
                    validationSchema={PersonalInfoSchema}
                    onSubmit={(values) => {

                        try {
                            const payload = {
                                firstName: values.firstName.trim(),
                                lastName: values.lastName.trim(),
                                dateOfBirth: values.dateOfBirth,
                                dependentsCount: Number(values.dependentsCount),
                                isMarried: Boolean(values.isMarried),
                                isIncomeStable: Boolean(values.isIncomeStable),
                            };

                            console.log("Payload to be sent:", payload);

                            const dataToSend = {
                                payload: payload,
                                userId,
                            };

                            mutate(dataToSend, {
                                onSuccess: (data) => {
                                    refetchProfileinformation()
                                    console.log('Successfully submitted:', data);
                                    
                                },
                                onError: (error) => {
                                    console.log('Submission error:', error.response?.data || error.message);
                                    console.error('Full error object:', error);
                                },
                            });
                        } catch (error) {
                            console.error('An error occurred during submission:', error);
                        }
                    }}
                >
                    {() => (
                        <Form>
                            <div className="row row-inner">
                                <div className="col align-self-center">
                                    <h3 className="inter-700">Personal Information</h3>
                                </div>
                                <div className="col-12 mt-1">
                                    <p className="text-b200 small">
                                        Edit your basic details.
                                    </p>
                                </div>

                                <div className="col-12 mt-1 mt-xl-2 mb-4">
                                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                                        {/* Personal Information Section */}
                                        <div className="d-flex align-items-center gap-3 mb-3">
                                            <FinIcon name="users" className="ts-1p4 text-cyan" />
                                            <span className="inter-600 fs-18px">Personal Information</span>
                                        </div>

                                        <div className="row row-cols-1 row-cols-sm-2 g-3 g-lg-4">
                                            <div className="col">
                                                <label htmlFor="firstName" className="small inter-500 text-b200 mb-2">First Name</label>
                                                <Field type="text" id="firstName" name="firstName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" />
                                                <ErrorMessage name="firstName" component="div" className="text-danger small" />
                                            </div>

                                            <div className="col">
                                                <label htmlFor="lastName" className="small inter-500 text-b200 mb-2">Last Name</label>
                                                <Field type="text" id="lastName" name="lastName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" />
                                                <ErrorMessage name="lastName" component="div" className="text-danger small" />
                                            </div>

                                            <div className="col">
                                                <label htmlFor="dateOfBirth" className="small inter-500 text-b200 mb-2">Date of Birth</label>
                                                <Field type="date" id="dateOfBirth" name="dateOfBirth" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" />
                                                <ErrorMessage name="dateOfBirth" component="div" className="text-danger small" />
                                            </div>

                                            <div className="col">
                                                <label htmlFor="dependentsCount" className="small inter-500 text-b200 mb-2">Dependents Count</label>
                                                <Field as="select" id="dependentsCount" name="dependentsCount" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                </Field>
                                                <ErrorMessage name="dependentsCount" component="div" className="text-danger small" />
                                            </div>

                                            <div className="col">
                                                <label htmlFor="isMarried" className="small inter-500 text-b200 mb-2">Married</label>
                                                <Field as="select" id="isMarried" name="isMarried" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </Field>
                                                <ErrorMessage name="isMarried" component="div" className="text-danger small" />
                                            </div>

                                            <div className="col">
                                                <label htmlFor="isIncomeStable" className="small inter-500 text-b200 mb-2">Is Income Stable?</label>
                                                <Field as="select" id="isIncomeStable" name="isIncomeStable" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </Field>
                                                <ErrorMessage name="isIncomeStable" component="div" className="text-danger small" />
                                            </div>

                                            {/* Read-only Mobile Number */}
                                            <div className="col">
                                                <label htmlFor="mobile" className="small inter-500 text-b200 mb-2">Mobile Number</label>
                                                <Field type="text" id="mobile" name="mobile" className="form-control form-control-fin inter-500 bg-light px-3 shadow-sm" readOnly />
                                            </div>

                                            {/* Read-only Email */}
                                            <div className="col">
                                                <label htmlFor="email" className="small inter-500 text-b200 mb-2">Email</label>
                                                <Field type="email" id="email" name="email" className="form-control form-control-fin inter-500 bg-light px-3 shadow-sm" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default PersonalInformation;
