import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  emailmobile: yup.string()
    .required("Email/Phone Number is required")
    .test('test-name', 'Enter Valid Phone/Email', function (value) {
      const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
      const isValidEmail = emailRegex.test(value);
      const isValidPhone = phoneRegex.test(value);
      return isValidEmail || isValidPhone;
    }),
});

export const addFixedDepositSchema = yup.object().shape({
  bankname: yup
    .string()
    .trim()
    .required("Bank name is required"),
  
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number"),
  
  rateofinterest: yup
  .number()
  .required("Interest Rate is required")
  .typeError("Interest Rate must be a number")
  .min(0, "Interest Rate must be at least 0")
  .max(100, "Interest Rate must be at most 100"),
  
  tenure: yup
    .number()
    .required("Tenure is required")
    .typeError("Tenure must be a number"),
  
  category: yup
    .string()
    .required("Category is required"),
  // insights: yup.string().required("Insights name is required"),
});

export const FixedDepositSchemaEdit = yup.object().shape({
  bankname: yup
    .string()
    .trim()
    .required("Bank name is required"),
  
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number"),
  rateofinterest: yup .number()
  .required("Interest Rate is required")
  .typeError("Interest Rate must be a number")
  .min(0, "Interest Rate must be at least 0")
  .max(100, "Interest Rate must be at most 100"),
  
  tenure: yup
    .number()
    .required("Tenure is required")
    .typeError("Tenure must be a number"),
  
  category: yup
    .string()
    .required("Category is required"),
  

});

export const addBankAccountsSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .trim()
    .required("Account Number is required"),
  ifscCode: yup
    .string()
    .trim()
    .required("IFSC code is required"),

  branch: yup
    .string()
    .trim()
    .required("Branch name is required"),

  bankName: yup
    .string()
    .trim()
    .required("Bank name is required"),

  currentBalance: yup
    .number()
    .required("Current Balance is required")
    .typeError("Current Balance must be a number"),
  accountType: yup
    .string()
    .required("Account Type is required"),

  holderName: yup
    .string()
    .trim()
    .required("Holder Name is required")
});
export const editBankAccountsSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .trim()
    .required("Account Number is required"),
  ifscCode: yup
    .string()
    .trim()
    .required("IFSC code is required"),

  branch: yup
    .string()
    .trim()
    .required("Branch name is required"),

  bankName: yup
    .string()
    .trim()
    .required("Bank name is required"),

  currentBalance: yup
    .number()
    .required("Current Balance is required")
    .typeError("Current Balance must be a number"),
  accountType: yup
    .string()
    .required("Account Type is required"),

  holderName: yup
    .string()
    .trim()
    .required("Holder Name is required")
    .matches(/^[a-zA-Z\s]*$/, "Valid Holder Name"),
});

export const addCreditCardSchema = yup.object().shape({
  issuerName: yup
    .string()
    .trim()
    .required("Issuer Name is required"),
  cardNumber: yup
  .string()
  .matches(/^\d+$/, "Card Number must only contain numbers")
  .min(13, "Card Number must be at least 13 digits")
  .max(19, "Card Number must be no more than 19 digits")
  .required("Card Number is required"),
  cardType: yup.string().required("Card Type is required"),
  creditLimit: yup.number().required("Credit Limit is required").typeError("Credit Limit must be a number"),
  usedCreditLimit: yup.number().required("Used Credit Limit is required"),
  interestRate: yup
    .number()
    .required("Interest Rate is required")
    .typeError("Interest Rate must be a number")
    .min(0, "Interest Rate must be at least 0")
    .max(100, "Interest Rate must be at most 100"),
  dueDate: yup.string()
    .required('Please enter Due Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') // Check date format
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; // If value is empty, return false

      const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
    }),
  minimumDue: yup.number().required("Minimum Due is required").typeError("Credit Limit must be a number"),
  lastAmountDue: yup.number().required("Last Amount Due is required").typeError("Credit Limit must be a number"),

  
});

export const CreditCardSchemaEdit = yup.object().shape({
  issuerName: yup
    .string()
    .trim()
    .required("Issuer Name is required"),

    cardNumber: yup
    .string()
    .matches(/^\d+$/, "Card Number must only contain numbers")
    .min(13, "Card Number must be at least 13 digits")
    .max(19, "Card Number must be no more than 19 digits")
    .required("Card Number is required"),

  cardType: yup
    .string()
    .trim() 
    .required("Card Type is required"),

  creditLimit: yup
    .number()
    .required("Credit Limit is required")
    .typeError("Credit Limit must be a number"),

  usedCreditLimit: yup
    .number()
    .required("Used Credit Limit is required")
    .typeError("Used Credit Limit must be a number"),

  interestRate: yup
  .number()
  .required("Interest Rate is required")
  .typeError("Interest Rate must be a number")
  .min(0, "Interest Rate must be at least 0")
  .max(100, "Interest Rate must be at most 100"),
  dueDate:yup.string()
  .required('Please enter Due Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') // Check date format
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; // If value is empty, return false

      const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
  }),

  minimumDue: yup
    .number()
    .required("Minimum Due is required")
    .typeError("Minimum Due must be a number"),

  lastAmountDue: yup
    .number()
    .required("Last Amount Due is required")
    .typeError("Last Amount Due must be a number"),
});

export const addDebtSchema = yup.object().shape({
  loanType: yup.string().required('Please select loan type.'),
  lender: yup.string().required('Please select lender.'),
  amount: yup.number().required('Please enter integer value.'),
  emi: yup.number().required('Please enter integer value.'),
  interestRate: yup.number()
    .required('Please enter integer value.')
    .min(0, 'Interest rate must be greater than or equal to 0.')
    .max(30, 'Interest rate is abnormally high, please review it.'),
  loanStartDate: yup.string()
  .required('Please enter loan Start Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  dueDate: yup.string()
  .required('Please enter Due Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  principalOutstanding: yup.number().required('Please enter integer value.'),
  tenureInMonths: yup.number().required('Please select tenure.'),
  // remainingTenure: Yup.number().required('Please select remaining tenure.'),
  // insights: Yup.string().required('Please enter insights.'),
});



export const addInsuranceSchema = yup.object().shape({
  policyName: yup.string().required("Policy Name is required"),
  sumAssured: yup.number().required("Sum Assured is required"),
  premiumAmount: yup.number().required("Premium is required"),
  premiumFrequency: yup.string().oneOf(['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'], 'Invalid premium frequency').required('Premium frequency is required'),
  premiumDate:yup.string()
  .required('Please enter Premium Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  maturityDate: yup.string()
  .required('Please enter Maturity Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  ownership: yup
  .string()
  .required("Ownership / Nominee is required")
  .matches(/^[A-Za-z\s]+$/, "Ownership / Nominee Name should contain only letters"),
  insuranceFiType: yup.string().required("Investment Link is required"),
  riderType: yup.string().required("Rider is required"),

});

export const editInsuranceSchema = yup.object().shape({
  policyName: yup.string().required("Policy Name is required"),
  sumAssured: yup.number().required("Sum Assured is required"),
  premiumAmount: yup.number().required("Premium is required"),
  premiumFrequency: yup.string().oneOf(['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'], 'Invalid premium frequency').required('Premium frequency is required'),
  premiumDate: yup.string()
  .required('Please enter Premium Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 
      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  maturityDate: yup.string()
  .required('Please enter Maturity Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 
      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; 
  }),
  ownership: yup
  .string()
  .required("Ownership / Nominee is required")
  .matches(/^[A-Za-z\s]+$/, "Ownership / Nominee Name should contain only letters"),
  insuranceFiType: yup.string().required("insuranceFiType Due is required"),
  riderType: yup.string().required("Rider is required"),

});


export const addMutualFundsSchema = yup.object().shape({
  mutualFundId: yup.string().required("Mutual Fund Id is required"),
  quantity: yup.number().required("Quantity is required").typeError("Amount must be a number"),
  mode: yup.string().required("Mode is required"),
  buyingPrice: yup.number().required("Buying Price Date is required").typeError("Buying Price must be a number"),
  purchaseDate: yup.string()
    .required('Please enter Purchase Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; 

      const year = parseInt(value.split('-')[0], 10); 
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
    }),
  
      nav: yup.number().required("Nav is required")
});



export const addAlternateInvestmentsSchema = yup.object().shape({
  investmentName: yup
  .string()
  .required("Investment Name is required"),
  currentPrice: yup.number().required("Current Price is required"),
  buyingPrice: yup.number().required("Buying Price  is required"),
  
  purchaseDate: yup.string()
    .required('Please enter Purchase Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') // Check date format
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
      if (!value) return false; // If value is empty, return false

      const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
      return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
    }),

});

export const editAlternateInvestmentsSchema = yup.object().shape({
  investmentName: yup
  .string()
  .required("Investment Name is required"),
  currentPrice: yup.number().required("Current Price is required"),
  buyingPrice: yup.number().required("Buying Price  is required"),
  purchaseDate:  yup.string()
  .required('Please enter Purchase Date.')
  .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
  .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
    if (!value) return false; 

    const year = parseInt(value.split('-')[0], 10); 
    return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
  }),

});
export const addGoalSchema = yup.object().shape({
  sharePercentage: yup.number()
  .min(1, 'Share Percentage must be at least 1')
  .max(100, 'Share Percentage cannot be more than 100')
  .required('Share Percentage is required'),
goalId: yup.string()
  .required('You must select a goal'),
});

export const addPlanningEstimatesSchema = yup.object().shape({
  fiscalYear: yup.number().required("Finacial Year is required"),
  savings: yup.number().required("Savings is required"),
  expense: yup.number().required("Expense is required"),
  income: yup.number().required("Income is required"),
  debt: yup.number().required("dept pay down is required"),

});

export const addPlanningGoalSchema = yup.object().shape({
  name: yup
  .string()
  .trim()
  .required("Name is required"),
  amount: yup.number().required("Amount is required").typeError("Amount must be a number"),
  goalDate: yup
  .date()
  .required("Goal Date is required")
  .min(new Date(), "Goal Date must be in the future") 
  .typeError("Invalid date format"), 
   forwhom: yup.string().optional(),

});

export const linkGoalInvesmentSchema = yup.object().shape({
  sharePercentage: yup.number().required("Share Percentage is required"),
  // goalId: yup.string().required("Goal is required")


});