import React from "react";
import Chart from 'react-apexcharts';

const DashboardAllTimeChart = ({...props}) => {
    const state = {
        series: [{
            name: "Price",
            type: "area",
            data: [
                1000.0,
                1500.0,
                2000.0,
                2500.0,
                3000.0,
                4000.0,
                6000.0,
                8000.0,
                12000.0,
                13000.0,
                14000.0,
                15000.0,
                16000.0,
                17000.0,
                18000.0,
                19000.0,
                20000.0,
                21000.0,
                22000.0,
                25000.0
            ],
        }],          
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3],
                curve: 'smooth'
            },
            labels: [
                "13 Nov 2017",
                "14 Nov 2017",
                "15 Nov 2017",
                "16 Nov 2017",
                "17 Nov 2017",
                "20 Nov 2017",
                "21 Nov 2017",
                "22 Nov 2017",
                "23 Nov 2017",
                "24 Nov 2017",
                "27 Nov 2017",
                "28 Nov 2017",
                "29 Nov 2017",
                "30 Nov 2017",
                "01 Dec 2017",
                "04 Dec 2017",
                "05 Dec 2017",
                "06 Dec 2017",
                "07 Dec 2017",
                "08 Dec 2017"
            ],
            xaxis: {
                type: 'datetime',
            },
            legend: { show: false },
            colors:['#0065FF'],
            fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 0.40
                }
            }
        }
    }

    return (
        <Chart options={state.options} series={state.series} height={props.height} />
    )    
}

export default DashboardAllTimeChart;