import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Aside from "./Aside"
import Header from "./Header"
import Footer from "./Footer"

const Layout = () => {
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo({top: 0});
    }, [location]);

    return(<>
        <Aside />
        <Header />
        <main className="fin-mainwrapper">
            <div className="fin-innerwrapper">
                <Outlet />
            </div>
            <Footer />
        </main>
    </>)
}

export default Layout;