import React, { useState } from "react";
import { FinIcon, AddInvestmentBack } from '../components/Common';
import useGetMutualFunds from "../hooks/investment/get-mutual-funds";
import { useFormik } from "formik";
import { addMutualFundsSchema } from "../utils/validationSchema";
import usePostinvestment from "../hooks/investment/post-investment";
import Select from 'react-select';
import { useLocation, useNavigate } from "react-router-dom";
const MutualFundAdd = ({ ...props }) => {

    const location = useLocation();
    const activeTab = location.state?.activeTab

    const navigate = useNavigate()
    // API calls

    const { data: mutualFundsData, isLoading: mutualFundsIsLoading } = useGetMutualFunds();
    const usePostinvestmentMutation = usePostinvestment();

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { mutualFundId: "", quantity: '', nav: '', mode: '', investmentType: 'MUTUAL_FUNDS', buyingPrice: '', purchaseDate: '' },
        validationSchema: addMutualFundsSchema,
        onSubmit: values => {

            let payload = {
                mutualFundId: formik.values.mutualFundId,
                nav: formik.values.nav,
                quantity: Number(formik.values.quantity),
                mode: formik.values.mode,
                investmentType: formik.values.investmentType,
                buyingPrice: Number(formik.values.buyingPrice),
                purchaseDate: formik.values.purchaseDate,
            };

            usePostinvestmentMutation.mutate(payload, {
                onSuccess: () => {
                    formik.resetForm();
                    navigate({
                        pathname: '/investment',
                        search: `?tab=${activeTab}`
                    });    
                },
            });
        }
    });


    const mutualFundsOptions = mutualFundsData?.data?.result?.map((item) => ({
        value: item.id,
        label: item.name
    })) || [];



    const customFilter = (inputValue, option) => {
        // Normalize and remove spaces for both the input and the label
        const normalizedInput = inputValue.toLowerCase().replace(/\s+/g, '');
        const normalizedLabel = option.label.toLowerCase().replace(/\s+/g, '');
    
        // First check: Does the normalized input (no spaces) match the normalized label?
        if (normalizedLabel.includes(normalizedInput)) {
            return true;
        }
    
        // If not, split input and label into words, and check for partial matches
        const inputWords = inputValue.toLowerCase().trim().split(/\s+/);
        const labelWords = option.label.toLowerCase().trim().split(/\s+/);
    
        // Check if every input word is included somewhere in the label words
        return inputWords.every(word => labelWords.some(labelWord => labelWord.includes(word)));
    };
    
    
    
    


    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add New Mutual Fund</h3>
                <p className="text-b200 small">
                    Add New Mutual Fund basic details.
                </p>
            </div>
            <div className="col-auto">
                <AddInvestmentBack value="MUTUAL_FUNDS" activeTab={activeTab} />
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">

                        {/* Mutual Fund ID Select */}
                        <div className="col">
                            <label htmlFor="mutualFundId" className="small inter-500 text-b200 mb-2">Mutual Fund Name </label>
                            <Select
                                id="mutualFundId"
                                name="mutualFundId"
                                options={mutualFundsOptions}
                                value={mutualFundsOptions.find(option => option.value === formik.values.mutualFundId)}
                                onChange={(selectedOption) => formik.setFieldValue('mutualFundId', selectedOption?.value)}
                                onBlur={formik.handleBlur}
                                isSearchable
                                classNamePrefix="react-select"
                                className="react-select-container"
                                filterOption={(option, inputValue) => customFilter(inputValue, option)}
                            />
                            {formik.errors.mutualFundId && formik.touched.mutualFundId && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.mutualFundId}
                                </p>
                            )}
                        </div>

                        {/* Quantity Input */}
                        <div className="col">
                            <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                            <input
                                type="text"
                                id="quantity"
                                name="quantity"
                                value={formik.values.quantity}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                placeholder="Enter Quantity"
                            />
                            {formik.errors.quantity && formik.touched.quantity && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.quantity}
                                </p>
                            )}
                        </div>

                        {/* Mode Select */}
                        <div className="col">
                            <label htmlFor="mode" className="small inter-500 text-b200 mb-2">Mode</label>
                            <select
                                id="mode"
                                name="mode"
                                value={formik.values.mode}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                            >   <option value="">--SELECT--</option>
                                <option value="SIP">SIP</option>
                                <option value="Lumpsum">LUMPSUM</option>
                              
                            </select>
                            {formik.errors.mode && formik.touched.mode && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.mode}
                                </p>
                            )}
                        </div>

                        {/* Buying Price Input */}
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price<strong className="text-danger">*</strong></label>
                            <input
                                type="text"
                                id="buyingPrice"
                                name="buyingPrice"
                                value={formik.values.buyingPrice}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                placeholder="00.00"
                            />
                            {formik.errors.buyingPrice && formik.touched.buyingPrice && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.buyingPrice}
                                </p>
                            )}
                        </div>

                        {/* NAV Input */}
                        <div className="col">
                            <label htmlFor="nav" className="small inter-500 text-b200 mb-2">Nav<strong className="text-danger">*</strong></label>
                            <input
                                type="number"
                                id="nav"
                                name="nav"
                                value={formik.values.nav}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                placeholder="00.00"
                            />
                            {formik.errors.nav && formik.touched.nav && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.nav}
                                </p>
                            )}
                        </div>

                        {/* Purchase Date Input */}
                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                            <input
                                type="date"
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="purchaseDate"
                                name="purchaseDate"
                                value={formik.values.purchaseDate}
                                onBlur={formik.handleBlur}
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }}                            />
                            {formik.errors.purchaseDate && formik.touched.purchaseDate && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.purchaseDate}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <button
                    type="button"
                    disabled={usePostinvestmentMutation?.isPending}
                    onClick={formik.handleSubmit}
                    className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                >
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save Mutual Fund {usePostinvestmentMutation?.isPending && (
                        <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>
                    )}
                </button>
            </div>
        </div>
    );
};

export default MutualFundAdd;
