import { fetcher } from "../utils/fetcher";

class DebtService {
  queryKeys = {
    postDebt: 'postDebt',
    getLenders: 'getLenders',
    getLoanJourney: 'getLoanJourney',
    editDbt: 'editDbt'
  };

  postDebt = async (data) => {

    return fetcher({
      url: `/loans`,
      method: 'POST',
      data
    });

  }

  getLenders = async (data) => {

    return fetcher({
      url: `/lenders`,
      method: 'GET',
    });

  }
  editDbt = async (data) => {
    const { payload, loanId } = data
    console.log('loanId', loanId)
    console.log("paylatest", payload)

    return fetcher({
      url: `/loans/${loanId}`,
      method: 'PUT',
      data: payload
    });
  }

  getLoanJourney = async (data) => {

    return fetcher({
      url: `/loans/${data}/journey`,
      method: 'GET',
    });

  }
}

const debtServiceInstance = new DebtService();
export default debtServiceInstance;
