import { QueryClient, useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import PlanningServiceInstance from '../../services/planning-service';


function useSavePlanningEstimates() {

  return useMutation({
    mutationFn: PlanningServiceInstance.savePlanningEstimates,
    onSuccess: (data) => {
      successToast('Saved Successfully')
      // QueryClient.invalidateQueries({ queryKey: [PlanningServiceInstance.queryKeys.bankFixedDeposits]})
    },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useSavePlanningEstimates;
