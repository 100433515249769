import React from 'react';
import Select from 'react-select';

const Select2Component = ({ data, onChange, defaultValue }) => {
    const options = data.map(item => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <Select
            options={options}
            onChange={selectedOption => onChange(selectedOption ? selectedOption.value : '')}
            defaultValue={options.find(option => option.value === defaultValue)}
            isClearable
            placeholder="Search Company"
            
        />
    );
};

export default Select2Component;
