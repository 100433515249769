import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetPlanningGoals() {
  return useQuery({
    queryKey: [PlanningServiceInstance.queryKeys.getPlanningGoals],
    queryFn: PlanningServiceInstance.getPlanningGoals,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetPlanningGoals;