import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Dashboard from "../pages/Dashboard";
import Investment from "../pages/Investment";
import StockAdd from "../pages/StockAdd";
import StockEdit from "../pages/StockEdit";
import MutualFundAdd from "../pages/MutualFundAdd";
import MutualFundEdit from "../pages/MutualFundsEdit";
import AlternateAdd from "../pages/AlternateAdd";
import Planning from "../pages/Planning";
import RetirementPlanner from "../pages/RetirementPlanner";
import Tax from "../pages/Tax";
import Banking from "../pages/Banking";
import BankDetailsAdd from "../pages/BankDetailsAdd";
import BankDetailsEdit from "../pages/BankingDetailsEdit"
import CreditCardAdd from "../pages/CreditCardAdd";
import CreditCardEdit from "../pages/CreditDetailsEdit"
import FixedDepositAdd from "../pages/FixedDepositAdd";
import FixedDepositEdit from "../pages/FixedDepositsEdit";
import FixedIncomeAdd from "../pages/FixedIncomeAdd";
import FixedIncomeEdit from "../pages/FixedIncomeEdit";
import AllTransactions from "../pages/AllTransactions";
import Insurance from "../pages/Insurance";
import InsuranceAdd from "../pages/InsuranceAdd";
import InsuranceEdit from "../pages/InsuranceEdit";
import Debt from "../pages/Debt";
import DebtAdd from "../pages/DebtAdd";
import DebtEdit from "../pages/DebtEdit";
import Simulator from "../pages/Simulator";
import Settings from "../pages/Settings";
import Help from "../pages/Help";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import Uikit from "../pages/Uikit";
import PlanningAdd from "../pages/PlanningAdd";
import PlanningGoalsAdd from "../pages/PlanningGoalsAdd";
import PlanningGoalEdit from "../pages/PlanningGoalEdit"
import GoalEdit from "../pages/GoalEdit";

const Routing = () => {
    let isLogedIn = window.localStorage.getItem('isLogedIn')
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={isLogedIn ? <Layout /> : <Login />} />
                <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/investment" element={<Investment />} />

                    <Route path="/stock-add" element={<StockAdd />} />
                    <Route path="/stock-edit" element={<StockEdit />} />
                    <Route path="/mutual-fund-add" element={<MutualFundAdd />} />
                    <Route path="/mutual-fund-edit" element={<MutualFundEdit />} />
                    <Route path="/alternate-add" element={<AlternateAdd />} />

                    <Route path="/planning" element={<Planning />} />
                    <Route path="/planning-goal-edit" element={<PlanningGoalEdit />} />
                    <Route path="/goal-edit" element={<GoalEdit/>} />
                    <Route path="/retirement-planner" element={<RetirementPlanner />} />
                    <Route path="/planning-add" element={<PlanningAdd />} />
                    <Route path="/planning-goal-add" element={<PlanningGoalsAdd />} />
                    <Route path="/tax" element={<Tax />} />
                    <Route path="/banking" element={<Banking />} />
                    <Route path="/bank-details-add" element={<BankDetailsAdd />} />
                    <Route path="/bank-details-edit" element={<BankDetailsEdit />} />
                    <Route path="/credit-card-add" element={<CreditCardAdd />} />
                    <Route path="/credit-card-edit" element={<CreditCardEdit />} />
                    <Route path="/fixed-deposit-add" element={<FixedDepositAdd />} />
                    <Route path="/fixed-deposit-edit" element={<FixedDepositEdit />} />
                    <Route path="/fixed-income-add" element={<FixedIncomeAdd />} />
                    <Route path="/fixed-income-edit" element={<FixedIncomeEdit />} />
                    <Route path="/all-transactions" element={<AllTransactions />} />
                    <Route path="/insurance" element={<Insurance />} />
                    <Route path="/insurance-add" element={<InsuranceAdd />} />
                    <Route path="/insurance-edit" element={<InsuranceEdit />} />
                    <Route path="/debt" element={<Debt />} />
                    <Route path="/debt-add" element={<DebtAdd />} />
                    <Route path="/debt-edit" element={<DebtEdit />} />
                    <Route path="/simulator" element={<Simulator />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/ui-kit" element={<Uikit />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<Login />} />
                <Route path='*' exact={true} element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
export default Routing;
