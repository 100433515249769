import { keepPreviousData, useQuery } from '@tanstack/react-query';

import UserMeService from '../../services/user-service';


function useGetUserProfile(data) {
  return useQuery({
    queryKey: [UserMeService.queryKeys.getUserProfile, data],
    queryFn: () => UserMeService.getUserProfile(data),
    refetchOnWindowFocus: false,
    enabled: !!data, 
  });
}


export default useGetUserProfile;