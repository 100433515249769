import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';


function useGetMutualFundsInvestment(data) {

  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.mutualFundsInvestment],
    queryFn:  investmentServiceInstance.mutualFundsInvestment,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetMutualFundsInvestment;