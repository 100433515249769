import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import usePostinvestment from "../hooks/investment/post-investment";
import { FinIcon, AddInvestmentBack } from '../components/Common';
import { useLocation, useNavigate } from "react-router-dom";
const FixedSchema = Yup.object().shape({
    instrumentName: Yup.string().trim().required('Please enter Instrument Name.'),
    category: Yup.string().trim().required('Category is required.'),
    rating: Yup.string().trim().required('Please enter Rating.'),
    couponRate: Yup.number()
        .min(0, 'Coupon Rate must be at least 0.')
        .max(30, 'This value is not allowed. Please fill between 0 and 30.')
        .required('Please enter Coupon Rate.').typeError("couponRate must be a number"),
    paymentFreq: Yup.string().trim().required('Payment Frequency is required'),
    nextInterestPaymentDate: Yup.string()
    .required('Please enter Next Interest Payment Date.')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format') // Check date format
    .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
        if (!value) return false; // If value is empty, return false

        const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
        return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
    }),
    maturityDate: Yup.string()
        .required('Please enter Maturity Date.')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format') // Check date format
        .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
            if (!value) return false; // If value is empty, return false

            const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
            return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
        }),
    isTaxable: Yup.boolean(),
    investmentType: Yup.string().trim().required('Investment Type is required.'),
    buyingPrice: Yup.number().required('Please enter Invested Value.'),
    purchaseDate: Yup.string()
        .required('Please enter Purchase Date.')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format') // Check date format
        .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
            if (!value) return false; // If value is empty, return false

            const year = parseInt(value.split('-')[0], 10); // Extract the year as a number
            return year >= 1000 && year <= 9999 && year.toString()[0] !== '0'; // Check conditions
        }),
});


const AddFixedIncome = (props) => {
    const location = useLocation()
    const activeTab = location.state?.activeTab
    const navigate = useNavigate()
    const usePostInvestmentMutation = usePostinvestment();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            instrumentName: '',
            category: '',
            rating: '',
            couponRate: '',
            category: '',
            paymentFreq: '',
            nextInterestPaymentDate: '',
            maturityDate: '',
            isTaxable: false,
            investmentType: 'BONDS',
            buyingPrice: '',
            purchaseDate: '',
        },
        validationSchema: FixedSchema,
        onSubmit: values => {
            values.isTaxable = values.isTaxable === "true";
            usePostInvestmentMutation.mutate(values, {
                onSuccess: (data) => {
                    formik.resetForm();
                    navigate({
                        pathname: '/investment',
                        search: `?tab=${activeTab}`
                    });
                },
            });
        },
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add New FIxed Income</h3>
                <p className="text-b200 small">Add New Fixed Income basic details.</p>
            </div>
            <div className="col-auto">
                <AddInvestmentBack value="FIXEDINCOME" activeTab={activeTab} />
            </div>
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="instrumentName" className="small inter-500 text-b200 mb-2">Instrument Name</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="instrumentName"
                                name="instrumentName"
                                onChange={formik.handleChange}
                                value={formik.values.instrumentName}
                            />
                            {formik.errors.instrumentName && formik.touched.instrumentName ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.instrumentName}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="category" className="small inter-500 text-b200 mb-2">Category<strong className="text-danger">*</strong></label>
                            <select
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                value={formik.values.category}
                            >   <option value="">--SELECT--</option>
                                <option value="Fixed_Deposit">Fixed Deposit</option>
                                <option value="Corporate">Corporate</option>
                                <option value="Government">Government</option>

                            </select>
                            {formik.errors.category && formik.touched.category ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.category}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="rating" className="small inter-500 text-b200 mb-2">Rating</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="rating"
                                name="rating"
                                onChange={formik.handleChange}
                                value={formik.values.rating}
                            />
                            {formik.errors.rating && formik.touched.rating ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.rating}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="couponRate" className="small inter-500 text-b200 mb-2">Coupon Rate (%)</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="couponRate"
                                name="couponRate"
                                onChange={formik.handleChange}
                                value={formik.values.couponRate}
                            />
                            {formik.errors.couponRate && formik.touched.couponRate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.couponRate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="paymentFreq" className="small inter-500 text-b200 mb-2">Payment Frequency</label>
                            <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="paymentFreq"
                                name="paymentFreq"
                                onChange={formik.handleChange}
                                value={formik.values.paymentFreq}
                            >
                                <option value="">-- Select --</option>
                                <option value="Semi Annually">Semi Annually</option>
                                <option value="Annually">Annually</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Quarterly">Quarterly</option>
                            </select>
                            {formik.errors.paymentFreq && formik.touched.paymentFreq ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.paymentFreq}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="nextInterestPaymentDate" className="small inter-500 text-b200 mb-2">Next Interest Payment Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="nextInterestPaymentDate"
                                name="nextInterestPaymentDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }}
                                value={formik.values.nextInterestPaymentDate}
                            />
                            {formik.errors.nextInterestPaymentDate && formik.touched.nextInterestPaymentDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.nextInterestPaymentDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="maturityDate"
                                name="maturityDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.maturityDate}
                            />
                            {formik.errors.maturityDate && formik.touched.maturityDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.maturityDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="isTaxable" className="small inter-500 text-b200 mb-2">Is Taxable</label>
                            <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="isTaxable"
                                name="isTaxable"
                                onChange={formik.handleChange}
                                value={formik.values.isTaxable ? "true" : "false"}
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {formik.errors.isTaxable && formik.touched.isTaxable ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.isTaxable}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="investmentType" className="small inter-500 text-b200 mb-2">Investment Type<strong className="text-danger">*</strong></label>
                            <input
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="investmentType"
                                name="investmentType"
                                value={formik.values.investmentType}
                                readOnly
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Invested Value</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="buyingPrice"
                                name="buyingPrice"
                                onChange={formik.handleChange}
                                value={formik.values.buyingPrice}
                            />
                            {formik.errors.buyingPrice && formik.touched.buyingPrice ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.buyingPrice}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="purchaseDate"
                                name="purchaseDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.purchaseDate}
                            />
                            {formik.errors.purchaseDate && formik.touched.purchaseDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.purchaseDate}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-2 mt-xl-4">
                        <div className="col">
                            <button
                                type="button"
                                className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                                disabled={usePostInvestmentMutation?.isPending}
                                onClick={formik.handleSubmit}
                            >
                                <FinIcon name="save" className="ts-1p5 me-3" />
                                Save New Fixed Income
                                {usePostInvestmentMutation?.isPending && (
                                    <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFixedIncome;
