import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';

function useGetMutualFunds() {
  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.getMutualFunds],
    queryFn: investmentServiceInstance.getMutualFunds,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetMutualFunds;