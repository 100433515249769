import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Routing from './components/Routing';
import Icons from './components/Icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//select 2 library import 
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';


const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();
root.render(<>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
        <Routing />
        <Icons />
        <ToastContainer />
    </QueryClientProvider>

    {/* </React.StrictMode> */}
</>
);