import React, { useEffect } from "react";
import { Popover, Tooltip } from "bootstrap";
// import { useNavigate } from "react-router-dom";

import { FinIcon } from "../components/Common";
import { Link } from "react-router-dom";

const baseURL = process.env.REACT_APP_API;

const Uikit = () => {
    // const navigate = useNavigate();
    // const [data, setData] = useState([]);

    useEffect(()=>{
        console.log(baseURL);

        /* fetch(`${baseURL}/list`, {
        }).then((res) => res.json()).then((data)=>{
            setData(data);
        }); */

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

        const myModal = document.getElementById('modalOne');
        myModal.addEventListener('hidden.bs.modal', () => {
            console.log('modal had been hidden');
        });

        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl));

    },[]);

    return(<>
        <div className="row row-inner">
            <div className="col-12">
                <h1>Ui Kit</h1>
                <hr/>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Buttons</h5>
                <div className="d-flex flex-wrap align-items-start gap-3">
                    <button type="button" className="btn btn-primary">
                        Primary
                    </button>
                    <Link to="/login" className="btn btn-primary">
                        Link Button
                    </Link>
                    <button type="button" className="btn btn-outline-primary">
                        Outline Primary
                    </button>
                    <button type="button" className="btn btn-primary btn-fin px-4">
                        Primary btn-fin
                    </button>
                    <button type="button" className="btn btn-outline-primary btn-fin px-4">
                        Outline Primary btn-fin
                    </button>
                    <button type="button" className="btn btn-warning btn-fin">
                        Warning btn-fin
                    </button>
                    <button type="button" className="btn btn-danger btn-fin">
                        Warning btn-fin
                    </button>
                </div>
            </div>

            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Badges</h5>
                <div className="d-flex flex-wrap align-items-start gap-3">
                    <span className="badge text-bg-success rounded-pill py-5px">+23.49%</span>
                    <span className="badge text-bg-danger rounded-pill py-5px">-45.75%</span>
                </div>
            </div>

            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Pagination</h5>
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center gap-2">
                        <li className="page-item page-item-prev disabled">
                            <Link className="page-link">Prev</Link>
                        </li>
                        <li className="page-item"><Link className="page-link" to="/#">01</Link></li>
                        <li className="page-item"><Link className="page-link" to="/#">02</Link></li>
                        <li className="page-item"><Link className="page-link" to="/#">03</Link></li>
                        <li className="page-item"><Link className="page-link" to="/#">04</Link></li>
                        <li className="page-item"><Link className="page-link" to="/#">...</Link></li>
                        <li className="page-item"><Link className="page-link" to="/#">379</Link></li>
                        <li className="page-item page-item-next">
                            <Link className="page-link" to="/#">Next</Link>
                        </li>
                    </ul>
                </nav>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Loan/Debt Card Design</h5>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
                    <div className="col">
                        <div className="card-detailing card-detailtheme">
                            <div className="d-flex gap-3">
                                <div className="card-icon">
                                    <FinIcon name="home"/>
                                </div>
                                <div className="d-block">
                                    <h5>₹100000</h5>
                                    <p className="mb-0">Home Loan - Outstanding Amount</p>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex flex-column gap-2 small text-b100">
                                <div className="d-flex justify-content-between">
                                    <span>Original Loan A/m</span>
                                    <strong className="text-b200 inter-600">₹4000000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>EMI</span>
                                    <strong className="text-b200 inter-600">₹10000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Avg. Interest Rate</span>
                                    <strong className="text-b200 inter-600">6%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card-detailing card-detailpink">
                            <div className="d-flex gap-3">
                                <div className="card-icon">
                                    <FinIcon name="personaloan"/>
                                </div>
                                <div className="d-block">
                                    <h5>₹100000</h5>
                                    <p className="mb-0">Personal Loan</p>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex flex-column gap-2 small text-b100">
                                <div className="d-flex justify-content-between">
                                    <span>Liability</span>
                                    <strong className="text-b200 inter-600">₹10000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Assets</span>
                                    <strong className="text-b200 inter-600">₹11000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Interest Paid</span>
                                    <strong className="text-b200 inter-600">₹15000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Avg. Interest Rate</span>
                                    <strong className="text-b200 inter-600">6%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card-detailing card-detailpurple">
                            <div className="d-flex gap-3">
                                <div className="card-icon">
                                    <FinIcon name="car"/>
                                </div>
                                <div className="d-block">
                                    <h5>₹100000</h5>
                                    <p className="mb-0">Card Loan</p>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex flex-column gap-2 small text-b100">
                                <div className="d-flex justify-content-between">
                                    <span>Liability</span>
                                    <strong className="text-b200 inter-600">₹10000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Assets</span>
                                    <strong className="text-b200 inter-600">₹11000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Interest Paid</span>
                                    <strong className="text-b200 inter-600">₹15000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Avg. Interest Rate</span>
                                    <strong className="text-b200 inter-600">6%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card-detailing card-detailgreen">
                            <div className="d-flex gap-3">
                                <div className="card-icon">
                                    <FinIcon name="education"/>
                                </div>
                                <div className="d-block">
                                    <h5>₹100000</h5>
                                    <p className="mb-0">Education Loan</p>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex flex-column gap-2 small text-b100">
                                <div className="d-flex justify-content-between">
                                    <span>Liability</span>
                                    <strong className="text-b200 inter-600">₹10000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Assets</span>
                                    <strong className="text-b200 inter-600">₹11000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Interest Paid</span>
                                    <strong className="text-b200 inter-600">₹15000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Avg. Interest Rate</span>
                                    <strong className="text-b200 inter-600">6%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card-detailing card-detailgold">
                            <div className="d-flex gap-3">
                                <div className="card-icon">
                                    <FinIcon name="gold"/>
                                </div>
                                <div className="d-block">
                                    <h5>₹100000</h5>
                                    <p className="mb-0">Gold Loan</p>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex flex-column gap-2 small text-b100">
                                <div className="d-flex justify-content-between">
                                    <span>Liability</span>
                                    <strong className="text-b200 inter-600">₹10000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Assets</span>
                                    <strong className="text-b200 inter-600">₹11000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Interest Paid</span>
                                    <strong className="text-b200 inter-600">₹15000</strong>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>Avg. Interest Rate</span>
                                    <strong className="text-b200 inter-600">6%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Texture Background</h5>
                
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4">
                    <div className="col">
                        <div className="bg-orange ratio ratio-16x9 rounded-4 overflow-hidden">
                            <img src="images/texture-bg.svg" className="texture-orange" alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="bg-blue-deem ratio ratio-16x9 rounded-4 overflow-hidden">
                            <img src="images/texture-bg.svg" className="texture-blue" alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="bg-green-deem ratio ratio-16x9 rounded-4 overflow-hidden">
                            <img src="images/texture-bg.svg" className="texture-green" alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="bg-pink-deem ratio ratio-16x9 rounded-4 overflow-hidden">
                            <img src="images/texture-bg.svg" className="texture-pink" alt=""/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Modal</h5>
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalOne">
                    Launch Modal #1
                </button>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Dropdown</h5>
                <div className="dropdown">
                    <button className="btn btn-info dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown button
                    </button>
                    <ul className="dropdown-menu shadow">
                        <li><Link className="dropdown-item" to="/#">Action</Link></li>
                        <li><Link className="dropdown-item" to="/#">Another action</Link></li>
                        <li><Link className="dropdown-item" to="/#">Something else here</Link></li>
                    </ul>
                </div>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Tooltip</h5>
                <div className="d-flex flex-wrap gap-3">
                    <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top">
                        Tooltip on top
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Tooltip on right">
                        Tooltip on right
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Tooltip on bottom">
                        Tooltip on bottom
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tooltip on left">
                        Tooltip on left
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="<em>Tooltip</em> <u>with</u> <b>HTML</b>">
                        Tooltip with HTML
                    </button>
                </div>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Popover</h5>
                <div className="d-flex flex-wrap gap-3">
                    <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Top popover">
                        Popover on top
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Right popover">
                        Popover on right
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover">
                        Popover on bottom
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="left" data-bs-content="Left popover">
                        Popover on left
                    </button>
                </div>
            </div>


            <div className="col-12 mb-5">
                <h5 className="inter-600 mb-3">Icons</h5>

                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3 text-center">
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="dashboard" className="h4"/>
                            <div>dashboard</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="investment" className="h4"/>
                            <div>investment</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="planning" className="h4"/>
                            <div>planning</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="wallet" className="h4"/>
                            <div>wallet</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="banking" className="h4"/>
                            <div>banking</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="insurance" className="h4"/>
                            <div>insurance</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="currency" className="h4"/>
                            <div>currency</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="monitor" className="h4"/>
                            <div>monitor</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="settings" className="h4"/>
                            <div>settings</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="help" className="h4"/>
                            <div>help</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="logout" className="h4"/>
                            <div>logout</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="logout2" className="h4"/>
                            <div>logout2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="pencil" className="h4"/>
                            <div>pencil</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="pencil2" className="h4"/>
                            <div>pencil2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="pencil3" className="h4"/>
                            <div>pencil3</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="arrowright" className="h4"/>
                            <div>arrowright</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="arrowright2" className="h4"/>
                            <div>arrowright2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="arrowleft" className="h4"/>
                            <div>arrowleft</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="arrowleft2" className="h4"/>
                            <div>arrowleft2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="eye" className="h4"/>
                            <div>eye</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="eyeoff" className="h4"/>
                            <div>eyeoff</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="car" className="h4"/>
                            <div>car</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="home" className="h4"/>
                            <div>home</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="starfill" className="h4"/>
                            <div>starfill</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="mobemail" className="h4"/>
                            <div>mobemail</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="lock" className="h4"/>
                            <div>lock</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="lock2" className="h4"/>
                            <div>lock2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="mail" className="h4"/>
                            <div>mail</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="mail2" className="h4"/>
                            <div>mail2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="mobile" className="h4"/>
                            <div>mobile</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="user" className="h4"/>
                            <div>user</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="user2" className="h4"/>
                            <div>user2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="users" className="h4"/>
                            <div>users</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="command" className="h4"/>
                            <div>command</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="addcirlce" className="h4"/>
                            <div>addcirlce</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="addline" className="h4"/>
                            <div>addline</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="bell" className="h4"/>
                            <div>bell</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="search" className="h4"/>
                            <div>search</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="calendar" className="h4"/>
                            <div>calendar</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="export" className="h4"/>
                            <div>export</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="export2" className="h4"/>
                            <div>export2</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="linechart" className="h4"/>
                            <div>linechart</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="bankcard" className="h4"/>
                            <div>bankcard</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="education" className="h4"/>
                            <div>education</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="gold" className="h4"/>
                            <div>gold</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneyhand" className="h4"/>
                            <div>moneyhand</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="info" className="h4"/>
                            <div>info</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneybag" className="h4"/>
                            <div>moneybag</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="personaloan" className="h4"/>
                            <div>personaloan</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="loans" className="h4"/>
                            <div>loans</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="p2p" className="h4"/>
                            <div>p2p</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneyhike" className="h4"/>
                            <div>moneyhike</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="heartpulse" className="h4"/>
                            <div>heartpulse</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="morexpenses" className="h4"/>
                            <div>morexpenses</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="life" className="h4"/>
                            <div>life</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneysett" className="h4"/>
                            <div>moneysett</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="pension" className="h4"/>
                            <div>pension</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="inflation" className="h4"/>
                            <div>inflation</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneyreturn" className="h4"/>
                            <div>moneyreturn</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneysave" className="h4"/>
                            <div>moneysave</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="moneyinvest" className="h4"/>
                            <div>moneyinvest</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="device" className="h4"/>
                            <div>device</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="save" className="h4"/>
                            <div>save</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="delete" className="h4"/>
                            <div>delete</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="close" className="h4"/>
                            <div>close</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="face" className="h4"/>
                            <div>face</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="partner" className="h4"/>
                            <div>partner</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="link" className="h4"/>
                            <div>link</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="reset" className="h4"/>
                            <div>reset</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="copy" className="h4"/>
                            <div>copy</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="" className="h4"/>
                            <div></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="" className="h4"/>
                            <div></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="border p-3 user-select-all">
                            <FinIcon name="" className="h4"/>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        {/* ================================== */}
        {/* ================================== */}
        {/* ================================== */}
        <div className="modal fade" id="modalOne" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        This is content of modal body
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Uikit;
