import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { formatNumberWithCommas } from "../utils/common";

const TotalInvestmentsChart = ({...props}) => {
 
  
 

      const dateGraphList = (startDate, endDate) => {
        const graphList = [];
        const tempDate = new Date(startDate);
      
        while (tempDate <= endDate) {
          const formattedDate = tempDate.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'short', // You can use 'numeric' if you prefer the month number.
            day: 'numeric',
          });
          graphList.push(formattedDate);
          tempDate.setDate(tempDate.getDate() + 1);
        }
      
        return graphList;
      };
      
      
  

     

    
      // graphp


   
function combineInvestmentData(stockData, mfData, bondData, alternativeData) {
  const dateMap = new Map();

  // Helper function to ensure each date is in the map
  function ensureDate(item) {
    if (!dateMap.has(item.dateStr)) {
      dateMap.set(item.dateStr, { 
        date: item.date, 
        dateStr: item.dateStr, 
        stock: 0, 
        stockMf: 0, 
        stockMfBond: 0, 
        total: 0 
      });
    }
    return dateMap.get(item.dateStr);
  }

  // Process each type of investment
  stockData.forEach(item => {
    const entry = ensureDate(item);
    entry.stock += item.price;
    entry.stockMf += item.price;
    entry.stockMfBond += item.price;
    entry.total += item.price;
  });

  mfData.forEach(item => {
    const entry = ensureDate(item);
    entry.stockMf += item.price;
    entry.stockMfBond += item.price;
    entry.total += item.price;
  });

  bondData.forEach(item => {
    const entry = ensureDate(item);
    entry.stockMfBond += item.price;
    entry.total += item.price;
  });

  alternativeData.forEach(item => {
    const entry = ensureDate(item);
    entry.total += item.price;
  });

  // Convert map to array and sort by date
  const sortedData = Array.from(dateMap.values()).sort((a, b) => a.date - b.date);

  // Create separate lists for each cumulative investment type
  const stockList = sortedData.map(({ date, dateStr,     stock }) => ({ date, dateStr, value: stock }));
  const stockMfList = sortedData.map(({ date, dateStr, stockMf }) => ({ date, dateStr, value: stockMf }));
  const stockMfBondList = sortedData.map(({ date, dateStr, stockMfBond }) => ({ date, dateStr, value: stockMfBond }));
  const totalList = sortedData.map(({ date, dateStr, total }) => ({ date, dateStr, value: total }));


  const stockListFinal = stockList.map(item => item.value);
  const stockMfListFinal = stockMfList.map(item => item.value);
  const stockMfBondListFinal = totalList.map(item => item.value);
  const totalListFinal = totalList.map(item => item.total);

//   console.log( 'sorted-totalList' , totalList );

   return {
    stockListFinal: stockList,
    stockMfListFinal: stockMfList,
    stockMfBondListFinal: stockMfBondList,
    totalListFinal: totalList
  };
}


const labels = dateGraphList(new Date(props.fromDate), new Date(props.toDate));
const staticData = new Array(labels.length).fill(props.totalCurrent); 

    const state = {
        series: [{
            name: "Stocks",
            type: "area",
            data: props.seriesData.stockList,
        }, {
            name: "Mutual Funds",
            type: "area",
            data: props.seriesData.stockMfBondList,
        }, {
            name: "Bonds",
            type: "area",
            data:  props.seriesData.stockMfList, 
        }, {
            name: "Alternate",
            type: "area",
            data: staticData, 
        }],          
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 3, 3, 3],
                curve: 'smooth'
            },
            labels:         dateGraphList( new Date(props.fromDate), new Date(props.toDate) )            ,
            xaxis: {
                type: 'datetime',
            },

        yaxis: {
            labels: {
                formatter: function (value) {
                    return formatNumberWithCommas(Math.round(value)); // Removes decimals and adds commas
                }
            }
        },

            legend: { show: false },
            colors:['#f4c678', '#6ca1ef', '#8dede2', '#ff9bcd'],
            fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 0.25
                }
            }
        }
    }

    return (
        <Chart options={state.options} series={state.series} height={props.height} />
    )    
}

export default TotalInvestmentsChart;