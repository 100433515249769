import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { errorToast, successToast } from '../../utils/toastMassage';
import insuranceServiceInstance from '../../services/insurance-service';

function useEditInsurance() {

  const navigate = useNavigate();

  return useMutation({
    mutationFn: insuranceServiceInstance.editInsurance,
    onSuccess: () => {
      successToast('Updated Successfully');
    },
    onError: (error) => {
      console.log(error,'Server Error');
      errorToast('Something went wrong, please try again later')
    },
  });

}

export default useEditInsurance;