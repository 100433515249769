import { keepPreviousData, useQuery } from '@tanstack/react-query';

import debtServiceInstance from '../../services/debt-service';

function useGetLenders() {
  return useQuery({
    queryKey: [debtServiceInstance.queryKeys.getLenders],
    queryFn: debtServiceInstance.getLenders,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetLenders;