import { keepPreviousData, useQuery } from '@tanstack/react-query';

import insuranceServiceInstance from '../../services/insurance-service';

function useGetInsurance(data) {
  return useQuery({
    queryKey: [insuranceServiceInstance.queryKeys.getInsurance + data],
    queryFn: () => insuranceServiceInstance.getInsurance(data),
     refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetInsurance;