import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetPlanningSimulatorGraph(data) {

    return useQuery({
        queryKey: [PlanningServiceInstance.queryKeys.getPlanningSimulatorGraph + data?.age + data?.simulate + data?.r_age + data?.le + data?.incm + data?.ivst + data?.pre_roi + data?.post_roi + data?.ir + data?.est_re],
        queryFn: () => PlanningServiceInstance.getPlanningSimulatorGraph(data),
        refetchOnWindowFocus: false

    });
}

export default useGetPlanningSimulatorGraph;