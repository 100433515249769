import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import { FinIcon } from '../components/Common';
import { addPlanningGoalSchema } from "../utils/validationSchema";
import useSavePlanningGoals from "../hooks/planning/save-planning-goals";
import { useNavigate } from "react-router-dom";
const PlanningGoalsAdd = (props) => {
  const navigate = useNavigate()
  //api call
  const useSavePlanningGoalsMutation = useSavePlanningGoals()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      amount: "",
      goalDate: "",
      // forwhom: ''

    },
    validationSchema: addPlanningGoalSchema,
    onSubmit: values => {
      console.log(values);
      const payload = {
        name: formik?.values?.name,
        amount: Number(formik?.values?.amount),
        goalDate: formik?.values?.goalDate,
      }

      useSavePlanningGoalsMutation.mutate(payload, {
        onSuccess: (data) => {
          formik.resetForm()
          navigate('/planning')
        },
      })


    },
  });

  return (<>
    <div className="row row-inner">
      <div className="col align-self-center">
        <h3 className="inter-700">Add New Planning Goal</h3>
        <p className="text-b200 small">
          Add New Planning Goal basic details.
        </p>
      </div>
      <div className="col-auto">
        <Link to={'/planning'} type="button" className="btn btn-normal btn-light">
          <FinIcon name="arrowleft2" className="ts-1p4" /> Back
        </Link>
      </div>

      {/* --- */}
      <div className="col-12 mt-1 mt-xl-2 mb-4">
        <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">

            <div className="col">
              <label htmlFor="name" className="small inter-500 text-b200 mb-2">Name</label>
              <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Name"
                min="0"
                id="name"
                name="name"
                onInput={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur('name')}
              />
              {(formik.errors.name && formik.touched.name) ? <p className="fs-14px text-danger inter-500 my-1">
                {formik.errors.name}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="amount" className="small inter-500 text-b200 mb-2">Amount (₹)</label>
              <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                min="0"
                id="amount"
                name="amount"
                onInput={formik.handleChange}
                value={formik.values.amount}
                onBlur={formik.handleBlur('amount')}
              />
              {(formik.errors.amount && formik.touched.amount) ? <p className="fs-14px text-danger inter-500 my-1">
                {formik.errors.amount}
              </p> : null}
            </div>
            <div className="col">
  <label htmlFor="goalDate" className="small inter-500 text-b200 mb-2">Goal Date</label>
  <input
    type="date" 
    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
    id="goalDate"
    name="goalDate"
    onInput={(e) => {
      const value = e.target.value;
      const dateParts = value.split('-'); 
      if (dateParts[0] && dateParts[0].length > 4) {
        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`; 
      }

      formik.handleChange(e); 
    }}
    value={formik.values.goalDate}
    onBlur={formik.handleBlur('goalDate')}
  />
  {formik.errors.goalDate && formik.touched.goalDate ? (
    <p className="fs-14px text-danger inter-500 my-1">
      {formik.errors.goalDate}
    </p>
  ) : null}
</div>

        

          </div>
        </div>
        <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4" disabled={useSavePlanningGoalsMutation?.isPending} onClick={formik.handleSubmit}>
          <FinIcon name="save" className="ts-1p5 me-3" />
          Save New Goal
          {useSavePlanningGoalsMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
        </button>
      </div>
    </div>
  </>)
}
export default PlanningGoalsAdd;