import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';


function useGetGsecData(data) {
 
  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.useGetGsecDataApi + data?.from + data?.to],
    queryFn: () => investmentServiceInstance.useGetGsecDataApi(data),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetGsecData;