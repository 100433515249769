import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { FinIcon } from '../components/Common';
import UpdateLinkGoal from "../hooks/planning/put-link-goals"; // Import hook here

const GoalEdit = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const item = location.state?.item;
    const goal = location.state?.goal;
    const goalId = item?.goalId || goal?.goalId;
     const investmentId= location.state?.investId
    // Use mutation hook to update link goal
    const { mutate: updateGoalLink, isLoading } = UpdateLinkGoal(); // Destructure the hook

    // Define formik instance
    const formik = useFormik({
        initialValues: {
            sharePercentage: item?.percentage || goal?.percentage || '', // Set default value based on location state
        },
        validationSchema: yup.object().shape({
            sharePercentage: yup
                .number()
                .required('Share percentage is required')
                .min(1, 'Minimum percentage is 1%')
                .max(100, 'Maximum percentage is 100%'),
        }),
        onSubmit: (values) => {
            console.log("Form Values:", values);
            
            const payload = {
                sharePercentage: values.sharePercentage,
                investmentId: investmentId,
            };

            // Trigger the mutation
            updateGoalLink(
                { goalId, payload },
                {
                    onSuccess: () => {
                        console.log('Goal updated successfully');
                        // Navigate to the investment page after success
                        navigate(`/investment`);
                    },
                    onError: (error) => {
                        console.error('Update failed:', error);
                    },
                }
            );
        },
    });
    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Update Planning Goal</h3>
                    <p className="text-b200 small">Update Planning Goal basic details.</p>
                </div>
                <div className="col-auto">
                    <Link to={goal ? '/investment' : '/planning'} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                    </Link>
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">

                              
                    

                                <div className="col">
                                    <label htmlFor="sharePercentage" className="small inter-500 text-b200 mb-2">Share Percentage</label>
                                    <input
                                        type="number"
                                        className={`form-control form-control-fin inter-500 bg-white px-3 shadow-sm ${formik.errors.sharePercentage && formik.touched.sharePercentage ? 'is-invalid' : ''}`}
                                        placeholder="Share Percentage"
                                        min="1"
                                        max="100"
                                        id="sharePercentage"
                                        name="sharePercentage"
                                        value={formik.values.sharePercentage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.sharePercentage && formik.touched.sharePercentage && (
                                        <div className="text-danger mt-1">
                                            {formik.errors.sharePercentage}
                                        </div>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                                        disabled={!formik.isValid || formik.isSubmitting || isLoading}
                                    >
                                        <FinIcon name="save" className="ts-1p5 me-3" />
                                        Update {goal ? "Goal" : "Item"}
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GoalEdit;
