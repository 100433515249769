import React from "react";
// import { Link } from "react-router-dom";
import {FinIcon} from './Common';

const AuthorisedDevices = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Authorised Devices</h3>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-normal btn-light" onClick={()=> props.passingMethod({account_settings: true})}>
                    <FinIcon name="arrowleft2" className="ts-1p4"/> Back
                </button>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    Manage all devices that are logged into your account.
                </p>
            </div>
            {/* ... */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <thead>
                                <tr>
                                    <th>Devices Name</th>
                                    <th>Created</th>
                                    <th>Last Update</th>
                                    <th width="120">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>My Computer</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mac OS X Chrome #9</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="text" defaultValue="Mac OS X Chrome #9" className="form-control"/>
                                    </td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <div className="d-flex gap-1">
                                            <button type="button" className="btn btn-sm text-success">
                                                <FinIcon name="save" className="ts-1p5"/>
                                            </button>
                                            <button type="button" className="btn btn-sm text-danger">
                                                <FinIcon name="delete" className="ts-1p4"/>
                                            </button>
                                            <button type="button" className="btn btn-sm text-secondary">
                                                <FinIcon name="close" className="ts-1p5"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mac OS X Chrome #9</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>My Computer</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mac OS X Chrome #9</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>My Computer</td>
                                    <td>11/02/2024</td>
                                    <td>18/03/2024 3:09 pm</td>
                                    <td>
                                        <button type="button" className="btn btn-sm text-primary">
                                            <FinIcon name="pencil3" className="ts-1p5"/>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthorisedDevices;