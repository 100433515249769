import { QueryClient, useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import bankServiceInstance from '../../services/bank-service';

function useUpdateTransactionCategory() {

  return useMutation({
    mutationFn: bankServiceInstance.updateTransactionCategory,
    onSuccess: (data) => {
      successToast('Updated Successfully')
      // QueryClient.invalidateQueries({ queryKey: [bankServiceInstance.queryKeys.bankFixedDeposits]})
    },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUpdateTransactionCategory;
