import { fetcher } from "../utils/fetcher";

class BankService {
  queryKeys = {
    bankAccounts: 'bankAccounts',
    bankAccountTransactions: 'bankAccountTransactions',
    bankCreditCards: 'bankCreditCards',
    bankFixedDeposits: 'bankFixedDeposits',
    addFixedDepositBank: 'addFixedDepositBank',
    addBankAccounts: 'addBankAccounts',
    addCreditCard: 'addCreditCard',
    bankTransactionCategories: 'bankTransactionCategories',
    updateTransactionCategory: 'updateTransactionCategory',
    getBankAggregates: 'getBankAggregates',
    putBankingDetails: 'putBankingDetails',
    putCreditCard: 'putCreditCard',
    putFixeddeposits: 'putFixeddeposits'
  };

  putBankingDetails = async (data) => {
    const { payload, id } = data
    return fetcher({
      url: `/banking/accounts/${id}`,
      method: 'PUT',
      data: payload,

    })
  }
  putFixeddeposits = async (data) => {
    const { payload, id } = data
    return fetcher({
      url: `/banking/fixed-deposits/${id}`,
      method: 'PUT',
      data: payload,

    })
  }

  putCreditCard = async (data) => {
    const { payload, id } = data;
    return fetcher({
      url: `/banking/credit-cards/${id}`,
      method: 'PUT',
      data: payload
    })
  }

  bankAccounts = async (data) => {
    return fetcher({
      url: `/banking/accounts?nextPageToken=${data}`,
      method: 'GET',
    });
  }

  bankAccountTransactions = async (data) => {
    const { accountId, page } = data
    return fetcher({
      url: `/banking/accounts/transactions?nextPageToken=${page}&limit=20&accountId=${accountId}`,
      method: 'GET',
    });
  }

  bankCreditCards = async (data) => {

    return fetcher({
      url: `/banking/credit-cards?nextPageToken=${data}`,
      method: 'GET',
    });
  }

  bankFixedDeposits = async (data) => {

    return fetcher({
      url: `/banking/fixed-deposits?nextPageToken=${data}`,
      method: 'GET',
    });
  }

  addFixedDepositBank = async (data) => {

    return fetcher({
      url: `/banking/fixed-deposits`,
      method: 'POST',
      data
    });
  }

  addBankAccounts = async (data) => {

    return fetcher({
      url: `/banking/accounts`,
      method: 'POST',
      data
    });
  }

  addCreditCard = async (data) => {

    return fetcher({
      url: `/banking/credit-cards`,
      method: 'POST',
      data
    });
  }

  bankTransactionCategories = async (data) => {

    return fetcher({
      url: `/banking/transaction-categories`,
      method: 'GET',
    });
  }

  updateTransactionCategory = async (data) => {
    const { payload, txnId } = data

    return fetcher({
      url: `/banking/accounts/transactions/${txnId}`,
      method: 'PATCH',
      data: payload
    });
  }

  getBankAggregates = async (data) => {

    return fetcher({
      url: `/banking/aggregates`,
      method: 'GET',
    });
  }
}




const bankServiceInstance = new BankService();
export default bankServiceInstance;
