import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetPlanningSavings(data) {
  return useQuery({
    queryKey: [PlanningServiceInstance.queryKeys.getPlanningSavings + data],
    queryFn: () => PlanningServiceInstance.getPlanningSavings(data),
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetPlanningSavings;