import { useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import bankServiceInstance from '../../services/bank-service';

function useUpdateFixedDeposits() {

  return useMutation({
    mutationFn: bankServiceInstance.putFixeddeposits,
    onSuccess: (data) => {
      successToast('Updated Fixed Deposits Successfully')
       },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUpdateFixedDeposits;
