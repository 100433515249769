import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import authServiceInstance from '../../services/auth-service';
import { errorToast, successToast } from '../../utils/toastMassage';

function useUserLogin() {

  const navigate = useNavigate()

  return useMutation({
    mutationFn: authServiceInstance.userLogin,
    onSuccess: (data) => {
      successToast('Login Successfully')
      setTimeout(() => {
        window.localStorage.setItem('isLogedIn', true);
        navigate('/dashboard');
      }, 500);
    },
    onError: (error) => {
      console.log(error,'LOGIN ERR');
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUserLogin;
