import React from "react";
import { Link } from "react-router-dom";
import {FinIcon} from './Common';

const AccountSettings = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col-12">
                <h3 className="inter-700">Account Settings</h3>
            </div>
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="d-flex justify-content-center flex-column align-items-center mb-4 pb-xxl-2">
                        <img src="images/avatar.png" className="mmwh-120 img-cover rounded-pill bg-b50 p-1" alt="John Doe"/>
                        <h4 className="inter-600 mt-2 mb-1">John Doe</h4>
                        <a href="mailto:johndoe123@gmail.com" className="text-b100 text-decoration-none text-hover-primary">johndoe123@gmail.com</a>
                    </div>

                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-cyan-10 text-cyan">
                                    <FinIcon name="user2" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Personal Info</h5>
                                    <p>Edit your and your spouse's basic details.</p>
                                    <button type="button" className="pfc-link" onClick={()=> props.passingMethod({personal_information: true})}>
                                        <FinIcon name="arrowright" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-primary-10 text-primary">
                                    <FinIcon name="mail2" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Email Preferences</h5>
                                    <p>Customize your email preferences.</p>
                                    <button type="button" className="pfc-link" onClick={()=> props.passingMethod({email_preferences: true})}>
                                        <FinIcon name="arrowright" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-yellow-10 text-yellow">
                                    <FinIcon name="lock2" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Security Settings</h5>
                                    <p>Manage your email, password, etc.</p>
                                    <button type="button" className="pfc-link" onClick={()=> props.passingMethod({security_settings: true})}>
                                        <FinIcon name="arrowright" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-warning-10 text-warning300">
                                    <FinIcon name="financer" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Financial Accounts</h5>
                                    <p>Administer your financial accounts.</p>
                                    <button type="button" className="pfc-link" onClick={()=> props.passingMethod({financial_accounts: true})}>
                                        <FinIcon name="arrowright" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-pink-10 text-pink">
                                    <FinIcon name="device" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Authorised Devices</h5>
                                    <p>Manage all devices that are logged into your account.</p>
                                    <button type="button" className="pfc-link" onClick={()=> props.passingMethod({authorised_devices: true})}>
                                        <FinIcon name="arrowright" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="profile-feature-card">
                                <div className="pfc-icon bg-purple-10 text-purple">
                                    <FinIcon name="logout2" />
                                </div>
                                <div className="pfc-content">
                                    <h5>Sign Out</h5>
                                    <p>Sign out of your logged-in account.</p>
                                    <Link to={'/.'} className="pfc-link">
                                        <FinIcon name="arrowright" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountSettings;