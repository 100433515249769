import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FinIcon } from "../components/Common";
import useGetBankAccountTransactions from "../hooks/bank-services/get-bank-account-transactions";
import { format } from "date-fns";
import { Pagination } from "antd";
import useGetBankTransactionCategories from "../hooks/bank-services/gey-transaction-categories";
import useUpdateTransactionCategory from "../hooks/bank-services/update-transaction-category";
import { useQueryClient } from "@tanstack/react-query";
import bankServiceInstance from "../services/bank-service";
import useGetBankAccounts from "../hooks/bank-services/get-banking-accounts";

const AllTransactions = () => {

    // init
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const accountIdFromParams = params.get('accountId');
    const pageLimit = 8;
    const queryClient = useQueryClient();
 
    // state
    const [accountId, setAccountId] = useState(accountIdFromParams);
    const [currentPage, setcurrentPage] = useState(0)
    const [nextPageToken, setNextPageToken] = useState(0)

    useEffect(() => {
        // Set accountId if it's present in the URL params
        if (accountIdFromParams) {
            setAccountId(accountIdFromParams);
        }
    }, [accountIdFromParams]);

    // Function to format numbers with commas
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }

    const OptionSelect = ({ item, type }) => {

        const updateTransctionCat = (val, txnId) => {

            useUpdateTransactionCategoryMutation.mutate({ payload: { category: val }, txnId: txnId },
                {
                    onSuccess: () => {

                        queryClient.invalidateQueries({ queryKey: [bankServiceInstance.queryKeys.bankAccountTransactions + accountId + currentPage] })
                    }
                }
            )
        }
        return (

            <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm h-25"
                id="category"
                name="category"
                // onInput={formik.handleChange}
                value={!!item?.category ? item?.category : ''}
                onChange={(e) => { updateTransctionCat(e.target.value, item?.transactionId) }}
            >
                <option>-- Select --</option>
                {type === 'CREDIT' ? transactioncategoriesData?.data?.result?.CREDIT?.map((citem, index) => {

                    return <option key={index?.toString()} color="red">{citem}</option>
                }) : transactioncategoriesData?.data?.result?.DEBIT?.map((citem, index) => {

                    return <option key={index?.toString()} color="red">{citem}</option>
                })}

                <option value={'OTHER'}>Other</option>
            </select>
        )
    }

    // api call
    const { data: accountTransactionsData, isLoading: accountTransactionsIsLoading, isPending: accountTransactionsIsPending } = useGetBankAccountTransactions({ accountId: accountId, page: nextPageToken })
    const { data: bankAccountsData, isLoading: bankAccountsIsLoading, isFetching: bankAccountsIsFetching } = useGetBankAccounts(0)
    const { data: transactioncategoriesData, isLoading: transactioncategoriesIsLoading, isFetching: transactioncategoriesIsFetching } = useGetBankTransactionCategories()
    const useUpdateTransactionCategoryMutation = useUpdateTransactionCategory()

    const nextPage = () => {
        let pageLimit = Math.ceil(accountTransactionsData?.data?.result?.totalCount / 20)
        if (pageLimit >= currentPage) {
            setcurrentPage(currentPage + 1)
            setNextPageToken(accountTransactionsData?.data?.result?.nextPageToken  )

            
        }
    }

    const prevPage = () => {
        let pageLimit = Math.round(accountTransactionsData?.data?.result?.totalCount / 20)
        if (pageLimit >= currentPage && currentPage >= 0) {
            setcurrentPage(currentPage - 1)
            setNextPageToken(accountTransactionsData?.data?.result?.nextPageToken -20  )

        }
    }

    const itemPaginationRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <Link className={`page-link ${currentPage === 0 ? 'disabled' : ''}`} onClick={prevPage}>Prev</Link>
            );
        }
        if (type === 'next') {
            return (
                <Link className="page-link" onClick={nextPage}>Next</Link>
            );
        }
        return originalElement;
    };

    const onChangePage = (page, pageSize) => {
        setcurrentPage(page)
        setNextPageToken(accountTransactionsData?.data?.result?.nextPageToken  )

    }


    return (<>

        <div className="row row-inner">
            <div className="col d-flex flex-row align-items-center gap-4 mb-3">
                <h3 className="inter-700 mb-0">All Transactions</h3>
                <select className="form-select inter-500 bg-white px-3 w-auto minh-40px"
                    id="category"
                    name="category"
                    // onInput={formik.handleChange}
                    value={accountId}
                    onChange={(e) => { setAccountId(e.target.value); setcurrentPage(0) }}
                >
                    <option>-- Select Your Bank --</option>
                    {
                        bankAccountsData?.data?.result?.listOfItems?.map((item, index) => {
                            return <option key={index?.toString()} value={item?.id} >{item?.bankName}</option>
                        })
                    }
                </select>
            </div>
            <div className="col-auto mb-3">
                <div className="input-group input-group-datepicker">
                    <input type="text" className="form-control rounded-3" defaultValue="28 Feb 2024 - 28 Nov 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                    <FinIcon name="calendar" />
                    {/* <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                        <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">5 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                    </ul> */}
                </div>
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Date & Time</th>
                                <th>Description</th>
                                <th>CREDIT/DEBIT</th>
                                <th width="150">Total Amount</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!accountTransactionsData?.data?.result?.listOfItems && accountTransactionsData?.data?.result?.listOfItems?.map((item) => {
                                const parsedDateTime = new Date(item?.txnTimestamp);
                                return (
                                    <tr key={item.txnId}>
                                        <td>{format(parsedDateTime, "d MMMM yyyy h:mm a")}</td>
                                        <td><span className="inter-600">{item.description}</span></td>
                                        <td>{item.type}</td>
                                        <td>
                                         { item?.type === 'CREDIT' ?  <span className="text-success300 text-nowrap">+₹{formatNumberWithCommas(item?.amount)}</span> : <span className="text-danger text-nowrap">-₹{formatNumberWithCommas(item?.amount)}</span>}
                                        </td>
                                        <td>
                                            {!!transactioncategoriesData?.data?.result && item?.type === 'CREDIT' ? <OptionSelect item={item} type={item?.type} /> : <OptionSelect item={item} type={item?.type} />
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                            {accountTransactionsIsLoading || accountTransactionsIsPending ? (
                                <tr key="loading">
                                    <td colSpan="7" className="text-center">
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>
                {!!accountTransactionsData?.data?.result?.listOfItems && <nav className="py-3 py-sm-4 align-content-center">
                    <Pagination className="pagination justify-content-center" current={currentPage} total={Math.ceil(accountTransactionsData?.data?.result?.totalCount / 20)} pageSize={1} itemRender={itemPaginationRender} onChange={onChangePage} />
                </nav>}
            </div>
        </div>
    </>)
}
export default AllTransactions;
