import { keepPreviousData, useQuery } from '@tanstack/react-query';

import insuranceServiceInstance from '../../services/insurance-service';

function useGetInsuranceScore() {
  return useQuery({
    queryKey: [insuranceServiceInstance.queryKeys.getInsuranceScore ],
    queryFn: () => insuranceServiceInstance.getInsuranceScore(),
    placeholderData : keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetInsuranceScore;