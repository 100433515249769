import { keepPreviousData, useQuery } from '@tanstack/react-query';

import LoansServiceInstance from '../../services/loan-service';

function useGetLoanScore() {
  return useQuery({
    queryKey: [LoansServiceInstance.queryKeys.getLoanScore],
    queryFn: LoansServiceInstance.getLoanScore,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetLoanScore;