import React from "react";
import { useFormik } from "formik";

import { FinIcon, AddBankingBack } from '../components/Common';
import { addFixedDepositSchema } from "../utils/validationSchema";
import useAddFixedDepositBank from "../hooks/bank-services/add-fixed-deposit";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/transfer/search";
const FixedDepositAdd = ({ ...props }) => {
    const location = useLocation()
    const activeTab = location.state.activeTab

    const navigate = useNavigate()
    //api call
    const useAddFixedDepositBankMutation = useAddFixedDepositBank()

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { bankname: "", amount: '', rateofinterest: 0, tenure: '', category: '', insights: '' },
        validationSchema: addFixedDepositSchema,
        onSubmit: values => {
            // console.log(values);
            let payload = {
                institution: formik.values.bankname,
                amount: Number(formik.values.amount),
                interestRate: Number(formik?.values.rateofinterest),
                tenureInMonths: formik?.values.tenure,
                category: formik?.values?.category.toLowerCase(),
                insights: formik?.values?.insights
            }
            console.log(payload);

            useAddFixedDepositBankMutation.mutate(payload, {
                onSuccess: (data) => {
                    formik.resetForm()
                    navigate({
                        pathname: '/banking',
                        search: `?tab=${activeTab}`
                    });
                },
            })
        }
    })
    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add Fixed Deposit</h3>
                <p className="text-b200 small">
                    Fixed Deposit add basic details.
                </p>
            </div>
            <div className="col-auto">
                <AddBankingBack value="FIXED_DEPOSIT" activeTab={activeTab} />
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="fdBankName" className="small inter-500 text-b200 mb-2">Bank Name</label>
                            <input type="text" id="fdBankName"
                                value={formik?.values?.bankname}
                                onBlur={formik.handleBlur('bankname')}
                                onInput={formik.handleChange('bankname')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Bank Name" />
                            {(formik.errors.bankname && formik.touched.bankname) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.bankname}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="fdAmount" className="small inter-500 text-b200 mb-2">Amount</label>
                            <input type="text" id="fdAmount" value={formik.values?.amount}
                                onBlur={formik.handleBlur('amount')}
                                onInput={formik.handleChange('amount')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.amount && formik.touched.amount) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.amount}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="fdAmount" className="small inter-500 text-b200 mb-2">Interest Rate <span>({formik?.values?.rateofinterest}%)</span></label>
                            <input type="text" id="fdAmount" value={formik.values?.rateofinterest}
                                onBlur={formik.handleBlur('rateofinterest')}
                                onInput={formik.handleChange('rateofinterest')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="" />
                            {(formik.errors.rateofinterest && formik.touched.rateofinterest) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.rateofinterest}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="fdTenure" className="small d-block inter-500 text-b200 mb-2">Tenure <span>({formik?.values?.tenure} Months)</span></label>
                            <input type="range" id="fdTenure" value={formik.values?.tenure}
                                onBlur={formik.handleBlur('tenure')}
                                onInput={formik.handleChange('tenure')} className="form-range form-control-fin inter-500 bg-white px-3 shadow-sm" name="tenure" min="0" max="800" step="6" />
                            {(formik.errors.tenure && formik.touched.tenure) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.tenure}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="fdCategory" className="small inter-500 text-b200 mb-2">Category</label>
                            <select
                                id="fdCategory"
                                name="category"
                                value={formik.values.category}
                                onChange={(e) => { formik.setFieldValue('category', e.target.value) }}
                                onBlur={formik.handleBlur}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                            >
                                <option value="">Select Category</option>
                                <option value="Cumulative">Cumulative</option>
                                <option value="Non-Cumulative">Non-Cumulative</option>
                            </select>
                            {(formik.errors.category && formik.touched.category) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.category}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="fdInsights" className="small inter-500 text-b200 mb-2">Insights</label>
                            <input type="text" id="fdInsights" value={formik.values?.insights}
                                onBlur={formik.handleBlur('insights')}
                                onInput={formik.handleChange('insights')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Insight" />
                            {(formik.errors.insights && formik.touched.insights) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.insights}
                            </p> : null}
                        </div>
                    </div>
                </div>

                <button type="button" onClick={formik.handleSubmit} disabled={useAddFixedDepositBankMutation?.isPending} className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save Fixed Deposit {useAddFixedDepositBankMutation?.isPending && <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        </div>
    )
}
export default FixedDepositAdd;