import { fetcher } from "../utils/fetcher";

class AuthService {
  queryKeys = {
    userLogin: 'userLogin',
    getUserProfile : 'getUserProfile'
  };

  userLogin = async (data) => {
    // console.log({ data });
    return fetcher({
      url: '/accounts/login',
      method: 'POST',
      data,
    });
  }

  getUserProfile = async (data) => {
    return fetcher({
      url: `/users/me`,
      method: 'GET',
    });
  
  }
}



const authServiceInstance = new AuthService();
export default authServiceInstance;
