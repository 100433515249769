import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';


function useGetFixedIncomeInvestment(data) {

  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.fixedIncomeInvestment],
    queryFn:  investmentServiceInstance.fixedIncomeInvestment,
    refetchOnWindowFocus: false

    // placeholderData: keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetFixedIncomeInvestment;