import React from "react";
import Chart from 'react-apexcharts';
import useGetPlanningRetirmentTotalAsset from "../hooks/planning/get-planning-retirment-asset";
import useGetPlanningRetirmentGraph from "../hooks/planning/get-planning-retirment";
import useGetPlanningGoals from "../hooks/planning/get-planning-goals";
import { formatNumberWithCommas } from "../utils/common";

const RetirementPlannerChart = ({ ...props }) => {
    
   
    // api 
    const { data: planningRetirmentTotalAssetData, isLoading: planningRetirmentTotalAssetIsLoading, isFetching: planningRetirmentTotalAssetIsFetching } = useGetPlanningRetirmentTotalAsset()
    const { data, isLoading, isFetching } = useGetPlanningRetirmentGraph({ age: props?.ageuser, simulate: false })
    const { data: planningGoalsData, isLoading: planningGoalsIsLoading, isFetching: planningGoalsIsFetching } = useGetPlanningGoals()

      
 
    const pointsMap = planningGoalsData?.data?.result?.map((goal, index) => {
        const goalYear = new Date(goal.goalDate).getFullYear(); // Get the year from the goalDate
        const currentYear = new Date().getFullYear(); // Get the current year
      
        // Calculate the age difference from 25 years
        const ageDifference = 25 - (currentYear - goalYear);
      
        return {
          y:  ( goal.amount),
          x: ageDifference, // Age difference as the x value
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: index % 2 === 0 ? "#4cb061" : "#f4c678",
            radius: 10
          },
          label: {
            offsetY: -1,
            borderColor: index % 2 === 0 ? "#4cb061" : "#f4c678",
            borderWidth: 2,
            text: goal.name || 'Goal',
            style: {
              color: "#000",
              background: "#fff",
              fontSize: 13
            }
          }
        };
      }) || [];
      
       
      

    const AGE_TO_SAVINGS_RATE_MAP = {
        20: 10.0,
        21: 10.7,
        22: 11.3,
        23: 12.0,
        24: 12.6,
        25: 13.3,
        26: 13.9,
        27: 14.6,
        28: 15.2,
        29: 15.9,
        30: 16.5,
        31: 17.2,
        32: 17.8,
        33: 18.5,
        34: 19.1,
        35: 19.75,
        36: 20.4,
        37: 21.1,
        38: 21.7,
        39: 22.4,
        40: 23.0,
        41: 23.7,
        42: 24.3,
        43: 25.0,
        44: 25.6,
        45: 26.3,
        46: 26.9,
        47: 27.6,
        48: 28.2,
        49: 28.9,
        50: 29.5,
        51: 30.2,
        52: 30.8,
        53: 31.5,
        54: 32.1,
        55: 32.8,
        56: 33.4,
        57: 34.1,
        58: 34.7,
        59: 35.4,
        60: 36.0,
        61: 36.7,
        62: 37.3,
        63: 38.0,
        64: 38.6,
        65: 39.3,
        66: 39.9,
    };

    const getSavingsRate = (age) => {
        if (age < 20) {
            return 10;
        } else if (age > 66) {
            return 40;
        }
        console.log(AGE_TO_SAVINGS_RATE_MAP[age]);
        return AGE_TO_SAVINGS_RATE_MAP[age];
    };

    const calculateRetirementPlan = ({
        inputAge = props?.ageuser,
        inputRetirementAge = 65,
        inputLifeExpectancy = 85,
        inputInflationRate = 6,
        inputRetirementIncomeReplacementRatio = 70,
        inputPostRetirementReturnOnCorpus = 2,
        inputReturnOnInvestment = 12,
        retirementAssest = !!planningRetirmentTotalAssetData?.data?.result?.totalAssetValue ? planningRetirmentTotalAssetData?.data?.result?.totalAssetValue : 7200000,
        currentIncome = 1500000,
    }) => {
        let currAge = inputAge;
        let value2 = retirementAssest;
        let corpus = retirementAssest;
        const annualRetirementSavings = (currentIncome * getSavingsRate(inputAge)) / 100;
        const estimatedRetirementExpenses =
            ((inputRetirementIncomeReplacementRatio * currentIncome) / 100) *
            Math.pow(1 + inputInflationRate / 100, inputRetirementAge - inputAge);
        const dataList = [
            {
                age: currAge,
                // value1: value1,
                // value2: value2,
                corpus: corpus,
            },
        ];

        while (++currAge <= inputRetirementAge) {
            value2 =
                corpus +
                annualRetirementSavings * Math.pow(1 + inputInflationRate / 100, currAge - 1 - inputAge);
            corpus = value2 * (1 + inputReturnOnInvestment / 100);
            dataList.push({
                age: currAge,
                // value2: parseFloat(value2.toFixed(2)),
                corpus: parseFloat(corpus.toFixed(2)),
            });
        }
        currAge--;
        let postRetirementExpense = estimatedRetirementExpenses;
        while (++currAge <= inputLifeExpectancy) {
            corpus = (corpus - postRetirementExpense) * (1 + inputPostRetirementReturnOnCorpus / 100);
            dataList.push({
                age: currAge,
                // value2: parseFloat(value2.toFixed(2)),
                corpus: parseFloat(corpus.toFixed(2)),
                // postRetirementExpense: parseFloat(postRetirementExpense.toFixed(2)),
            });
            postRetirementExpense =
                estimatedRetirementExpenses *
                Math.pow(1 + inputInflationRate / 100, currAge + 1 - (inputRetirementAge + 1));
        }
        return dataList;
    };
  //  const retirmentPlan = calculateRetirementPlan({});
    
    
    const staticAnnotations = [
        {
            y: 175845666.28,
            x: 50,
            marker: {
                size: 8,
                fillColor: "#fff",
                strokeColor: "#f4c678",
                radius: 10
            },
            label: {
                offsetY: -1,
                borderColor: '#f4c678',
                borderWidth: 2,
                text: 'Stocks',
                style: {
                    color: "#000",
                    background: "#fff",
                    fontSize: 13
                },
            }
        },
        {
            y: 355604664.64,
            x: 56,
            marker: {
                size: 8,
                fillColor: "#fff",
                strokeColor: "#6ca1ef",
                radius: 10
            },
            label: {
                offsetY: -1,
                borderColor: '#6ca1ef',
                borderWidth: 2,
                text: 'Mutual Funds',
                style: {
                    color: "#000",
                    background: "#fff",
                    fontSize: 13
                },
            }
        },
        {
            y: 566316657.05,
            x: 60,
            marker: {
                size: 8,
                fillColor: "#fff",
                strokeColor: "#8dede2",
                radius: 10
            },
            label: {
                offsetY: -1,
                borderColor: '#8dede2',
                borderWidth: 2,
                text: 'Bonds',
                style: {
                    color: "#000",
                    background: "#fff",
                    fontSize: 13
                },
            }
        },
        {
            y: 1009693822.08,
            x: 65,
            marker: {
                size: 8,
                fillColor: "#fff",
                strokeColor: "#ff9bcd",
                radius: 10
            },
            label: {
                offsetY: -1,
                borderColor: '#ff9bcd',
                borderWidth: 2,
                text: 'Alternate',
                style: {
                    color: "#000",
                    background: "#fff",
                    fontSize: 13
                },
            }
        }
    ];
  
    
    const state = {
        series: [
            {
                name: "Series 1",
                type: "area",
                data: (data?.data?.result?.retirmentPlan?.map(x => Math.floor(x.corpus)))
 
            }
        ],
        options: {
            chart: {
                height: 460,
                type: "area"
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.6,
                    opacityTo: 0.3
                }
            },
            xaxis: {
                categories: data?.data?.result?.retirmentPlan?.map(x => x.age)
            },

        yaxis: {
            labels: {
                formatter: function (value) {
                    return formatNumberWithCommas(value); // Adds comma separators
                }
            }
        },

            stroke: {
                width: 2,
                curve: 'smooth'
            },
            annotations: {

                points: [
                       ...pointsMap

                ]

            }
        }
    }
    return (
        <Chart options={state.options} series={state.series} height={props.height} />

    )
}

export default RetirementPlannerChart;