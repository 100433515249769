import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {

    return(<>
    <div className="d-flex align-items-center min-vh-100">
        <div className="container py-5 text-center">
            <div className="row">
                <div className="col-12">
                    <h1 className="display-1 text-danger">Page Not Found</h1>
                    <hr className="w-25 mx-auto my-4"/>
                </div>
                <div className="col-12 my-4">
                    <Link to="/" className="btn btn-primary btn-lg">
                        Go to Home
                    </Link>
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default PageNotFound;
