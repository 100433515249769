import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FinIcon } from "../components/Common";
import useGetInsurance from "../hooks/insurance/get-insurance";
import { format } from "date-fns";
import { Pagination } from "antd";
import exportFromJSON from "export-from-json";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Fill } from "react-icons/ri";

const Insurance = () => {
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }

    const navigate = useNavigate()

    const editHandler = (item) => {
        navigate('/insurance-edit', { state: { item } })
    }
    const deleteInsuranceData =(item)=>{
      alert(item.id)
    }

    // init
    let limit = 8;

    // state
    const [currentPage, setcurrentPage] = useState(0)

    // api call
    const { data: getInsuranceData, isLoading: getInsuranceIsLoading, isFetching: getInsuranceIsFetching } = useGetInsurance(currentPage)

    const nextPage = () => {
        if (limit > currentPage) {
            setcurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage > 0) {
            setcurrentPage(currentPage - 1)
        }
    }

    const itemPaginationRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <Link className={`page-link ${(currentPage > 0) ? '' : 'disabled'}`} onClick={prevPage}>Prev</Link>
            );
        }
        if (type === 'next') {
            return (
                <Link className="page-link" onClick={nextPage}>Next</Link>
            );
        }
        return originalElement;
    };

    const onChangePage = (page, pageSize) => {
        setcurrentPage(page)
    }

    const onExportCSVFile = () => {
        const fileName = "InsuranceDataFinActive";
        const data = getInsuranceData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                Name: item?.policyName,
                // Type: item?.riderType,
                Coverage: item?.sumAssured,
                Premium: item?.premiumAmnt,
                PremiumFrequencyDueDate: `${item?.premiumFreq}/${item?.dueDate}`,
                MaturityDate: `${format(new Date(item?.maturityDate), 'dd-MMM-yyyy')}`,
                OwnershipNominee: item?.ownership,
                InvestmentLinked: item?.insuranceFiType,
                PolicyRider: item?.riderType,
                Insights: item?.insights
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }


    return (<>
        <div className="row row-inner">
            <div className="col-12">
                <div className="row g-2 g-xxl-3">
                    <div className="col">
                        <h3 className="inter-700">Insurance</h3>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex gap-2 gap-xxl-3">
                            <button type="button" onClick={() => onExportCSVFile()} className="btn btn-normal btn-light">
                                <FinIcon name="export" className="ts-1p4" />
                                Export
                            </button>
                            <Link to={'/insurance-add'} type="button" className="btn btn-normal btn-primary">
                                <FinIcon name="addcirlce" className="ts-1p4" />
                                Add
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-inner mt-3">
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Name</th>
                                {/* <th>Type</th> */}
                                <th>Coverage</th>
                                <th>Premium</th>
                                <th className="text-nowrap">Premium Frequency<br />/ Due Date</th>
                                <th>Maturity Date</th>
                                <th>Ownership <br />/ Nominee</th>
                                <th>Investment<br />Linked</th>
                                <th>Policy Rider</th>
                                <th>Insights</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getInsuranceIsLoading || getInsuranceIsFetching ?
                                <tr key="loading">
                                    <td colSpan="10" className="text-center">
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>

                                : <>
                                    {getInsuranceData?.data?.result?.listOfItems && getInsuranceData.data.result.listOfItems.length > 0 ? (
                                        !!getInsuranceData?.data?.result?.listOfItems && getInsuranceData?.data?.result?.listOfItems?.map((item, index) => (
                                            <tr key={index.toString()}>
                                                <td>{item?.policyName}</td>
                                                {/* <td>{item?.riderType}</td> */}
                                                <td>₹{formatNumberWithCommas(item?.sumAssured)}</td>
                                                <td>₹{formatNumberWithCommas(item?.premiumAmnt)}</td>
                                                <td>{item?.premiumFreq ?? 'N/A'}</td>
                                                <td className="text-nowrap">{!!item?.maturityDate ? format(new Date(item?.maturityDate), 'dd-MMM-yyyy') : 'N/A'}</td>
                                                <td className="text-nowrap">{item?.ownership}</td>
                                                <td>{item?.insuranceFiType ? 'Yes' : 'No'  || 'N/A'}</td>
                                                <td>{item?.riderType}</td>
                                                <td>₹{item?.insights ?? 'N/A'}</td>
                                                <td>
                                                    <div className="d-flex d-gap gap-2">

                                                        <button type="button" onClick={() => editHandler(item)}
                                                            className="btn btn-primary">
                                                            <FaRegEdit />
                                                        </button>
                                                        <button type="button" className="btn btn-primary d-gap d-flex align-items-center gap-1" onClick={() => { deleteInsuranceData(item) }}  >
                                                            <RiDeleteBin6Fill />
                                                        </button>
                                                    </div>


                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr key="no-data">
                                            <td colSpan="11" className="text-center">
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </>
                            }
                        </tbody>
                    </table>
                </div>
                {/* pagination */}
                {/* <nav className="py-3 py-sm-4 align-content-center">
                    <Pagination className="pagination justify-content-center" current={currentPage} total={limit * 1} pageSize={1} itemRender={itemPaginationRender} onChange={onChangePage} />
                </nav> */}
            </div>
        </div>
    </>)
}
export default Insurance;
