import { fetcher } from "../utils/fetcher";

class UserService {
  queryKeys = {
    putUser: 'putUser',
    getUser: 'getUser'
  };

  putUser = async (data) => {
    const { payload }= data
    console.log("payload here",payload)
    return fetcher({
      url: `/users/me`,
      method: 'PUT',
      data:payload
    });

  }
  getUserProfile = async (data) => {
    return fetcher({
      url: `/users/me`,
      method: 'GET', 
      data
    });
  }
}



const UserMeService = new UserService();
export default UserMeService;
