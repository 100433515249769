import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FinIcon } from "./Common";

const Aside = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if(document.documentElement.clientWidth<=1024){
            document.body.classList.remove('body-menuactive');
        }
    }, [navigate]);

    const menu = {
        main: [{
            name: "Dashboard",
            icon: 'dashboard',
            href: "dashboard"
        },
        {
            name: "Investment",
            icon: 'investment',
            href: "investment"
        },{
            name: "Planning",
            icon: 'planning',
            href: "planning"
        },{
            name: "Tax",
            icon: 'wallet',
            href: "tax"
        },{
            name: "Banking",
            icon: 'banking',
            href: "banking"
        },{
            name: "Insurance",
            icon: 'insurance',
            href: "insurance"
        },{
            name: "Debt",
            icon: 'currency',
            href: "debt"
        },{
            name: "Simulator",
            icon: 'monitor',
            href: "simulator"
        },{
            name: "UI Kit",
            icon: 'pencil',
            href: "ui-kit"
        }],
        other: [{
            name: "Settings",
            icon: 'settings',
            href: "settings"
        },{
            name: "Help",
            icon: 'help',
            href: "help"
        },{
            name: "Log Out",
            icon: 'logout',
            href: "login"
        }]
    }
    return(
    <aside className="fin-aside">
        <div className="fin-logobox">
            <Link to={'/'}>
                <img
                    src="images/finactive-logo.svg"
                    alt="Finactive Logo"
                    width={137}
                    height={18}
                />
            </Link>
            <button type="button" className="fin-menutoggler" onClick={()=> document.body.classList.toggle('body-menuactive')}>
                <FinIcon name="arrowleft"/>
            </button>
        </div>
        <div className="fin-asideinner">
            <div className="fin-mainmenu">
                <h6 className="fin-asidelabel">Main Menu</h6>
                {menu.main.map((link) => {
                return (
                    <NavLink
                        key={link.name}
                        to={'/'+link.href}
                        className="fin-asidelink"
                    >
                        <FinIcon name={link.icon}/>
                        <span>{link.name}</span>
                    </NavLink>
                );
                })}
            </div>
            <div className="fin-othermenu">
                <h6 className="fin-asidelabel">Other Menu</h6>
                {menu.other.map((link) => {
                    return (
                        <NavLink
                            key={link.name}
                            to={'/'+link.href}
                            className="fin-asidelink"
                        >
                            <FinIcon name={link.icon}/>
                            <span>{link.name}</span>
                        </NavLink>
                    );
                })}
            </div>
        </div>
    </aside>)
}
export default Aside;