import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { fetcher } from "../utils/fetcher";

const SMBADonutChart = ({ ...props }) => {

    var totalRecord = {
        options: {
            chart: {
                id: 'totalRecord_Donut',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['Stocks', 'Mutual Funds', 'Bonds', 'Alternate'],
            colors: ['#F4C678', '#6CA1EF', '#8DEDE2', '#FF9BCD'],
            plotOptions: {
                pie: {
                    donut: {
                        size: '70',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 6,
                                show: true,
                                fontSize: "24px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Total Investment",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;


                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr';
                                        }
                                        
                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        }
                                        
                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'; 
                                        }
                                        
                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`; 
                                    }

                                    return ''
                                }
                            }
                        }
                    },
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                colors: ['#fff']
            },
            responsive: [{
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: false
        }
    };

    var stocksRecord = {
        options: {
            chart: {
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['Total Investment', 'Large Cap'],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "22px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Stocks",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;

                                        
                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr'; 
                                        }
                                        
                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L'; 
                                        }
                                        
                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'; 
                                        }
                                        
                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`; 
                                    }

                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#F4C678',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    var mutualFundsRecord = {
        options: {
            chart: {
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['Total Investment', 'Debt Scheme-Overnight Fund', 'Other Scheme-Index Funds'],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "22px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Mutual Funds",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;
                                    
                                        
                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr'; 
                                        } 
                                        
                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L'; 
                                        } 
                                        
                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'; 
                                        } 
                                        
                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }
                                    
                                        return `₹${finalResult}`; 
                                    }
                                    
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#6CA1EF',
                    shadeTo: 'light',
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    var bondsRecord = {
        options: {
            chart: {
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['Total Investment', 'Fixed Deposit'],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "22px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Fixed Income",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;
                                    
                                        
                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr'; 
                                        } 
                                        
                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L'; 
                                        } 
                                        
                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
                                        } 
                                        
                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }
                                    
                                        return `₹${finalResult}`; 
                                    }
                                    
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#8DEDE2',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    var alternateRecord = {
        options: {
            chart: {
                id: 'alternate_Donut',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['Alternate'],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "22px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Alternate",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;
                                    
                                        
                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr'; // Convert to Crores
                                        } 
                                        
                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        } 
                                        
                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'; 
                                        } 
                                        
                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }
                                    
                                        return `₹${finalResult}`; 
                                    }
                                    
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#FF9BCD',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await fetcher({
                    url: `/investments/aggregates`,
                    method: 'GET',
                });
                setData(response);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();

    }, []);

    const getObjValues = (obj = {}, keyToRemove = 'totalInvestment') => {
        const { [keyToRemove]: _, ...newObj } = obj;
        return Object.values(newObj);
    }

    const getObjKeys = (obj = {}, keyToRemove = 'totalInvestment') => {
        const { [keyToRemove]: _, ...newObj } = obj;
        return Object.keys(newObj);
    }

    return (<div className="row justify-content-xl-between g-2">
        <div className="col-12 col-md-auto">
            <div className="fin-donutchart">
                <Chart
                    type="donut" width={props.height} height={props.height}
                    options={totalRecord.options}
                    series={
                        loading ? [250, 250, 250, 250] : [
                            data.result.stocks.totalInvestment,
                            data.result.mutualFunds.totalInvestment,
                            data.result.bonds.totalInvestment,
                            data.result.alternatives.totalInvestment
                        ]
                    }
                />
            </div>
        </div>
        <div className="col-12 col-md-auto align-self-center">
            <div className="fin-donutdivider"></div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={{ ...stocksRecord.options, labels: getObjKeys(data.result?.stocks) }}
                    series={
                        loading ? [100, 50] : getObjValues(data.result.stocks)
                    }
                />
            </div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={{ ...mutualFundsRecord.options, labels: getObjKeys(data.result?.mutualFunds) }}
                    series={
                        loading ? [100, 50] : getObjValues(data.result.mutualFunds)
                    }
                />
            </div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={{ ...bondsRecord.options, labels: getObjKeys(data.result?.bonds) }}
                    series={
                        loading ? [100, 50, 25] : getObjValues(data.result.bonds)
                    }
                />
            </div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={alternateRecord.options}
                    series={
                        loading ? [98, 2] : [
                            data.result.alternatives.totalInvestment
                        ]
                    }
                />
            </div>
        </div>
    </div>
    )
}

export default SMBADonutChart;