import React from "react";
import { Link } from "react-router-dom";
import { FinIcon } from '../components/Common';
import useAddInsurance from "../hooks/insurance/add-insurance";
import { useFormik } from "formik";
import { addInsuranceSchema } from "../utils/validationSchema";
import { useNavigate } from "react-router-dom";

const PeronalInformation = ({ ...props }) => {
    const navigate = useNavigate();
    const useAddInsuranceMutation = useAddInsurance();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            policyName: "",
            sumAssured: "",
            premiumAmount: "",
            premiumFrequency: "",
            premiumDate: "",
            maturityDate: "",
            ownership: "",
            insuranceFiType: "",
            riderType: "",
            // remainingTenure: "0",
            // insights: ""
        },
        validationSchema: addInsuranceSchema,
        onSubmit: values => {
            const payload = {
                policyName: formik?.values?.policyName,
                sumAssured: Number(formik?.values?.sumAssured),
                premiumAmount: Number(formik?.values?.premiumAmount),
                premiumFrequency: formik?.values?.premiumFrequency,
                premiumDate: formik?.values?.premiumDate,
                maturityDate: formik?.values?.maturityDate,
                ownership: formik?.values?.ownership,
                insuranceFiType: formik?.values?.insuranceFiType === 'YES' ? true : false,
                riderType: formik?.values?.riderType,
                // remainingTenure: formik?.values?.remainingTenure,
                // insights: formik?.values?.insights
            };

            useAddInsuranceMutation?.mutate(payload, {
                onSuccess: (data) => {
                    formik.resetForm()
                    navigate('/insurance')
                },
            })
        },
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add New Insurance</h3>
            </div>
            <div className="col-auto">
                <Link to={'/insurance'} type="button" className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </Link>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    New insurance add basic details.
                </p>
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="policyName" className="small inter-500 text-b200 mb-2">Policy Name</label>
                            <input type="text" id="policyName"
                                onInput={formik.handleChange}
                                value={formik.values.policyName}
                                onBlur={formik.handleBlur('policyName')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Policy Name" />
                            {formik.errors.policyName && formik.touched.policyName && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.policyName}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="sumAssured" className="small inter-500 text-b200 mb-2">Sum Assured</label>
                            <input type="text" id="sumAssured"
                                onInput={formik.handleChange}
                                value={formik.values.sumAssured}
                                onBlur={formik.handleBlur('sumAssured')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {formik.errors.sumAssured && formik.touched.sumAssured && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.sumAssured}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="premiumAmount" className="small inter-500 text-b200 mb-2">Premium Amount</label>
                            <input type="text" id="premiumAmount"
                                onInput={formik.handleChange}
                                value={formik.values.premiumAmount}
                                onBlur={formik.handleBlur('premiumAmount')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {formik.errors.premiumAmount && formik.touched.premiumAmount && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumAmount}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="premiumFrequency" className="small inter-500 text-b200 mb-2">Premium Frequency</label>
                            <select id="premiumFrequency"
                                onInput={formik.handleChange}
                                value={formik.values.premiumFrequency}
                                onBlur={formik.handleBlur('premiumFrequency')}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option value="" label="Select frequency" />
                                <option value="Monthly" label="Monthly" />
                                <option value="Quarterly" label="Quarterly" />
                                <option value="Semi-Annually" label="Semi-Annually" />
                                <option value="Annually" label="Annually" />
                            </select>
                            {formik.errors.premiumFrequency && formik.touched.premiumFrequency && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumFrequency}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="premiumDate" className="small inter-500 text-b200 mb-2">Premium Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="premiumDate"
                                name="premiumDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }} value={formik.values.premiumDate}
                                onBlur={formik.handleBlur('premiumDate')}
                            />
                            {formik.errors.premiumDate && formik.touched.premiumDate && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumDate}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="maturityDate"
                                name="maturityDate"
                                onInput={(e) => {
                                    const value = e.target.value;
                                    const dateParts = value.split('-');
                                    if (dateParts[0] && dateParts[0].length > 4) {
                                        e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                    }

                                    formik.handleChange(e);
                                }}
                                value={formik.values.maturityDate}
                                onBlur={formik.handleBlur('maturityDate')}
                            />
                            {formik.errors.maturityDate && formik.touched.maturityDate && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.maturityDate}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="ownership" className="small inter-500 text-b200 mb-2">Ownership / Nominee</label>
                            <input type="text" id="ownership"
                                onInput={formik.handleChange}
                                value={formik.values.ownership}
                                onBlur={formik.handleBlur('ownership')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Ownership" />
                            {formik.errors.ownership && formik.touched.ownership && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.ownership}
                            </p>}
                        </div>

                        <div className="col">
                            <label htmlFor="insuranceFiType" className="small inter-500 text-b200 mb-2">Investment Link</label>
                            <select id="insuranceFiType"
                                onChange={formik.handleChange}
                                value={formik.values.insuranceFiType}
                                onBlur={formik.handleBlur}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option value="">-SELECT-</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                            </select>
                            {formik.errors.insuranceFiType && formik.touched.insuranceFiType && (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.insuranceFiType}
                                </p>
                            )}
                        </div>


                        <div className="col">
                            <label htmlFor="riderType" className="small inter-500 text-b200 mb-2">Policy Rider</label>
                            <input type="text" id="riderType"
                                onInput={formik.handleChange}
                                value={formik.values.riderType}
                                onBlur={formik.handleBlur('riderType')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Policy Rider" />
                            {formik.errors.riderType && formik.touched.riderType && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.riderType}
                            </p>}
                        </div>

                        {/* <div className="col">
                            <label htmlFor="remainingTenure" className="small inter-500 text-b200 mb-2">Remaining Tenure (Years)</label>
                            <input type="text" id="remainingTenure"
                                onInput={formik.handleChange}
                                value={formik.values.remainingTenure}
                                onBlur={formik.handleBlur('remainingTenure')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Tenure" />
                            {formik.errors.remainingTenure && formik.touched.remainingTenure && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.remainingTenure}
                            </p>}
                        </div>
                        
                        <div className="col">
                            <label htmlFor="insights" className="small inter-500 text-b200 mb-2">Insights / Remarks</label>
                            <textarea id="insights"
                                onInput={formik.handleChange}
                                value={formik.values.insights}
                                onBlur={formik.handleBlur('insights')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Add Remarks" />
                            {formik.errors.insights && formik.touched.insights && <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.insights}
                            </p>}
                        </div> */}
                    </div>

                    <div className="mt-3">
                        <button type="button" className="btn btn-primary" onClick={formik.handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PeronalInformation;
