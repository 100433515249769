import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetEmergencyFund() {
  return useQuery({
    queryKey: [PlanningServiceInstance.queryKeys.getEmergencyFund],
    queryFn: PlanningServiceInstance.getEmergencyFund,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetEmergencyFund;