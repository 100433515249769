import { QueryClient, useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import bankServiceInstance from '../../services/bank-service';

function useAddBankAccounts() {

  return useMutation({
    mutationFn: bankServiceInstance.addBankAccounts,
    onSuccess: (data) => {
      successToast('Account Added Successfully')

    },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useAddBankAccounts;
