import { useMutation } from '@tanstack/react-query';

import { errorToast, successToast } from '../../utils/toastMassage';
import bankServiceInstance from '../../services/bank-service';

function useUpdateBankDetails() {

  return useMutation({
    mutationFn: bankServiceInstance.putBankingDetails,
    onSuccess: () => {
      successToast('updated Bank Details Successfully')
       },
    onError: (error) => {
      console.log(error);
      errorToast('Something went wrong, please try again later')

    },
  });
}

export default useUpdateBankDetails;
