import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';

function useGetInvestmentScore() {
  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.getInvestmentScore],
    queryFn: investmentServiceInstance.getInvestmentScore,
    refetchOnWindowFocus: false
    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetInvestmentScore;