import { fetcher } from "../utils/fetcher";

class LoansService {
  queryKeys = {
    getLoans: 'getLoans',
    getLoanScore: 'getLoanScore',

  };

  getLoans = async (data) => {

    return fetcher({
      url: `/loans`,
      method: 'GET',
    });

  }


  getLoanScore = async (data) => {

    return fetcher({
      url: `/loans/score`,
      method: 'GET',
    });

  }


}

const LoansServiceInstance = new LoansService();
export default LoansServiceInstance;
