import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
// import Chart from 'react-apexcharts'
import exportFromJSON from "export-from-json";
import SMBADonutChart from '../charts/SMBADonutChart';
import TotalInvestmentsChart from '../charts/TotalInvestmentsChart';
import MyPortfolioChart from '../charts/MyPortfolioChart';
import { FinIcon } from "../components/Common";
import { FaBootstrap, FaEye } from "react-icons/fa6";
import useGetInvestmentStock from "../hooks/investment/stock-investment";
import useGetMutualFundsInvestment from "../hooks/investment/mutual-funds-investment";
import useGetAlternateInvestment from "../hooks/investment/alternate-investment";
import { addMonths, format, parseISO } from "date-fns";
import useGetFixedIncomeInvestment from "../hooks/investment/fixed-Income-investment";
import useGetPlanningGoals from "../hooks/planning/get-planning-goals";
import useInvestmentLinkGoal from "../hooks/investment/linvestment-link-goal";
import { FaRegEdit } from "react-icons/fa";
import { addGoalSchema, editAlternateInvestmentsSchema } from "../utils/validationSchema";
import { useFormik } from "formik";
import useEditAlternateInvestment from "../hooks/investment/edit-alternate-investment";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Fill } from "react-icons/ri"
import { GoGoal } from "react-icons/go";
import * as bootstrap from 'bootstrap'; // Import Bootstrap JS

import { fetcher } from "../utils/fetcher";
import useGetGsecData from "../hooks/investment/gsec-data";
import deleteLinkGoal from "../hooks/planning/delete-goals";
import useDeleteLinkGoal from "../hooks/planning/delete-goals";


const Investment = () => {

    const navigate = useNavigate()


    // usestates
    // usestates
    const [fromDate, setfromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .toISOString()
        .split('T')[0]);
    const [toDate, settoDate] = useState(new Date().toISOString().split('T')[0]);


    const [sharePercentage, setSharePercentage] = useState();
    const [goalId, setGoalId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [investmentId, setInvestmentId] = useState('');
    const [linkedGoals, setLinkedGoals] = useState('');
    const [isValid, setIsValid] = useState(false);
console.log("linkedGoals",linkedGoals.investmentId)

    const handleSharePercentageChange = (e) => {
        let value = e.target.value;


        if (value.startsWith("0")) {
            value = parseInt(value, 10).toString();
        }

        if (value < 0 || value > 100 || isNaN(value)) {
            setErrorMessage("Share Percentage must be between 1 and 100");
            setIsValid(false);
        } else {
            setErrorMessage('');
            setSharePercentage(value);
            setIsValid(true);
        }
    };

    // api call
    const { data: stockData, isLoading: stockIsLoading, isFetching: stockIsFetching, refetch: refetchGoals } = useGetInvestmentStock({ from: fromDate, to: toDate })
    const { data: mutualFundsData, isLoading: mutualFundsIsLoading, isFetching: mutualFundsIsFetching, refetch: refetchMutualFund } = useGetMutualFundsInvestment()
    const { data: alternatesData, isLoading: alternateIsLoading, isFetching: alternateIsFetching, refetch: refetchAlternateInvestment } = useGetAlternateInvestment()
    const { data: fixedIncomeData, isLoading: fixedIncomeIsLoading, isFetching: fixedIncomeIsFetching, refetch: refetchFixedIncome } = useGetFixedIncomeInvestment()
    const { data: planningGoalsData, isLoading: planningGoalsIsLoading, isFetching: planningGoalsIsFetching,refetch: refetchPlanning } = useGetPlanningGoals()
    const { data: gSecData10yr, isLoading: gSecDataIsLoading, isFetching: gSecDataIsFetching } = useGetGsecData({ from: fromDate, to: toDate });



    const useInvestmentLinkGoalMutation = useInvestmentLinkGoal()
    const useEditAlternateInvestmentMutation = useEditAlternateInvestment()
    const [addurl, setAddurl] = useState('/stock-add');
    const [searchParams] = useSearchParams();
    const initialTab = searchParams.get('tab') || 'stockdata';
    const [activeTab, setActiveTab] = useState(initialTab);
    const [seriesData, setSeriesData] = useState({
        stockList: [],
        stockMfList: [],
        stockMfBondList: [],
        totalList: []
    });

    // Trigger `run()` when relevant props change
    useEffect(() => {
        if (stockData && mutualFundsData && alternatesData && fixedIncomeData && gSecData10yr) {
            const stockGraphData = getStockGraphData(new Date(fromDate), new Date(toDate));
            const mfGraphData = getMutualFundGraphData(new Date(fromDate), new Date(toDate));
            const bondsGraphData = getBondsGraphData(new Date(fromDate), new Date(toDate));
            const alternateGraphData = getAlternateGraphData(new Date(fromDate), new Date(toDate));

            // Combine all investment data
            const { stockList, stockMfList, stockMfBondList, totalList } = combineInvestmentData(
                stockGraphData,
                mfGraphData,
                bondsGraphData,
                alternateGraphData
            );

            // Only update if data has changed to prevent re-render loop
            setSeriesData((prevData) => {
                if (
                    JSON.stringify(prevData.stockList) !== JSON.stringify(stockList) ||
                    JSON.stringify(prevData.stockMfList) !== JSON.stringify(stockMfList) ||
                    JSON.stringify(prevData.stockMfBondList) !== JSON.stringify(stockMfBondList) ||
                    JSON.stringify(prevData.totalList) !== JSON.stringify(totalList)
                ) {
                    return { stockList, stockMfList, stockMfBondList, totalList };
                }
                return prevData;
            });


        }
    }, [stockData, mutualFundsData, alternatesData, fixedIncomeData, gSecData10yr, fromDate, toDate]);

    const dateGraphList = (startDate, endDate) => {
        const graphList = [];
        const tempDate = new Date(startDate);

        while (tempDate <= endDate) {
            const formattedDate = tempDate.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
            graphList.push(formattedDate);
            tempDate.setDate(tempDate.getDate() + 1);
        }

        return graphList;
    };


    const initGraphList = (startDate, endDate) => {
        const graphList = [];
        const tempDate = new Date(startDate);
        while (tempDate <= endDate) {
            graphList.push({
                date: new Date(tempDate),
                dateStr: tempDate.toISOString(),
                price: 0,
            });
            tempDate.setDate(tempDate.getDate() + 1);
        }
        return graphList;
    }

    const getStockGraphData = (startDate, endDate) => {
        const result = initGraphList(startDate, endDate);
        const dateStrings = result.map(item => item.dateStr);
        stockData?.data?.result?.listOfItems.forEach((item) => {
            const stockId = item.issuer.id;
            const quantity = item.quantity;
            const purchaseDate = new Date(item.purchasedDate);
            const historyList = stockData?.data?.result?.stockHistoryData[stockId];
            const tempMap = {};


            historyList?.forEach((historyRec) => {
                const historyDate = new Date(historyRec.date);
                if (purchaseDate <= historyDate) {
                    tempMap[historyRec.date] = Number(historyRec.tradePrice) * quantity;
                }
            });
            let prevPrice = 0;
            result.forEach((rec) => {
                prevPrice = tempMap[rec.dateStr] ?? prevPrice;
                rec.price += prevPrice;
            });
        });

        return result;
    }

    const getMutualFundGraphData = (startDate, endDate) => {
        const result = initGraphList(startDate, endDate);
        mutualFundsData?.data?.result.listOfItems.forEach((item) => {
            const fundId = item.fund.id;
            const quantity = item.quantity;
            const purchaseDate = new Date(item.purchasedDate);
            const historyList = mutualFundsData?.data?.result.mutualFundHistoryData[fundId];
            const tempMap = {};
            historyList?.forEach((historyRec) => {
                const historyDate = new Date(historyRec.date);
                if (purchaseDate <= historyDate) {
                    tempMap[historyRec.date] = Number(historyRec.nav) * quantity;
                }
            });
            let prevPrice = 0;
            result.forEach((rec) => {
                prevPrice = tempMap[rec.dateStr] ?? prevPrice;
                rec.price += prevPrice;
            });
        });
        return result;
    }

    const getBondsGraphData = (startDate, endDate) => {
        const result = initGraphList(startDate, endDate);
        fixedIncomeData?.data?.result?.listOfItems.forEach((item) => {
            const buyingPrice = Number(item.buyingPrice);
            const purchaseDate = new Date(item.purchasedDate);
            const tempMap = {};
            if (purchaseDate <= startDate) {
                tempMap[startDate.toISOString()] = buyingPrice;
            } else if (purchaseDate <= endDate) {
                tempMap[purchaseDate.toISOString()] = buyingPrice;
            }





            gSecData10yr?.data?.result?.historicalData?.forEach((historyRec) => {
                const historyDate = new Date(historyRec.timestamp);
                if (startDate <= historyDate && historyDate <= endDate) {
                    tempMap[historyRec.timestamp] = Number(historyRec.closeVal);
                }
            });
            let prevPrice = 0;
            result.forEach((rec) => {
                prevPrice = tempMap[rec.dateStr] ?? prevPrice;
                rec.price += prevPrice;
            });
        });
        return result;
    }

    const getAlternateGraphData = (startDate, endDate) => {
        const result = initGraphList(startDate, endDate);
        alternatesData?.data?.result?.listOfItems.forEach((item) => {
            const investmentId = item.investmentId;
            const buyingPrice = Number(item.buyingPrice);
            const purchaseDate = new Date(item.purchasedDate);
            const historyList = alternatesData?.data?.result?.investmentHistoryData[investmentId];
            const tempMap = {};
            if (purchaseDate <= startDate) {
                tempMap[startDate.toISOString()] = buyingPrice;
            } else if (purchaseDate <= endDate) {
                tempMap[purchaseDate.toISOString()] = buyingPrice;
            }
            historyList?.forEach((historyRec) => {
                const historyDate = new Date(historyRec.date);
                if (startDate <= historyDate && historyDate <= endDate) {
                    tempMap[historyRec.date] = Number(historyRec.price);
                }
            });
            let prevPrice = 0;
            result.forEach((rec) => {
                prevPrice = tempMap[rec.dateStr] ?? prevPrice;
                rec.price += prevPrice;
            });
        });
        return result;
    }



    function combineInvestmentData(stockData, mfData, bondData, alternativeData) {
        const dateMap = new Map();

        // Helper function to ensure each date is in the map
        function ensureDate(item) {
            if (!dateMap.has(item.dateStr)) {
                dateMap.set(item.dateStr, {
                    date: item.date,
                    dateStr: item.dateStr,
                    stock: 0,
                    stockMf: 0,
                    stockMfBond: 0,
                    total: 0
                });
            }
            return dateMap.get(item.dateStr);
        }

        // Process each type of investment
        stockData.forEach(item => {
            const entry = ensureDate(item);
            entry.stock += item.price;
            entry.stockMf += item.price;
            entry.stockMfBond += item.price;
            entry.total += item.price;
        });

        mfData.forEach(item => {
            const entry = ensureDate(item);
            entry.stockMf += item.price;
            entry.stockMfBond += item.price;
            entry.total += item.price;
        });

        bondData.forEach(item => {
            const entry = ensureDate(item);
            entry.stockMfBond += item.price;
            entry.total += item.price;
        });

        alternativeData.forEach(item => {
            const entry = ensureDate(item);
            entry.total += item.price;
        });

        // Convert map to array and sort by date
        const sortedData = Array.from(dateMap.values()).sort((a, b) => a.date - b.date);

        // Create separate lists for each cumulative investment type
        const stockList = sortedData.map(({ date, dateStr, stock }) => ({ date, dateStr, value: stock }));
        const stockMfList = sortedData.map(({ date, dateStr, stockMf }) => ({ date, dateStr, value: stockMf }));
        const stockMfBondList = sortedData.map(({ date, dateStr, stockMfBond }) => ({ date, dateStr, value: stockMfBond }));
        const totalList = sortedData.map(({ date, dateStr, total }) => ({ date, dateStr, value: total }));


        const stockListFinal = stockList.map(item => (item.value));
        const stockMfListFinal = stockMfList.map(item => item.value);
        const stockMfBondListFinal = stockMfBondList.map(item => item.value);
        const totalListFinal = totalList.map(item => item.value);

        return {
            stockList: stockListFinal,
            stockMfList: stockMfListFinal,
            stockMfBondList: stockMfBondListFinal,
            totalList: totalListFinal
        };
    }


  
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tabFromUrl = params.get('tab');
        
        if (tabFromUrl) {
            setActiveTab(tabFromUrl);
             const activeTabButton = document.querySelector(`#investmentTabs button[data-bs-target="#${tabFromUrl}"]`);
            if (activeTabButton) {
                setAddurl(activeTabButton.dataset.addurl);
            }
        }
    }, []); 
    
    useEffect(() => {
        const triggerTabList = document.querySelectorAll('#investmentTabs button[data-bs-toggle]');
        
        const handleTabChange = (event) => {
            const newTab = event.target.getAttribute('data-bs-target').substring(1) || 'stockdata';
            const newAddurl = event.target.getAttribute('data-addurl');
    
            setActiveTab(newTab);
            setAddurl(newAddurl);
    
            const params = new URLSearchParams(window.location.search);
            params.set('tab', newTab);
    
            window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        };
    
        triggerTabList.forEach(triggerEl => {
            triggerEl.addEventListener('show.bs.tab', handleTabChange);
        });
            return () => {
            triggerTabList.forEach(triggerEl => {
                triggerEl.removeEventListener('show.bs.tab', handleTabChange);
            });
        };
    }, []);
    
    // useEffect(() => {
    //     const triggerTabList = document.querySelectorAll('#investmentTabs button[data-bs-toggle]');
    //     triggerTabList.forEach(triggerEl => {
    //         triggerEl.addEventListener('show.bs.tab', function (event) {
    //             setAddurl(event.target.dataset.addurl);
    //         });

    //     });

    //     const myModal = document.getElementById('modalOne');
    //     myModal.addEventListener('hidden.bs.modal', () => {
    //         console.log('modal had been hidden');
    //     });
    // }, []);

    // Function to format numbers with commas
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            investmentName: investmentId.investmentName || "",
            currentPrice: investmentId.currentPrice || '',
            buyingPrice: investmentId.buyingPrice || '',
            purchaseDate: formatDate(investmentId.purchasedDate || '')
        },
        validationSchema: editAlternateInvestmentsSchema,
        onSubmit: values => {
            let payload = {
                investmentName: formik.values.investmentName,
                buyingPrice: Number(formik?.values?.buyingPrice),
                currentPrice: Number(formik.values.currentPrice),
                purchaseDate: formik?.values?.purchaseDate,
            }
            useEditAlternateInvestmentMutation.mutate(
                { payload: payload, investId: investmentId.investmentId },
                {
                    onSuccess: () => {
                        const modalElement = document.getElementById('modalEditAlternate');
                        const modal = bootstrap.Modal.getInstance(modalElement);
                        modal.hide();
                        refetchAlternateInvestment();

                    },
                    onError: (error) => {
                        console.error("Error updating investment:", error);
                    }
                }
            );
        }
    })


    const tabsContent = (tab) => {
        // console.log(tab)
    }

    // function abbreviateString(str) {
    //     return str
    //         .split(' ')
    //         .map(word => word[0])
    //         .join('');
    // }

    const onExportCSVFileStocks = () => {
        const fileName = "StocksCSV";
        const data = !!stockData?.data?.result?.listOfItems && stockData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                Holdings: item?.issuer?.name,
                Quantity: item?.quantity,
                ButPrice: item?.buyingPrice,
                LatestPrice: item?.lastTradedPrice,
                TotalPL: ` ${((item?.lastTradedPrice - item?.buyingPrice) * item?.quantity)?.toFixed(2)}`,
                LastDays: ` ${Math.abs(((item?.issuer?.nseHistory?.from?.tradePrice - item?.issuer?.nseHistory?.to?.tradePrice) * item?.quantity)?.toFixed(2))}`,
                Sector: item?.issuer?.sector,
                Category: item?.issuer?.category,
                SectorPerformance: '',
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileMutualFunds = () => {
        const fileName = "MutualFundsCSV";
        const data = !!mutualFundsData?.data?.result?.listOfItems && mutualFundsData?.data?.result?.listOfItems?.map((item, index) => {

            return {
                Name: item?.fund?.name,
                Category: item?.fund?.category,
                BuyPrice: item?.buyingPrice,
                NAV: item?.netAssetValue,
                CurrentValue: '',
                Change: '',
                Mode: item?.mode,
                TotalPL: item?.lastAmountDue,
                ExpertRatio: '',
                MorningStarRatings: item?.fund?.rating,
                TopPerformer: '',
                LowestExpenseRatonFund: ''
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileFixedIncome = () => {
        const fileName = "FixedIncomeCSV";
        const data = !!fixedIncomeData?.data?.result?.listOfItems && fixedIncomeData?.data?.result?.listOfItems?.map((item, index) => {

            return {
                InstrumentName: item?.instrumentName,
                Category: item?.category,
                Rating: item?.rating,
                InvestedAmount: item?.investedAmount,
                CouponRate: item?.couponRate,
                PaymentFreq: item?.paymentFreq,
                NextIntrestPaymentDate: format(new Date(item?.nextInterestPaymentDate), 'dd/MM/yyyy'),
                MaturityDate: format(new Date(item?.maturityDate), 'dd/MM/yyyy'),
                TaxableExempt: item?.taxableType
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileAlternate = () => {
        const fileName = "AlternateCSV";
        const data = !!alternatesData?.data?.result?.listOfItems && alternatesData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                Name: item?.investmentName,
                EntryPrice: item?.buyingPrice,
                LastTradedPrice: item?.currentPrice,
                ProfitLoss: ` ${(item?.buyingPrice - item?.currentPrice)?.toFixed(2)}`,
                // Fees: item?.currentPrice,
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSV = () => {
        if (addurl === '/stock-add') {
            onExportCSVFileStocks()
        } else if (addurl === '/mutual-fund-add') {
            onExportCSVFileMutualFunds()
        } else if (addurl === '/fixed-income-add') {
            onExportCSVFileFixedIncome()
        } else if (addurl === '/alternate-add') {
            onExportCSVFileAlternate()
        }
    }



    const formikLinkGoal = useFormik({
        initialValues: {
            sharePercentage: '',
            goalId: '',
        },
        validationSchema: addGoalSchema,
        onSubmit: (values) => {
            const payload = {
                investmentId: investmentId,
                sharePercentage: Number(values.sharePercentage),

            };

            useInvestmentLinkGoalMutation.mutate(
                { payload: payload, goalId: values.goalId },
                {
                    onSuccess: () => {
                        const modalElement = document.getElementById('modalOne');
                        const modal = bootstrap.Modal.getInstance(modalElement);

                        modal.hide();
                        formikLinkGoal.resetForm();

                        refetchGoals();
                        refetchMutualFund();
                        refetchFixedIncome();
                        refetchAlternateInvestment()
                    },
                    onError: (error) => {
                        console.error("Error linking goal:", error);
                    }
                }
            );
        }
    });
    // const onLinkGoal = () => {
    //     const payload = {
    //         investmentId: investmentId,
    //         sharePercentage: Number(sharePercentage),

    //     }

    //     useInvestmentLinkGoalMutation.mutate({ payload: payload, goalId: goalId })
    // }


    const editStockHandler = (item) => {
        navigate('/stock-edit', { state: { item, activeTab } })
    }
    const editMutualFundsHandler = (item) => {
        navigate('/mutual-fund-edit', { state: { item, activeTab } })
    }
    const editFixedIncomesHandler = (item) => {
        navigate('/fixed-income-edit', { state: { item, activeTab } })
    }

    const deleteStock = (item) => {
        alert(item.investmentId)
    }

    const deleteMutualFunds = (item) => {
        alert(item.investmentId)

    }
    const deleteFixedIncomes = (item) => {
        alert(item.investmentId)
    }
    const deleteAlternatesData = (item) => {
        alert(item.investmentId)
    }

    // useEffect(() => {
    //     if (stockData) {
    //       //  calculateStockSum();
    //     }
    // }, [stockData]);
    function filterHistoryByDateRange(history, fromDate, toDate) {
        // Convert fromDate and toDate to Date objects for comparison
        const from = new Date(fromDate);
        const to = new Date(toDate);

        // Iterate over each stock in the history object
        for (const stock in history) {
            // Filter the trade entries that fall within the date range
            history[stock] = history[stock].filter(entry => {
                const entryDate = new Date(entry.date);
                return entryDate >= from && entryDate <= to;
            });
        }

        return history;
    }


  
    const calculateStockSum = () => {

        const investments = stockData?.data?.result?.listOfItems;



        const issuerQuantities = investments.reduce((acc, investment) => {
            const issuerId = investment.issuer.id;

            if (acc[issuerId]) {
                acc[issuerId] += investment.quantity;
            } else {
                acc[issuerId] = investment.quantity;
            }

            var his = filterHistoryByDateRange(stockData?.data?.result?.stockHistoryData, fromDate, toDate)

            return acc;
        }, {});

        // If you want to get the result as an array of objects:
        const result = Object.keys(issuerQuantities).map(issuerId => ({
            issuerId,
            totalQuantity: issuerQuantities[issuerId]
        }));


    }
    const investId = linkedGoals.investmentId
 
    const handleEditGoal = (goal,) => {
        navigate('/goal-edit', { state: { goal, investId} })
    }


    function calculatePriceDifferenceMf(stockHistoryData, issuerId, fromDate, toDate) {
        if (!stockHistoryData || !issuerId) return null;

        const stockData = stockHistoryData[issuerId];
        if (!stockData || stockData.length === 0) return null;

    // Convert dates to Date objects for comparison
    const from = new Date(fromDate);
    const to = new Date(toDate);
       // Find the stock data entry closest to the fromDate
       const fromData = stockData.find(item => new Date(item.date).getTime() === from.getTime());
       if (!fromData) return null; // If no matching fromDate is found, return null
   
       // Find the stock data entry closest to the toDate
       const toData = stockData.find(item => new Date(item.date).getTime() === to.getTime());
       if (!toData) return null; // If no matching toDate is found, return null
   
    // Get the start price and end price
    const startPrice = parseFloat(fromData.nav);
    const endPrice = parseFloat(toData.nav);


    
        //  // Get the first entry for the start price
        // const startPrice = parseFloat(stockData[0].nav);

        // // Get the last entry for the end price
        // const endPrice = parseFloat(stockData[stockData.length - 1].nav);

        // Calculate the price difference
         
        const percentageChange = parseFloat( (((endPrice - startPrice) / startPrice) * 100  ) .toFixed(2));

  
        return percentageChange;
    }
    function calculatePriceDifference(stockHistoryData, issuerId, fromDate, toDate) {
        if (!stockHistoryData || !issuerId) return null;

        const stockData = stockHistoryData[issuerId];
        if (!stockData || stockData.length === 0) return null;

    // // Convert dates to Date objects for comparison
    // const from = new Date(fromDate);
    // const to = new Date(toDate);
    //    // Find the stock data entry closest to the fromDate
    //    const fromData = stockData.find(item => new Date(item.date).getTime() === from.getTime());
    //    if (!fromData) return null; // If no matching fromDate is found, return null
   
    //    // Find the stock data entry closest to the toDate
    //    const toData = stockData.find(item => new Date(item.date).getTime() === to.getTime());
    //    if (!toData) return null; // If no matching toDate is found, return null
    //    console.log('stockData',stockData,fromData,toData)

    // // Get the start price and end price
    // const startPrice = parseFloat(fromData.tradePrice);
    // const endPrice = parseFloat(toData.tradePrice);


        // Get the first entry for the start price
        const startPrice = parseFloat(stockData[0].tradePrice);

        // Get the last entry for the end price
        const endPrice = parseFloat(stockData[stockData.length - 1].tradePrice);

        // Calculate the price difference
        const priceDifference = parseFloat(endPrice - startPrice);
       
        const percentageChange = parseFloat (((priceDifference / startPrice) * 100).toFixed(2));

        // console.log( 'endPrice',endPrice, startPrice, percentageChange )
        return percentageChange;
    }
    // stock 
    const totalPL = stockData?.data?.result?.listOfItems?.reduce((acc, item) => {
        let currentPrice = item?.currentPrice;
        let plForItem = (currentPrice - item?.buyingPrice) * item?.quantity;
        return acc + (plForItem || 0);
    }, 0);

    const totalCurrentValue = stockData?.data?.result?.listOfItems?.reduce((acc, item) => {
        let currentPrice = item?.currentPrice;
        let currentTotalForItem = currentPrice * item?.quantity;
        return acc + (currentTotalForItem || 0);
    }, 0);

    let totalProfitLoss = 0;  // Initialize total profit/loss outside the map
    let totalCurrent = 0; // Initialize total current value outside the map

alternatesData?.data?.result?.listOfItems.forEach((item) => {
    const profitLoss = (item?.currentPrice || 0) - (item?.buyingPrice || 0);
    totalProfitLoss += profitLoss;
    const currentTotal = profitLoss; 
    totalCurrent += currentTotal; 
});
const { mutate: unlinkGoal, isLoading } = useDeleteLinkGoal();


    const handleAddClick = () => {
        navigate(addurl, { state: { activeTab } });
    };
    const handleDeleteGoal = (goalId) => {
        unlinkGoal(
          { investId, goalId }, // Pass the necessary data
          {
            onSuccess: () => {
              // Success logic
              const modalElement = document.getElementById('modalTwo');
              const modal = bootstrap.Modal.getInstance(modalElement);
              modal.hide(); // Close the modal after deletion
            
              // Refetch all necessary data
              refetchGoals();
              refetchMutualFund();
              refetchFixedIncome();
              refetchAlternateInvestment();
            },
            onError: (error) => {
              // Error handling logic
              console.error("Error unlinking goal:", error);
            }
          }
        );
      };
      
      

    return (
        <>
            <div className="row row-inner">
                <div className="col">
                    <h3 className="inter-700">Investment</h3>
                </div>
                <div className="col-auto">
                    <div className="d-flex gap-2 gap-xxl-3">
                        <button onClick={onExportCSV} type="button" className="btn btn-normal btn-light">
                            <FinIcon name="export" className="ts-1p4" />
                            Export
                        </button>
                        <button onClick={handleAddClick} className="btn btn-normal btn-primary">
                            <FinIcon name="addcirlce" className="ts-1p4" />
                            Add Investment
                        </button>
                    </div>
                </div>
                <div className="col-12 py-2">
                    <SMBADonutChart height="200" />
                </div>
            </div>


            <div className="row row-inner">
                <div className="col py-3">
                    <div className="row mx-0 bg-gray-50 p-3 rounded-3 border border-common">
                        <div className="col py-2">
                            <div className="d-flex gap-2 gap-lg-3">
                                <div className="block">
                                    <h4 className="inter-700">Total Investments</h4>
                                    <p className="inter-500 text-b100 mb-0">Graph & Chart</p>
                                </div>
                                <div className="vr bg-secondary"></div>
                                <div className="block align-self-center">
                                    <p className="inter-500 text-b100 mb-2">Graph & Chart</p>
                                    <select className="form-select inter-700" defaultValue="all">
                                        <option value="all">All</option>
                                        <option value="stocks">Stocks</option>
                                        <option value="mutual-funds">Mutual Funds</option>
                                        <option value="fixed-income">Fixed Income</option>
                                        <option value="alternate">Alternate</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="col-auto py-2">
                            <div className="input-group input-group-datepicker">
                                {/* <input type="text" className="form-control rounded-3" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                            <FinIcon name="calendar" /> */}

                                <div className="m-1">
                                    <label htmlFor="fromDate" className="form-label  ms-2">From</label>
                                    <input
                                        type="date"
                                        id="fromDate"
                                        className="form-control rounded-3"
                                        value={fromDate}
                                        onChange={(e) => { setfromDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }}
                                        placeholder="dd-mm-yyyy"
                                        aria-label="From date input"
                                    />
                                </div>

                                <div className="m-1">
                                    <label htmlFor="toDate" className="form-label  ms-2">To</label>
                                    <input
                                        type="date"
                                        id="toDate"
                                        className="form-control rounded-3"
                                        value={toDate}
                                        onChange={(e) => { settoDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }}
                                        placeholder="dd-mm-yyyy"
                                        aria-label="To date input"
                                    />
                                </div>


                                {/* <button className="btn bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                                <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                                <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                                <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                            </ul> */}
                            </div>
                        </div>
                        <div className="col-12">

                            <TotalInvestmentsChart height="380" seriesData={seriesData} fromDate={fromDate} toDate={toDate} gSecData10yrGraphData={gSecData10yr} stockGraphData={stockData} mutualFundsGraphData={mutualFundsData} bondsGraphData={fixedIncomeData} alternateGraphData={alternatesData} totalCurrent={totalCurrent} totalCurrentValue={totalCurrentValue} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row row-inner d-none ">

                <div className="col py-4">
                    <MyPortfolioChart height="380" fromDate={fromDate} toDate={toDate} />
                </div>
            </div>


            <div
                className="row row-inner ">
                <div className="col pt-1">
                    <h3 className="inter-700">Portfolio</h3>
                </div>
                {addurl === '/stock-add' && (<div className="col-auto mb-3">
                    <div className="input-group input-group-datepicker">
                        {/* <input type="date" className="form-control" value={fromDate} onChange={(e) => { setfromDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }} placeholder="dd mm yyyy" aria-label="Text input with dropdown button" />

                    <input type="date" className="form-control" value={toDate} onChange={(e) => { settoDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }} placeholder="dd mm yyyy" aria-label="Text input with dropdown button" />
                    <FinIcon name="calendar" /> */}
                        {/* <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                        <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                    </ul> */}
                    </div>
                </div>)}
                <div className="col-auto ml-20 ms-md-auto mb-3 d-flex flex-wrap d-gap gap-3">
                    <div>
                        <button onClick={handleAddClick} className="btn btn-normal btn-primary">
                            <FinIcon name="addcirlce" className="ts-1p4" />
                            Add Investment
                        </button>
                    </div>
                    <ul className="nav nav-pills nav-pills-common" id="investmentTabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'stockdata' ? 'active' : ''}`}
                                id="holdings-tab" data-bs-toggle="pill" data-bs-target="#stockdata" type="button" role="tab" aria-controls="stockdata" aria-selected={activeTab === 'stockdata'} onClick={() => tabsContent('holdings')} data-addurl="/stock-add">Stocks</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'mutualfund' ? 'active' : ''}`}
                                id="mutualfunds-tab" data-bs-toggle="pill" data-bs-target="#mutualfund" type="button" role="tab" aria-controls="mutualfundsBox" aria-selected={activeTab === 'mutualfund'} onClick={() => tabsContent('mutualfunds')} data-addurl="/mutual-fund-add">Mutual Funds</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'fixedincome' ? 'active' : ''}`}
                                id="fixedincome-tab" data-bs-toggle="pill" data-bs-target="#fixedincome" type="button" role="tab" aria-controls="fixedincome" aria-selected={activeTab === 'fixedincome'} onClick={() => tabsContent('fixedincome')} data-addurl="/fixed-income-add">Fixed Income</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'alternate' ? 'active' : ''}`}
                                id="alternate-tab" data-bs-toggle="pill" data-bs-target="#alternate" type="button" role="tab" aria-controls="alternate" aria-selected="false" onClick={() => tabsContent('alternate')} data-addurl="/alternate-add">Alternate</button>
                        </li>
                    </ul>



                </div>
                <div className="col-12 mb-4">
                    <div className="tab-content">
                        <div className={`tab-pane ${activeTab === 'stockdata' ? 'show active' : ''}`} id="stockdata" role="tabpanel" aria-labelledby="holdings-tab" tabIndex="0">
                            <div className="table-responsive">
                                <table className="table-common">
                                    <thead>
                                        <tr>
                                            <th>Holdings</th>
                                            <th>Quantity</th>
                                            <th>Buy <br />Price</th>
                                            <th>Current <br />Price</th>
                                            <th width="120">Total P&L</th>
                                            <th>Current Total</th> {/* New column for current total */}
                                            <th>
                                                % Change in {
                                                    (() => {
                                                        let timeDifference = new Date(toDate) - new Date(fromDate);
                                                        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                                                        return daysDifference;
                                                    })()
                                                } days
                                            </th>
                                            <th>Sector</th>
                                            <th>Category</th>
                                            <th width="100">Sector <br />Performance</th>
                                            <th>Linked Goal</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stockIsLoading || stockIsFetching ? (
                                            <tr key="loading">
                                                <td colSpan="12" className="text-center">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {stockData?.data?.result?.listOfItems && stockData?.data?.result?.listOfItems?.length > 0 ? (
                                                    stockData?.data?.result?.listOfItems?.map((item, index) => {
                                                        let currentPrice = item?.currentPrice;
                                                        const priceDifference = calculatePriceDifference(
                                                            stockData?.data?.result?.stockHistoryData,
                                                            item?.issuer?.id,
                                                            fromDate,
                                                            toDate
                                                        );

                                                        return (
                                                            <tr key={index.toString()}>
                                                                <td>{item?.issuer?.name}</td>
                                                                <td>{item?.quantity}</td>
                                                                <td>₹{formatNumberWithCommas(item?.buyingPrice)}</td>
                                                                <td>₹{formatNumberWithCommas(currentPrice)}</td>
                                                                <td>
                                                                    {((currentPrice - item?.buyingPrice) > 0) ? (
                                                                        <span className="text-success300">
                                                                            +₹{formatNumberWithCommas(((Math.abs(currentPrice - item?.buyingPrice)) * item?.quantity)?.toFixed(2))}
                                                                        </span>
                                                                    ) : (
                                                                        ((currentPrice - item?.buyingPrice) < 0) ? (
                                                                            <span className="text-danger">
                                                                                -₹{formatNumberWithCommas(((Math.abs(currentPrice - item?.buyingPrice)) * item?.quantity)?.toFixed(2))}
                                                                            </span>
                                                                        ) : (
                                                                            <span className="text-warning">
                                                                                ₹{formatNumberWithCommas(((Math.abs(currentPrice - item?.buyingPrice)) * item?.quantity)?.toFixed(2))}
                                                                            </span>
                                                                        )
                                                                    )}
                                                                </td>
                                                                {/* Current Total Calculation */}
                                                                <td>
                                                                    ₹{formatNumberWithCommas((currentPrice * item?.quantity).toFixed(2))} {/* Current total column */}
                                                                </td>
                                                                <td>{priceDifference}%</td>
                                                                <td>{item?.issuer?.sector}</td>
                                                                <td>{item?.issuer?.category}</td>
                                                                <td>{item?.sectorPerformance}</td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => {
                                                                                setInvestmentId(item?.investmentId);
                                                                                formikLinkGoal.resetForm();
                                                                            }}
                                                                            className="btn btn-primary d-gap d-flex align-items-center gap-1"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#modalOne"
                                                                        >
                                                                            <GoGoal />
                                                                        </button>
                                                                        <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                            <FaEye />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button type="button" onClick={() => editStockHandler(item)} className="btn btn-primary">
                                                                            <FaRegEdit />
                                                                        </button>
                                                                        <button type="button" className="btn btn-primary d-gap d-flex align-items-center gap-1" onClick={() => { deleteStock(item) }}>
                                                                            <RiDeleteBin6Fill />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr key="no-data">
                                                        <td colSpan="12" className="text-center">No data found</td>
                                                    </tr>
                                                )}

                                                {/* Total Row */}
                                                <tr key="total">
                                                    <td colSpan="4"><strong>Total</strong></td>
                                                    <td>
                                                        <strong>
                                                            ₹{formatNumberWithCommas((totalPL || 0).toFixed(2))} {/* Total P&L */}
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            ₹{formatNumberWithCommas((totalCurrentValue || 0).toFixed(2))} {/* Total Current Value */}
                                                        </strong>
                                                    </td>
                                                    <td colSpan="5"></td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'mutualfund' ? 'show active' : ''}`} id="mutualfund" role="tabpanel" aria-labelledby="mutualfunds-tab" tabIndex="0">
                            <div className="table-responsive">
                                <table className="table-common">
                                    <thead>
                                        <tr>
                                            <th>Mutual Fund Scheme </th>
                                            <th>Category</th>
                                            <th>Buy <br />Price</th>
                                            <th>NAV</th>
                                            <th>Quantity</th>
                                            <th>Expense <br />Ratio</th>
                                            <th>
                                                % Change in {
                                                    (() => {
                                                        let timeDifference = new Date(toDate) - new Date(fromDate);
                                                        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                                                        return daysDifference;
                                                    })()
                                                } days
                                            </th>
                                            <th>Mode</th>
                                            <th>Total <br /> P&L</th>
                                            <th>Current Total</th> {/* New column for Current Total */}
                                            <th>MorningStar <br />Ratings</th>
                                            <th>Top <br />Performer (3yr)</th>
                                            <th width="200">Lowest Expense<br />Ratio Fund</th>
                                            <th>Linked Goal</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mutualFundsIsLoading || mutualFundsIsFetching ? (
                                            <tr key="loading">
                                                <td colSpan="14" className="text-center">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {mutualFundsData?.data?.result?.listOfItems && mutualFundsData?.data?.result?.listOfItems.length > 0 ? (
                                                    mutualFundsData?.data?.result?.listOfItems.map((item, index) => {
                                                        let currentPrice = item?.currentPrice;
                                                        const priceDifference = calculatePriceDifferenceMf(
                                                            mutualFundsData?.data?.result?.mutualFundHistoryData,
                                                            item?.fund?.id,
                                                            fromDate,
                                                            toDate
                                                        );

                                                        const profitLoss = ((currentPrice - item?.buyingPrice) * item?.quantity).toFixed(2);
                                                        const currentTotal = (currentPrice * item?.quantity).toFixed(2); // New calculation for Current Total

                                                        return (
                                                            <tr key={index.toString()}>
                                                                <td>{item?.fund?.name}</td>
                                                                <td>{item?.fund?.category}</td>
                                                                <td>₹{formatNumberWithCommas(item?.buyingPrice)}</td>
                                                                <td>₹{formatNumberWithCommas(item?.currentPrice)}</td>
                                                                <td>{formatNumberWithCommas(item?.quantity)}</td>
                                                                <td>N/A</td>
                                                                <td>{priceDifference}%</td>
                                                                <td>{item?.mode}</td>
                                                                <td>
                                                                    {profitLoss > 0 ? (
                                                                        <span className="text-success300">
                                                                            +₹{formatNumberWithCommas(profitLoss)}
                                                                        </span>
                                                                    ) : profitLoss < 0 ? (
                                                                        <span className="text-danger">
                                                                            -₹{formatNumberWithCommas(Math.abs(profitLoss))}
                                                                        </span>
                                                                    ) : (
                                                                        <span className="text-warning">
                                                                            ₹{formatNumberWithCommas(profitLoss)}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>₹{formatNumberWithCommas(currentTotal)}</td> {/* Display current total */}
                                                                <td>N/A</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <FinIcon name="starfill" className="text-warning300 ts-1p2" />
                                                                        {item?.fund?.rating}
                                                                    </div>
                                                                </td>
                                                                <td>N/A</td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button type="button" onClick={() => {
                                                                            setInvestmentId(item?.investmentId);
                                                                            formikLinkGoal.resetForm();
                                                                        }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                            <GoGoal />
                                                                        </button>
                                                                        <button type="button" onClick={() => setLinkedGoals(item)} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                            <FaEye />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button type="button" onClick={() => editMutualFundsHandler(item)} className="btn btn-primary">
                                                                            <FaRegEdit />
                                                                        </button>
                                                                        <button type="button" className="btn btn-primary d-gap d-flex align-items-center gap-1" onClick={() => deleteMutualFunds(item)}>
                                                                            <RiDeleteBin6Fill />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr key="no-data">
                                                        <td colSpan="14" className="text-center">No data found</td>
                                                    </tr>
                                                )}
                                            </>
                                        )}

                                        {/* Footer row to display total P&L and Total Current Value */}
                                        {!mutualFundsIsLoading && mutualFundsData?.data?.result?.listOfItems?.length > 0 && (
                                            <tr>
                                                <td colSpan="8" className="text-start fw-bold">Total</td>
                                                <td className="fw-bold">
                                                    ₹{formatNumberWithCommas(mutualFundsData?.data?.result?.listOfItems.reduce((total, item) => {
                                                        let currentPrice = item?.currentPrice;
                                                        return total + (currentPrice - item?.buyingPrice) * item?.quantity;
                                                    }, 0).toFixed(2))}
                                                </td>
                                                <td className="fw-bold"> {/* Display total current value */}
                                                    ₹{formatNumberWithCommas(mutualFundsData?.data?.result?.listOfItems.reduce((total, item) => {
                                                        return total + item?.currentPrice * item?.quantity;
                                                    }, 0).toFixed(2))}
                                                </td>
                                                <td colSpan="5"></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>


                            </div>
                        </div>


                        <div className={`tab-pane ${activeTab === 'fixedincome' ? 'show active' : ''}`} id="fixedincome" role="tabpanel" aria-labelledby="fixedincome-tab" tabIndex="0">
                            <div className="table-responsive">
                                <table className="table-common">
                                    <thead>
                                        <tr>
                                            <th>Instrument Name</th>
                                            <th>Category</th>
                                            <th>Rating</th>
                                            <th>Invested <br />Amount</th>
                                            <th>Coupon <br />Rate %</th>
                                            <th>Payment Freq</th>
                                            <th width="120">Next Intrest <br />Payment Date</th>
                                            <th width="120">Maturity Date</th>
                                            <th width="135">Taxable/Exempt</th>
                                            <th>Linked Goal</th>

                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {fixedIncomeIsLoading || fixedIncomeIsFetching ?
                                            <tr key="loading">
                                                <td colSpan="11" className="text-center">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            : <>
                                                {
                                                    fixedIncomeData?.data?.result?.listOfItems && fixedIncomeData?.data?.result?.listOfItems?.length > 0 ? (
                                                        !!fixedIncomeData?.data?.result?.listOfItems && fixedIncomeData?.data?.result?.listOfItems?.map((item, index) => {
                                                            return (
                                                                <tr key={index?.toString()}>
                                                                    <td>
                                                                        <abbr title={item?.instrumentName}>

                                                                            {(item?.instrumentName)}
                                                                        </abbr>
                                                                    </td>
                                                                    <td>{item?.category}</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center gap-1">
                                                                            <FinIcon name="starfill" className="text-warning300 ts-1p2" />
                                                                            {item?.rating}
                                                                        </div>
                                                                    </td>
                                                                    <td>₹{formatNumberWithCommas(item?.investedAmount)}</td>
                                                                    <td>{item?.couponRate}%</td>
                                                                    <td>{item?.paymentFreq}</td>
                                                                    <td>{format(new Date(item?.nextInterestPaymentDate), 'dd MMM yyyy')}</td>
                                                                    <td>{format(new Date(item?.maturityDate), 'dd MMM yyyy')}</td>
                                                                    <td>{item?.taxableType}</td>

                                                                    <td>
                                                                        <div className="d-flex d-gap gap-2">
                                                                            <button type="button" onClick={() => {
                                                                                setInvestmentId(item?.investmentId);
                                                                                formikLinkGoal.resetForm();
                                                                            }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                                <GoGoal />

                                                                            </button>
                                                                            <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                                <FaEye />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <td>
                                                                            <div className="d-flex d-gap gap-2">
                                                                                <button type="button" onClick={() => editFixedIncomesHandler(item)}
                                                                                    className="btn btn-primary d-flex">
                                                                                    <FaRegEdit />
                                                                                </button>
                                                                                <button type="button" className="btn btn-primary d-gap d-flex align-items-center gap-1" onClick={() => { deleteFixedIncomes(item) }}>
                                                                                    <RiDeleteBin6Fill />
                                                                                </button>
                                                                            </div>


                                                                        </td>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })) : (

                                                        <tr key="no-data">
                                                            <td colSpan="11" className="text-center">
                                                                No data found
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'alternate' ? 'show active' : ''}`} id="alternate" role="tabpanel" aria-labelledby="alternate-tab" tabIndex="0">
                            <div className="table-responsive">


                                <table className="table-common">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Entry <br />Price</th>
                                            <th>Current Price</th>
                                            <th>Profit & <br />Loss</th>
                                            <th>Current <br />Total</th> {/* Current Total as Current Price - Buying Price */}
                                            <th>Fees</th>
                                            <th>Linked Goal</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {alternateIsLoading || alternateIsFetching ? (
                                            <tr key="loading">
                                                <td colSpan="8" className="text-center">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {alternatesData?.data?.result?.listOfItems && alternatesData?.data?.result?.listOfItems.length > 0 ? (

                                                    alternatesData.data.result.listOfItems.map((item, index) => {
                                                        const profitLoss = (item?.currentPrice || 0) - (item?.buyingPrice || 0);
                                                        // totalProfitLoss += profitLoss; // Adding to total Profit & Loss

                                                        const currentTotal = (item?.currentPrice || 0) - (item?.buyingPrice || 0);
                                                        // totalCurrent += currentTotal; // Sum up current totals

                                                        return (
                                                            <tr key={index.toString()}>
                                                                <td>{item?.investmentName}</td>
                                                                <td>₹{formatNumberWithCommas(item?.buyingPrice || 0)}</td>
                                                                <td>₹{formatNumberWithCommas(item?.currentPrice || 0)}</td>
                                                                <td>
                                                                    <span className={profitLoss >= 0 ? "text-success300" : "text-danger"}>
                                                                        ₹{formatNumberWithCommas(profitLoss.toFixed(2))}
                                                                    </span>
                                                                </td>
                                                                <td>₹{formatNumberWithCommas(currentTotal.toFixed(2))}</td> {/* Show current total */}
                                                                <td>₹{formatNumberWithCommas(item?.fees || 0)}</td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button type="button" onClick={() => {
                                                                            setInvestmentId(item?.investmentId);
                                                                            formikLinkGoal.resetForm();
                                                                        }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                            <GoGoal />
                                                                        </button>
                                                                        <button type="button" onClick={() => setLinkedGoals(item)} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                            <FaEye />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex d-gap gap-2">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => {
                                                                                setInvestmentId(item);
                                                                            }}
                                                                            className="btn btn-primary"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#modalEditAlternate"
                                                                        >
                                                                            <FaRegEdit />
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={() => deleteAlternatesData(item)}
                                                                        >
                                                                            <RiDeleteBin6Fill />
                                                                        </button>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr key="no-data">
                                                        <td colSpan="8" className="text-center">
                                                            No data found
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3" className="text-start"><strong>Total</strong></td>
                                            <td>₹{formatNumberWithCommas((totalProfitLoss || 0).toFixed(2))}</td> {/* Total Profit & Loss */}
                                            <td>₹{formatNumberWithCommas((totalCurrent || 0).toFixed(2))}</td> {/* Total Current Value (Sum of c - b) */}
                                            <td colSpan="3"></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalOne" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Link Goal</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* Share Percentage Input */}
                            <div className="col">
                                <label htmlFor="sharePercentage" className="small inter-500 text-b200 mb-2">Share Percentage</label>
                                <input
                                    type="number"
                                    className={`form-control form-control-fin inter-500 bg-white px-3 shadow-sm ${formik.errors.sharePercentage && formik.touched.sharePercentage ? 'is-invalid' : ''}`}
                                    placeholder="Share Percentage"
                                    min="1"
                                    max="100"
                                    id="sharePercentage"
                                    name="sharePercentage"
                                    value={formikLinkGoal.values.sharePercentage}
                                    onChange={formikLinkGoal.handleChange}
                                    onBlur={formikLinkGoal.handleBlur}
                                />
                                {formikLinkGoal.errors.sharePercentage && formikLinkGoal.touched.sharePercentage && (
                                    <div className="text-danger mt-1">
                                        {formikLinkGoal.errors.sharePercentage}
                                    </div>
                                )}
                            </div>

                            {/* Goal Selection Dropdown */}
                            <div className="col mt-3">
                                <label htmlFor="linkgoal" className="small inter-500 text-b200 mb-2">Select Goal</label>
                                <select
                                    className={`form-select form-control-fin inter-500 bg-white px-3 shadow-sm ${formikLinkGoal.errors.goalId && formikLinkGoal.touched.goalId ? 'is-invalid' : ''}`}
                                    id="linkgoal"
                                    name="goalId"
                                    value={formikLinkGoal.values.goalId}
                                    onChange={formikLinkGoal.handleChange}
                                    onBlur={formikLinkGoal.handleBlur}
                                >
                                    <option value="">-- Select Goal --</option>
                                    {planningGoalsData?.data?.result?.map((item, index) => (
                                        <option key={index} value={item.goalId}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                {formikLinkGoal.errors.goalId && formikLinkGoal.touched.goalId && (
                                    <div className="text-danger mt-1">
                                        {formikLinkGoal.errors.goalId}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={formikLinkGoal.handleSubmit}
                                disabled={!formikLinkGoal.isValid || formikLinkGoal.isSubmitting}
                            >
                                Link Goal
                                {useInvestmentLinkGoalMutation?.isPending && (
                                    <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="modalTwo" tabIndex="-1" aria-labelledby="modalTwoLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modalTwoLabel">Link and Manage Goals</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {linkedGoals?.linkedGoals?.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Goal Name</th>
                                            <th>Goal Percent</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linkedGoals.linkedGoals.map((goal, index) => (
                                            <tr key={goal.goalId}>
                                                <td>{index + 1}</td>
                                                <td>{goal.name}</td>
                                                <td> {goal.percentage}%</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                    onClick={() => handleDeleteGoal(goal.goalId)}
                                                    >
                                                        <RiDeleteBin6Fill />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm ms-2"
                                                        data-bs-dismiss="modal"
                                                        onClick={() => handleEditGoal(goal)}
                                                    >
                                                        <FaRegEdit />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No linked goals available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>




            <div className="modal fade" id="modalEditAlternate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Update Alternate</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col">
                                <label htmlFor="investmentName" className="small inter-500 text-b200 mb-2">Investment Name</label>
                                <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Investment Name"
                                    min="0"
                                    id="investmentName"
                                    name="investmentName"
                                    value={formik?.values?.investmentName}
                                    onBlur={formik.handleBlur('investmentName')}
                                    onInput={formik.handleChange('investmentName')}
                                />
                                {(formik.errors.investmentName && formik.touched.investmentName) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.investmentName}
                                </p> : null}
                            </div>

                            <div className="col">
                                <label htmlFor="buyprice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Buy Price"
                                    min="0"
                                    id="buyingprice"
                                    name="buyingprice"
                                    value={formik?.values?.buyingPrice}
                                    onBlur={formik.handleBlur('buyingPrice')}
                                    onInput={formik.handleChange('buyingPrice')}
                                />
                                {(formik.errors.buyingPrice && formik.touched.buyingPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.buyingPrice}
                                </p> : null}
                            </div>

                            <div className="col">
                                <label htmlFor="currentprice" className="small inter-500 text-b200 mb-2">Current Price</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Current Price"
                                    min="0"
                                    id="currentprice"
                                    name="currentprice"
                                    value={formik?.values?.currentPrice}
                                    onBlur={formik.handleBlur('currentPrice')}
                                    onInput={formik.handleChange('currentPrice')}
                                />
                                {(formik.errors.currentPrice && formik.touched.currentPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.currentPrice}
                                </p> : null}
                            </div>

                            <div className="col">
                                <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">purchase Date</label>
                                <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                    min="0"
                                    id="purchaseDate"
                                    name="purchaseDate"
                                    value={formik?.values?.purchaseDate}
                                    onBlur={formik.handleBlur('purchaseDate')}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        const dateParts = value.split('-');
                                        if (dateParts[0] && dateParts[0].length > 4) {
                                            e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                        }

                                        formik.handleChange(e);
                                    }} />
                                {(formik.errors.purchaseDate && formik.touched.purchaseDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.purchaseDate}
                                </p> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit}>
                                Edit
                                {useEditAlternateInvestmentMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" ></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default Investment;

