import { useMutation } from '@tanstack/react-query';


import UserMeService from '../../services/user-service';
import { errorToast, successToast } from '../../utils/toastMassage';

function useUserMe() {

    

    return useMutation({
        mutationFn: UserMeService.putUser,
        onSuccess: () => {
            successToast('User Update SuccessFully');
           
        },
        onError: (error) => {

            errorToast('Something went wrong, please try again later')
        },
    });

}

export default useUserMe;