import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import investmentServiceInstance from '../../services/investment-service';
import { errorToast, successToast } from '../../utils/toastMassage';

function useEditInvestment() {

  const navigate = useNavigate();

  return useMutation({
    mutationFn: investmentServiceInstance.editInvestment,
    onSuccess: (data) => {
      successToast('Modified Successfully');
      // setTimeout(() => {
      //   // window.localStorage.setItem('isLogedIn', true);
      //   navigate('/investment');
      // }, 1000);
    },
    onError: (error) => {
      console.log(error,'modified errr');
      errorToast('Something went wrong, please try again later')
    },
  });

}

export default useEditInvestment;