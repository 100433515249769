import { keepPreviousData, useQuery } from '@tanstack/react-query';

import investmentServiceInstance from '../../services/investment-service';


function useGetInvestmentStock(data) {

  return useQuery({
    queryKey: [investmentServiceInstance.queryKeys.investmentStock + data?.from + data?.to],
    queryFn: () => investmentServiceInstance.investmentStock(data),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetInvestmentStock;