import { keepPreviousData, useQuery } from '@tanstack/react-query';

import LoansServiceInstance from '../../services/loan-service';

function useGetLoans() {
  return useQuery({
    queryKey: [LoansServiceInstance.queryKeys.getLoans],
    queryFn: LoansServiceInstance.getLoans,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetLoans;