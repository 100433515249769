import React from "react";
// import { Link } from "react-router-dom";
import {FinIcon} from './Common';

const PeronalInformation = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Security Settings</h3>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-normal btn-light" onClick={()=> props.passingMethod({account_settings: true})}>
                    <FinIcon name="arrowleft2" className="ts-1p4"/> Back
                </button>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    Manage your email, password, etc.
                </p>
            </div>
            {/* ... */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Email</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Password</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Mobile phone</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Multi-factor authentication</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Mobile app verification</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="small inter-500 text-b200">Foreign login alert</div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3"/>
                    Save Changes
                </button>
            </div>
        </div>
    )
}
export default PeronalInformation;