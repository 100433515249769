import { keepPreviousData, useQuery } from '@tanstack/react-query';

import debtServiceInstance from '../../services/debt-service';


function useGetLoanJourney(data) {

  return useQuery({
    queryKey: [debtServiceInstance.queryKeys.getLoanJourney + data],
    queryFn: () => debtServiceInstance.getLoanJourney(data),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetLoanJourney;