import { keepPreviousData, useQuery } from '@tanstack/react-query';

import authServiceInstance from '../../services/auth-service';


function useGetUserProfile(data) {
  return useQuery({
    queryKey: [authServiceInstance.queryKeys.getUserProfile ],
    queryFn: authServiceInstance.getUserProfile,
      refetchOnWindowFocus: false
    
    // enabled: !!data,
  });
}

export default useGetUserProfile;