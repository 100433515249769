import { keepPreviousData, useQuery } from '@tanstack/react-query';

import bankServiceInstance from '../../services/bank-service';

function useGetBankAggregates() {
  return useQuery({
    queryKey: [bankServiceInstance.queryKeys.getBankAggregates],
    queryFn: bankServiceInstance.getBankAggregates,
    refetchOnWindowFocus: false
    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetBankAggregates;