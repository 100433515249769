import { keepPreviousData, useQuery } from '@tanstack/react-query';

import bankServiceInstance from '../../services/bank-service';

function useGetBankTransactionCategories(data) {
  return useQuery({
    queryKey: [bankServiceInstance.queryKeys.bankTransactionCategories ],
    queryFn: bankServiceInstance.bankTransactionCategories,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetBankTransactionCategories;