import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import usePostDebt from "../hooks/debt/post-debt";
import { FinIcon } from '../components/Common';
import useGetLenders from "../hooks/debt/get-lenders";
import { addDebtSchema } from "../utils/validationSchema";
import { useNavigate } from "react-router-dom";
const DebtAdd = () => {
    // API calls
    const { data: getLendersData, isLoading: getLendersIsLoading } = useGetLenders();
    const usePostDebtMutation = usePostDebt();
    const navigate = useNavigate()
    // Functon to calculate EMI interest rate
    const calculateInterestRate = (principal, emi, months) => {
        const tolerance = 0.01; // Tolerance level for convergence
        let lowerBound = 0.01; // Start from 0.01% (0.0001 monthly interest rate)
        let upperBound = 0.3; // 30% annual interest rate
        let rate = (lowerBound + upperBound) / 2;

        while (upperBound - lowerBound > tolerance) {
            let calculatedEMI = calculateEMI(principal, rate, months);
            if (calculatedEMI > emi) {
                upperBound = rate;
            } else {
                lowerBound = rate;
            }
            rate = (lowerBound + upperBound) / 2;
        }

        // Convert monthly rate to annual percentage rate (APR)
        return rate * 12 * 100;
    };

    const calculateEMI = (principal, rate, months) => {
        if (rate === 0) return principal / months; // Special case for zero interest rate
        rate = rate / 100; // Convert percentage to decimal
        return (principal * rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
    };

    // Formik setup
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            loanType: "",
            lender: "",
            amount: "",
            emi: "",
            interestRate: "",
            loanStartDate: "",
            dueDate: "",
            principalOutstanding: "",
            tenureInMonths: "",
        },
        validationSchema: addDebtSchema,
        onSubmit: values => {
            console.log(values)
            const payload = formik.values.lender === 'Other' ? {
                loanType: formik.values.loanType,
                otherLender: otherLendervalue,
                amount: formik.values.amount,
                emi: formik.values.emi,
                interestRate: Number(formik.values.interestRate),
                loanStartDate: formik.values.loanStartDate,
                dueDate: formik.values.dueDate,
                principalOutstanding: formik.values.principalOutstanding,
                tenureInMonths: formik.values.tenureInMonths,
            } : {
                loanType: formik.values.loanType,
                lenderId: Number(formik.values.lender),
                amount: formik.values.amount,
                emi: formik.values.emi,
                interestRate: Number(formik.values.interestRate),
                loanStartDate: formik.values.loanStartDate,
                dueDate: formik.values.dueDate,
                principalOutstanding: formik.values.principalOutstanding,
                tenureInMonths: formik.values.tenureInMonths,
            };
            usePostDebtMutation.mutate(payload, {
                onSuccess: () => {
                    formik.resetForm();
                    setOtherLendervalue('');
                    navigate('/debt')
                },
            });
        },
    });

    const [other, setOther] = useState(false);
    const [otherLendervalue, setOtherLendervalue] = useState('');

    const otherChange = (vl) => {
        if (vl === 'Other') {
            setOther(true);
        } else {
            setOther(false);
        }
    };

    // useEffect(() => {
    //     const { amount, emi, tenureInMonths } = formik.values;

    //     if (amount && emi && tenureInMonths) {
    //         const calculatedInterestRate = calculateInterestRate(
    //             parseFloat(amount),
    //             parseFloat(emi),
    //             parseFloat(tenureInMonths)
    //         );

    //         if (calculatedInterestRate < 1 || calculatedInterestRate > 30) {
    //             formik.setFieldError('interestRate', 'This is not a normal interest rate. Please check your data.');
    //             formik.setFieldValue('interestRate', '');
    //         } else {
    //             formik.setFieldValue('interestRate', calculatedInterestRate.toFixed(2)); // Set formatted interest rate
    //         }
    //     }
    // }, [formik.values.amount, formik.values.emi, formik.values.tenureInMonths]);

    useEffect(() => {
        const calculateEMI = (principal, rate, tenure) => {
            return (principal * rate * Math.pow(1 + rate, tenure)) / (Math.pow(1 + rate, tenure) - 1);
        };

        const calculateInterestRate = () => {
            let principal = formik.values.amount;
            let emi = formik.values.emi;
            let tenure = formik.values.tenureInMonths;

            if (principal > 0 && emi > 0 && tenure > 0) {
                let low = 0.0001;
                let high = 1;
                let epsilon = 0.000001;

                while ((high - low) > epsilon) {
                    let mid = (low + high) / 2;
                    let emiCalculated = calculateEMI(principal, mid, tenure);

                    if (emiCalculated > emi) {
                        high = mid;
                    } else {
                        low = mid;
                    }
                }

                let monthlyRate = (low + high) / 2;
                let annualRate = monthlyRate * 12 * 100;
                return annualRate.toFixed(2);
            }
            return 0;
        };

        const interestRate = calculateInterestRate();
        console.log()
        formik.setFieldValue('interestRate', interestRate);



    }, [formik.values.amount, formik.values.emi, formik.values.tenureInMonths]);



    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Add New Debt</h3>
                    <p className="text-b200 small">Add New Debt basic details.</p>
                </div>
                <div className="col-auto">
                    <Link to={'/debt'} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                    </Link>
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                            <div className="col">
                                <label htmlFor="loanType" className="small inter-500 text-b200 mb-2">Loan Type</label>
                                <select className="form-select form-control-fin inter-500 bg-white shadow-sm"
                                    id="loanType"
                                    name="loanType"
                                    onChange={formik.handleChange}
                                    value={formik.values.loanType}
                                    onBlur={formik.handleBlur('loanType')}
                                >
                                    <option value={''}>--Select--</option>
                                    <option value="Home Loan">Home Loan</option>
                                    <option value="Personal Loan">Personal Loan</option>
                                    <option value="Vehicle Loan">Vehicle Loan</option>
                                    <option value="Education Loan">Education Loan</option>
                                    <option value="Business Loan">Business Loan</option>
                                </select>
                                {(formik.errors.loanType && formik.touched.loanType) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.loanType}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="lender" className="small inter-500 text-b200 mb-2">Lender</label>
                                <select className="form-select form-control-fin inter-500 bg-white shadow-sm"
                                    disabled={getLendersIsLoading}
                                    id="lender"
                                    name="lender"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        otherChange(e.target.value);
                                    }}
                                    value={formik.values.lender}
                                    onBlur={formik.handleBlur('lender')}
                                >
                                    <option value={''}>--Select--</option>
                                    {!!getLendersData?.data?.result && getLendersData?.data?.result?.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                    <option value="Other">Other</option>
                                </select>
                                {(formik.errors.lender && formik.touched.lender) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.lender}
                                </p> : null}
                            </div>
                            {
                                formik.values.lender === 'Other' && <div className="col">
                                    <label htmlFor="otherLender" className="small inter-500 text-b200 mb-2">Other Lender</label>
                                    <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                        placeholder="Enter Other Lender Name"
                                        id="otherLender"
                                        name="otherLender"
                                        value={otherLendervalue}
                                        onChange={(e) => { setOtherLendervalue(e.target.value); }}
                                    />
                                </div>
                            }
                            <div className="col">
                                <label htmlFor="amount" className="small inter-500 text-b200 mb-2">Amount (₹)</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                    min="0"
                                    id="amount"
                                    name="amount"
                                    onInput={formik.handleChange}
                                    value={formik.values.amount}
                                    onBlur={formik.handleBlur('amount')}
                                />
                                {(formik.errors.amount && formik.touched.amount) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.amount}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="emi" className="small inter-500 text-b200 mb-2">EMI (₹)</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                    min="0"
                                    id="emi"
                                    name="emi"
                                    onInput={formik.handleChange}
                                    value={formik.values.emi}
                                    onBlur={formik.handleBlur('emi')}
                                />
                                {(formik.errors.emi && formik.touched.emi) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.emi}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="tenureInMonths" className="small inter-500 text-b200 mb-2">Tenure (Months)</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                    min="0"
                                    id="tenureInMonths"
                                    name="tenureInMonths"
                                    onInput={formik.handleChange}
                                    value={formik.values.tenureInMonths}
                                    onBlur={formik.handleBlur('tenureInMonths')}
                                />
                                {(formik.errors.tenureInMonths && formik.touched.tenureInMonths) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.tenureInMonths}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="interestRate" className="small inter-500 text-b200 mb-2">Interest Rate<span>({formik.values.interestRate}%)</span></label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                    min="0"
                                    max="100"
                                    id="interestRate"
                                    name="interestRate"
                                    onChange={formik.handleChange}
                                    value={formik.values.interestRate}
                                    onBlur={formik.handleBlur('interestRate')}
                                    disabled
                                />
                                {formik.errors.interestRate && formik.touched.interestRate && <p className="fs-14px text-danger inter-500 my-1">{formik.errors.interestRate}</p>}

                            </div>
                            <div className="col">
                                <label htmlFor="loanStartDate" className="small inter-500 text-b200 mb-2">Loan Start Date</label>
                                <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="loanStartDate"
                                    name="loanStartDate"
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        const dateParts = value.split('-');
                                        if (dateParts[0] && dateParts[0].length > 4) {
                                            e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                        }

                                        formik.handleChange(e);
                                    }} value={formik.values.loanStartDate}
                                    onBlur={formik.handleBlur('loanStartDate')}
                                />
                                {(formik.errors.loanStartDate && formik.touched.loanStartDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.loanStartDate}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="dueDate" className="small inter-500 text-b200 mb-2">Due Date</label>
                                <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="dueDate"
                                    name="dueDate"
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        const dateParts = value.split('-');
                                        if (dateParts[0] && dateParts[0].length > 4) {
                                            e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                        }

                                        formik.handleChange(e);
                                    }} value={formik.values.dueDate}
                                    onBlur={formik.handleBlur('dueDate')}
                                />
                                {(formik.errors.dueDate && formik.touched.dueDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.dueDate}
                                </p> : null}
                            </div>
                            <div className="col">
                                <label htmlFor="principalOutstanding" className="small inter-500 text-b200 mb-2">Principal Outstanding (₹)</label>
                                <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                    min="0"
                                    id="principalOutstanding"
                                    name="principalOutstanding"
                                    onInput={formik.handleChange}
                                    value={formik.values.principalOutstanding}
                                    onBlur={formik.handleBlur('principalOutstanding')}
                                />
                                {(formik.errors.principalOutstanding && formik.touched.principalOutstanding) ? <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.principalOutstanding}
                                </p> : null}
                            </div>

                        </div>

                        <div className="text-start mt-4">
                            <button type="submit" className="btn btn-primary btn-submit" onClick={formik.handleSubmit}>
                                Add Debt
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DebtAdd;
