import React from "react";
import { FinIcon } from '../components/Common';
import { useFormik } from "formik";
import * as yup from "yup";
import useEditInvestment from "../hooks/investment/edit-investment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MutualFundsEdit = () => {
    const location = useLocation();
    const item = location.state?.item;


    const navigate = useNavigate();
    const activeTab = location.state.activeTab


    const { mutate } = useEditInvestment();


    const handleBackToInveshment = () => {
        navigate({
            pathname: '/investment',
            search: `?tab=${activeTab}`,
        });

        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });
        }, 100);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };



    // Validation Schema
    const editMutualFundsSchema = yup.object().shape({
        mode: yup.string().required("Mode is required"),
        buyingPrice: yup.number().typeError('Buying Price must be a number').required("Buying Price is required"),
        purchaseDate: yup.string()
        .required('Please enter Purchase Date.')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in DD-MM-YYYY format') 
        .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
          if (!value) return false; 
    
          const year = parseInt(value.split('-')[0], 10); 
          return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
        }),
        name: yup.string().required("Fund Name is required"),
        quantity: yup.number().typeError('Quantity must be a number').required("Quantity is required"),
    });

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: !!item,
        initialValues: {
            investmentId: item?.investmentId,
            mode: item?.mode || '',
            investmentType: item?.investmentType || 'MUTUAL_FUNDS',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: formatDate(item?.purchasedDate) || '',
            fundCategory: item?.fund?.category || '',
            name: item?.fund?.name || '',
            quantity: item?.quantity || '',
            linkedGoals: item?.linkedGoals || [],
        },
        validationSchema: editMutualFundsSchema,
        onSubmit: (values) => {
            console.log("Form values before submitting:", values.name);


            try {
                const payload = {
                    mutualFundId: item?.fund?.id,
                    buyingPrice: Number(values.buyingPrice),
                    purchaseDate: values.purchaseDate,
                    quantity: values.quantity,
                    mode: values.mode,
                };
                console.log(payload.currentPrice)
                const dataToSend = {
                    payload,
                    investmentId: values.investmentId,
                };


                mutate(dataToSend, {
                    onSuccess: (data) => {
                        formik.resetForm();
                        console.log('Successfully submitted:', data);
                        navigate({
                            pathname: '/investment',
                            search: `?tab=${activeTab}`
                        });
                    },
                    onError: (error) => {
                        console.log('Submission error:', error.response?.data || error.message);
                        console.error('Full error object:', error);
                    },
                });

            } catch (error) {
                console.error('An error occurred during submission:', error);
            }
        },
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Mutual Fund</h3>
                <p className="text-b200 small">Update Mutual Fund basic details.</p>
            </div>
            <div className="col-auto">
                <button onClick={handleBackToInveshment} className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </button>
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                            {/* Fund Name (Non-editable) */}
                            <div className="col">
                                <label htmlFor="name" className="small inter-500 text-b200 mb-2">Mutual Fund Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={formik.values.name}
                                    disabled
                                    className="form-control form-control-fin inter-500 bg-light px-3 shadow-sm"
                                    placeholder="Fund Name"
                                />
                            </div>

                            {/* Quantity */}
                            <div className="col">
                                <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                                <input
                                    type="number"
                                    id="quantity"
                                    value={formik.values.quantity}
                                    onBlur={formik.handleBlur('quantity')}
                                    onChange={formik.handleChange('quantity')}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00.00"
                                />
                                {formik.errors.quantity && formik.touched.quantity && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.quantity}</p>
                                )}
                            </div>

                            {/* Mode */}
                            <div className="col">
                                <label htmlFor="mode" className="small inter-500 text-b200 mb-2">Mode</label>
                                <select
                                    id="mode"
                                    value={formik.values.mode}
                                    onBlur={formik.handleBlur('mode')}
                                    onChange={formik.handleChange('mode')}
                                    className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                >
                                    <option value="">--- Select ---</option>
                                    <option value="SIP">SIP</option>
                                    <option value="Lumpsum">LUMSUP</option>

                                </select>
                                {formik.errors.mode && formik.touched.mode && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.mode}</p>
                                )}
                            </div>

                            {/* Buying Price */}
                            <div className="col">
                                <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                                <input
                                    type="number"
                                    id="buyingPrice"
                                    value={formik.values.buyingPrice}  // Check that value is correctly set
                                    onBlur={formik.handleBlur('buyingPrice')}  // Ensure handleBlur is properly connected
                                    onChange={formik.handleChange('buyingPrice')}  // Ensure handleChange is connected
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00.00"
                                />
                                {formik.errors.buyingPrice && formik.touched.buyingPrice && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.buyingPrice}</p>
                                )}
                            </div>

                            {/* Purchase Date */}
                            <div className="col">
                                <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                                <input
                                    type="date"
                                    id="purchaseDate"
                                    value={formik.values.purchaseDate}
                                    onBlur={formik.handleBlur('purchaseDate')}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        const dateParts = value.split('-');
                                        if (dateParts[0] && dateParts[0].length > 4) {
                                            e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                        }

                                        formik.handleChange(e);
                                    }} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="DD-MM-YYYY"
                                />
                                {formik.errors.purchaseDate && formik.touched.purchaseDate && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.purchaseDate}</p>
                                )}
                            </div>

                        </div>

                        {/* Submit Button */}
                        <div className="row mt-4">
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MutualFundsEdit;
