import React, { useEffect, useState } from "react";

import { FinIcon } from "../components/Common";
import SimulatorChart from "../charts/SimulatorChart";
import useGetUserProfile from "../hooks/auth-service/get-user-profile";
import { differenceInYears, parseISO } from "date-fns";
import { useFormik } from "formik";
import { TbPigMoney } from "react-icons/tb";
import { SiShortcut } from "react-icons/si";
import { GoProjectRoadmap } from "react-icons/go";
import useGetPlanningRetirmentTotalAsset from "../hooks/planning/get-planning-retirment-asset";
import { Tooltip } from '@mui/material';
import { formatNumberWithCommas } from "../utils/common";
const Simulator = () => {

    // api 
    const { data: userProfileData, isLoading: userProfileIsLoading, isFetching: userProfileIsFetching } = useGetUserProfile()
    const { data: planningRetirmentTotalAssetData, isLoading: planningRetirmentTotalAssetIsLoading, isFetching: planningRetirmentTotalAssetIsFetching } = useGetPlanningRetirmentTotalAsset()

    // state
    const [userAge, setUserAge] = useState(23);
    const [simulations, setSimulations] = useState([]); // State to store simulations
    console.log("simulations", simulations)

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { currentage: 45, retirementage: 65, lifeexpectancy: 85, currentincome: 1500000, currentinvestment: 11521920, preretirementroi: 12, postretirementroi: 2, inflationrate: 6, estretirementexpenses: '' },
        // validationSchema: addMutualFundsSchema,
        onSubmit: values => {


        }
    })
    const saveSimulation = () => {
        if (simulations.length < 3) {
            setSimulations([...simulations, formik.values]);
        } else {
            alert("You can only save a maximum of 3 simulations.");
        }
    };



    useEffect(() => {
        if (userProfileData?.data && !userProfileIsLoading) {
            formik.setFieldValue('currentage', calculateAge(userProfileData?.data?.result?.dob))
        }

        if (planningRetirmentTotalAssetData?.data && !planningRetirmentTotalAssetIsLoading) {
            formik.setFieldValue('currentinvestment', planningRetirmentTotalAssetData?.data?.result?.totalAssetValue)
        }

    }, [userProfileIsLoading, userProfileData?.data, planningRetirmentTotalAssetData?.data, planningRetirmentTotalAssetIsLoading]);


    function calculateAge(dateString) {
        const birthDate = parseISO(!!dateString ? dateString : '1990-05-06T00:00:00.000Z');
        const currentDate = new Date();
        const age = differenceInYears(currentDate, birthDate);
        return age;
    }

    // Calculate the Retirement Corpus and Projected Corpus
    const retirementCorpus = formik.values.currentinvestment * (1 + formik.values.preretirementroi / 100);
    const projectedCorpus = formik.values.currentincome * (formik.values.retirementage - formik.values.currentage) * (1 + formik.values.inflationrate / 100);

    // Calculate Shortfall or Excess Percentage
    const shortfallOrExcess = ((retirementCorpus - projectedCorpus) / projectedCorpus) * 100;
    const excessShortfallPercentage = shortfallOrExcess.toFixed(2);

    return (<>
        <div className="row row-inner">
            <div className="col-12">
                <div className="row g-2 g-xxl-3">
                    <div className="col">
                        <h3 className="inter-700">Simulator</h3>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex gap-2 gap-xxl-3">
                            <button onClick={() => { formik.resetForm() }} type="button" className="btn btn-normal btn-primary">
                                <FinIcon name="reset" className="ts-1p5" />
                                Reset
                            </button>
                            <button onClick={saveSimulation} type="button" className="btn btn-normal btn-primary">
                                <FinIcon name="save" className="ts-1p5" />
                                Save Simulation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3 mt-md-1">
                <p className="text-b200 small maxw-560px">
                    See how much monthly passive income you can have based on your assets and investments. Simply enter some basic information in our wealth simulator.

                </p>
                <p className="text-b200 small maxw-510px d-flex  fw-bolder">
                    <span className="d-flex align-items-center gap-2">
                        <TbPigMoney />
                        Retirement Corpus: {formatNumberWithCommas(retirementCorpus)}
                    </span>
                   
                </p>

            </div>


        </div>
        <div className="row row-inner">
            <div className="col-md-auto mb-3">
                <div className="simulator-bg minw-md-300 p-3 h-100">
                    <div className="label-withicon mb-3">
                        <label htmlFor="retirementAge">
                            <FinIcon name="pension" className="text-primary" />
                            <span>Current Age</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="retirementAge" placeholder="00" defaultValue="60"
                                value={formik?.values?.currentage}
                                onBlur={formik.handleBlur('currentage')}
                                onInput={formik.handleChange('currentage')}
                            />
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="retirementAge">
                            <FinIcon name="pension" className="text-primary" />
                            <span>Retirement Age</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="retirementAge" placeholder="00" defaultValue="60"
                                value={formik?.values?.retirementage}
                                onBlur={formik.handleBlur('retirementage')}
                                onInput={formik.handleChange('retirementage')}
                            />
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="lifeExpec">
                            <FinIcon name="life" className="text-primary" />
                            <span>Life Expectancy</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="lifeExpec" placeholder="00" defaultValue="60"
                                value={formik?.values?.lifeexpectancy}
                                onBlur={formik.handleBlur('lifeexpectancy')}
                                onInput={formik.handleChange('lifeexpectancy')}
                            />
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="CurrentInvest">
                            <FinIcon name="moneyinvest" className="text-primary" />
                            <Tooltip title="Current Income (Annual Gross Income)" arrow>
                                <span style={{ cursor: 'pointer' }}>Current Income</span>
                            </Tooltip>                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="CurrentInvest" placeholder="00"
                                value={formik?.values?.currentincome}
                                onBlur={formik.handleBlur('currentincome')}
                                onInput={formik.handleChange('currentincome')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">₹</span>
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="CurrentInvest">
                            <FinIcon name="moneyinvest" className="text-primary" />
                            <Tooltip title="Current Investment (Investments tagged for Retirement)" arrow>
                                <span style={{ cursor: 'pointer' }}>Current Investment</span>
                            </Tooltip>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="CurrentInvest" placeholder="00"
                                value={formik?.values?.currentinvestment}
                                onBlur={formik.handleBlur('currentinvestment')}
                                onInput={formik.handleChange('currentinvestment')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">₹</span>
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="preRetirmentROI">
                            <FinIcon name="moneysave" className="text-primary" />
                            <Tooltip title="Pre Retirement ROI (Expected rate of return on investments in %) " arrow>
                                <span style={{ cursor: 'pointer' }}>Pre Retirement ROI</span>
                            </Tooltip>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="preRetirmentROI" placeholder="00"
                                value={formik?.values?.preretirementroi}
                                onBlur={formik.handleBlur('preretirementroi')}
                                onInput={formik.handleChange('preretirementroi')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">%</span>
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="postRetirmentROI">
                            <FinIcon name="moneyreturn" className="text-primary" />
                            <Tooltip title="	Post Retirement ROI (Expected Return on investment in %)" arrow>
                                <span style={{ cursor: 'pointer' }}>Post Retirement ROI</span>
                            </Tooltip>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="postRetirmentROI" placeholder="00" defaultValue="10"
                                value={formik?.values?.postretirementroi}
                                onBlur={formik.handleBlur('postretirementroi')}
                                onInput={formik.handleChange('postretirementroi')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">%</span>
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="inflationRate">
                            <FinIcon name="inflation" className="text-primary" />
                            <span>Inflation Rate</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="inflationRate" placeholder="00" defaultValue="7"
                                value={formik?.values?.inflationrate}
                                onBlur={formik.handleBlur('inflationrate')}
                                onInput={formik.handleChange('inflationrate')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">%</span>
                        </div>
                    </div>
                    <div className="label-withicon mb-3">
                        <label htmlFor="ESTretirementExpense">
                            <FinIcon name="moneysett" className="text-primary" />
                            <Tooltip title="Est Retirement Expenses (Estimated inflation adjusted expenses using income replacement method)" arrow>
                                <span style={{ cursor: 'pointer' }}>EST. Retirement Expenses</span>
                            </Tooltip>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="ESTretirementExpense" placeholder="00" defaultValue="50000"
                                value={formik?.values?.estretirementexpenses}
                                onBlur={formik.handleBlur('estretirementexpenses')}
                                onInput={formik.handleChange('estretirementexpenses')}
                            />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">₹</span>
                        </div>
                    </div>
                    {/* <div className="label-withicon mb-3">
                        <label htmlFor="unexpectedExpense">
                            <FinIcon name="morexpenses" className="text-primary" />
                            <span>Pre Retirement Income Growth</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="unexpectedExpense" placeholder="00" defaultValue="10000" />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">%</span>
                        </div>
                    </div>
                    <div className="label-withicon">
                        <label htmlFor="debtRepay">
                            <FinIcon name="loans" className="text-primary" />
                            <span>Post Retirement Expenses Growth</span>
                        </label>
                        <div className="label-inputwrapper">
                            <input type="text" id="debtRepay" placeholder="00" defaultValue="5000" />
                            <span className="position-absolute top-50 end-0 translate-middle-y pe-none fs-16px">%</span>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="col-md mb-3">
                <div className="simulator-bg p-3 h-100">
                    <SimulatorChart ageuser={formik.values?.currentage} retirementage={formik.values?.retirementage} lifeexpectancy={formik.values?.lifeexpectancy} currentincome={formik.values?.currentincome} currentinvestment={formik.values?.currentinvestment} preretirementroi={formik.values?.preretirementroi} postretirementroi={formik?.values?.postretirementroi} inflationrate={formik.values?.inflationrate} estretirementexpenses={formik?.values?.estretirementexpenses} height="100%" />
                </div>

            </div>
        </div>
    </>)
}
export default Simulator;