import { keepPreviousData, useQuery } from '@tanstack/react-query';

import bankServiceInstance from '../../services/bank-service';

function useGetBankAccounts(data) {
  return useQuery({
    queryKey: [bankServiceInstance.queryKeys.bankAccounts + data],
    queryFn: () => bankServiceInstance.bankAccounts(data),
    placeholderData : keepPreviousData,
    refetchOnWindowFocus: false

    // enabled: !!data,
  });
}

export default useGetBankAccounts;