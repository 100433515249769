import { Link } from "react-router-dom";

const Footer = () => {
    return(
    <div className="fin-footer">
        <div>© 2024 Finactive Inc. All Rights Reserved.</div>
        <div className="fin-footerlink">
            <Link to={''}>Privacy</Link>
            <Link to={''}>Terms of Service</Link>
            <Link to={''}>Help & FAQ</Link>
        </div>
    </div>
    )
}
export default Footer;