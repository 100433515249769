import { useMutation } from '@tanstack/react-query';
import PlanningServiceInstance from '../../services/planning-service';
import { errorToast, successToast } from '../../utils/toastMassage';

function UpdateLinkGoal() {
  return useMutation({
    mutationFn: PlanningServiceInstance.PutLinkGoal,
    onSuccess: () => {
      successToast('Update Successfully');
      // queryClient.invalidateQueries({ queryKey: [PlanningServiceInstance.queryKeys.yourQueryKey] });
    },
    onError: (error) => {
      console.error('Update error:', error);
      errorToast('Something went wrong, please try again later'); 
    },
  });
}

export default UpdateLinkGoal;
