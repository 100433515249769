import React from "react";
// import { Link } from "react-router-dom";
import {FinIcon} from './Common';

const EmailPreferences = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Email Preferences</h3>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-normal btn-light" onClick={()=> props.passingMethod({account_settings: true})}>
                    <FinIcon name="arrowleft2" className="ts-1p4"/> Back
                </button>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    Customize your email preferences.
                </p>
            </div>
            {/* ... */}
            <div className="col col-md-6 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row g-3 g-lg-4">
                        <div className="col">
                            <div className="d-flex flex-column align-items-start gap-2 gap-sm-3 text-b200 checked-medium-text">
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue="" defaultChecked/>
                                    <span className="form-check-label">Daily Email</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue="" defaultChecked/>
                                    <span className="form-check-label">Engagement Communications</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Advisor Communications</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Empower Personal Wealth Communications</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Guides and Information Content</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Unsubscribe from All Emails</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Weekly Email</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Investment Insights</span>
                                </label>
                                <label className="form-check d-inline-flex gap-3">
                                    <input type="checkbox" className="form-check-input rounded-0" defaultValue=""/>
                                    <span className="form-check-label">Wealth Management Communications</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3"/>
                    Save Changes
                </button>
            </div>
        </div>
    )
}
export default EmailPreferences;