import React from "react";
import { Link } from "react-router-dom";
import {FinIcon} from './Common';

const FinancialAccounts = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Financial Accounts</h3>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2 gap-xxl-3">
                    <button type="button" className="btn btn-normal btn-primary">
                        <FinIcon name="addline" className="ts-1p4"/>
                        Add
                    </button>
                    <button type="button" className="btn btn-normal btn-light" onClick={()=> props.passingMethod({account_settings: true})}>
                        <FinIcon name="arrowleft2" className="ts-1p4"/> Back
                    </button>
                </div>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    Administer your financial accounts.
                </p>
            </div>
            {/* ... */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="d-flex justify-content-between gap-2 small inter-500 text-b100">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="images/icici-bank.png" className="mmh-16" alt="bank"/>
                                        <span>ICICI BANK</span>
                                    </div>
                                    <span>19/02/2024</span>
                                </div>
                                <div className="d-flex justify-content-between gap-2 inter-500 text-b300 mt-3">
                                    <span>XXXX XXXX 7467</span>
                                    <Link to={'./'}><FinIcon name="link" className="ts-1p4"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="d-flex justify-content-between gap-2 small inter-500 text-b100">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="images/icici-bank.png" className="mmh-16" alt="bank"/>
                                        <span>ICICI BANK</span>
                                    </div>
                                    <span>19/02/2024</span>
                                </div>
                                <div className="d-flex justify-content-between gap-2 inter-500 text-b300 mt-3">
                                    <span>XXXX XXXX 7467</span>
                                    <Link to={'./'}><FinIcon name="link" className="ts-1p4"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 p-sm-4 shadow-common bg-white rounded-3">
                                <div className="d-flex justify-content-between gap-2 small inter-500 text-b100">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="images/icici-bank.png" className="mmh-16" alt="bank"/>
                                        <span>ICICI BANK</span>
                                    </div>
                                    <span>19/02/2024</span>
                                </div>
                                <div className="d-flex justify-content-between gap-2 inter-500 text-b300 mt-3">
                                    <span>XXXX XXXX 7467</span>
                                    <Link to={'./'}><FinIcon name="link" className="ts-1p4"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3"/>
                    Save Changes
                </button>
            </div>
        </div>
    )
}
export default FinancialAccounts;