import React from "react";
import { Link } from "react-router-dom";

import { FinIcon } from "../components/Common";

const Tax = () => {

    const income = [{
        id: 1,
        date: '29/02/2024',
        details: 'Stock',
        amount: 1000
    }, {
        id: 2,
        date: '01/03/2024',
        details: 'Stock',
        amount: 1000
    }, {
        id: 3,
        date: '02/03/2024',
        details: 'Stock',
        amount: 1000
    }, {
        id: 4,
        date: '03/03/2024',
        details: 'Stock',
        amount: 1000
    }, {
        id: 5,
        date: '04/04/2024',
        details: 'Stock',
        amount: 1000
    }];

    const expenses = [{
        id: 1,
        date: '29/02/2024',
        details: 'Bill',
        amount: 500
    }, {
        id: 2,
        date: '01/03/2024',
        details: 'Bill',
        amount: 500
    }, {
        id: 3,
        date: '02/03/2024',
        details: 'Bill',
        amount: 500
    }, {
        id: 4,
        date: '03/03/2024',
        details: 'Bill',
        amount: 500
    }, {
        id: 5,
        date: '04/04/2024',
        details: 'Bill',
        amount: 500
    }];

    const capitalLedgers = [{
        id: 1,
        date1: '29/02/2024',
        particulars1: 'By Cash Ac.',
        jf1: '',
        debitAmount: '1000',
        date2: '29/02/2024',
        particulars2: 'By Cash Ac.',
        jf2: '',
        creditAmout: '1000'
    },{
        id: 2,
        date1: '01/03/2024',
        particulars1: 'By Cash Ac.',
        jf1: '',
        debitAmount: '1000',
        date2: '01/03/2024',
        particulars2: 'By Cash Ac.',
        jf2: '',
        creditAmout: '1000'
    },{
        id: 3,
        date1: '02/03/2024',
        particulars1: 'By Cash Ac.',
        jf1: '',
        debitAmount: '1000',
        date2: '02/03/2024',
        particulars2: 'By Cash Ac.',
        jf2: '',
        creditAmout: '1000'
    },{
        id: 4,
        date1: '03/03/2024',
        particulars1: 'By Cash Ac.',
        jf1: '',
        debitAmount: '1000',
        date2: '03/03/2024',
        particulars2: 'By Cash Ac.',
        jf2: '',
        creditAmout: '1000'
    },{
        id: 5,
        date1: '04/03/2024',
        particulars1: 'By Cash Ac.',
        jf1: '',
        debitAmount: '1000',
        date2: '04/03/2024',
        particulars2: 'By Cash Ac.',
        jf2: '',
        creditAmout: '1000'
    }]

    return(<>
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Tax</h3>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2 gap-xxl-3">
                    <div className="input-group input-group-datepicker">
                        <input type="text" className="form-control" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                        <FinIcon name="calendar"/>
                        <button className="btn bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                            <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                            <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                            <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                        </ul>
                    </div>
                    <button type="button" className="btn btn-normal btn-light">
                        <FinIcon name="export" className="ts-1p4"/>
                        Export
                    </button>
                    <button type="button" className="btn btn-normal btn-primary">
                        <FinIcon name="addcirlce" className="ts-1p4"/>
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div className="row row-inner g-4 mt-0">
            <div className="col-md-6">
                <div className="d-flex gap-3 justify-content-between mb-3">
                    <h3 className="inter-700 align-self-center m-0">Income</h3>
                    <div className="input-group input-group-datepicker w-auto">
                        <input type="text" className="form-control rounded-10px" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                        <FinIcon name="calendar"/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Details</th>
                                <th width="130">Amount ₹</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            income && income.map((item)=>{
                                return(
                                    <tr key={item.id}>
                                        <td>{item.date}</td>
                                        <td>{item.details}</td>
                                        <td className="text-success300">+₹{item.amount}</td>
                                    </tr>  
                                )
                            })
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2" className="inter-700 h6">Total Income</td>
                                <td className="inter-700 h6">₹5000</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div className="col-md-6">
                <div className="d-flex gap-3 justify-content-between mb-3">
                    <h3 className="inter-700 align-self-center m-0">Expenses</h3>
                    <div className="input-group input-group-datepicker w-auto">
                        <input type="text" className="form-control rounded-10px" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                        <FinIcon name="calendar"/>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Details</th>
                                <th width="130">Amount ₹</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            expenses && expenses.map((item)=>{
                                return(
                                    <tr key={item.id}>
                                        <td>{item.date}</td>
                                        <td>{item.details}</td>
                                        <td className="text-error300">+₹{item.amount}</td>
                                    </tr>  
                                )
                            })
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2" className="inter-700 h6">Total Income</td>
                                <td className="inter-700 h6">₹2500</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div className="row row-inner gy-3 my-4">
            <div className="col align-self-center">
                <h3 className="inter-700 m-0">Capital Ledgers</h3>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2 gap-xxl-3">
                    <div className="input-group input-group-datepicker">
                        <input type="text" className="form-control" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                        <FinIcon name="calendar"/>
                        <button className="btn bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                            <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                            <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                            <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Particulars</th>
                                <th>JF</th>
                                <th width="150">Debit Amount ₹</th>
                                <th>Date</th>
                                <th>Particulars</th>
                                <th>JF</th>
                                <th width="150">Credit Amount ₹</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            capitalLedgers && capitalLedgers.map((item)=>{
                                return(
                                    <tr key={item.id}>
                                        <td>{item.date1}</td>
                                        <td>{item.particulars1}</td>
                                        <td>{item.jf1}</td>
                                        <td className="text-error300">+₹{item.debitAmount}</td>
                                        <td>{item.date2}</td>
                                        <td>{item.particulars2}</td>
                                        <td>{item.jf2}</td>
                                        <td className="text-success300">+₹{item.creditAmout}</td>
                                    </tr>  
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>)
}
export default Tax;
