import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FinIcon } from "./Common";

const Notifications = () => {
    useEffect(()=>{

    },[]);

    return(<>
        <div className="dropdown dropdown-notifications">
            <button type="button" className="btn-bell active" data-bs-toggle="dropdown">
                <FinIcon name="bell" className=""/>
            </button>
            <div className="dropdown-menu dropdown-menu-end shadow">
                <div className="notif-card">
                    <div className="notif-header">
                        <div className="d-flex align-items-center gap-2">
                            <strong>Notifications</strong> 
                            <span>2</span>
                        </div>
                        <button type="button" className="notif-markread">Mark all as read</button>
                    </div>
                    <div className="notif-body">
                        <Link to={''} className="dropdown-item notif-new">
                            <div className="notif-icon">
                                <span className="notif-iconmask">
                                    <img src="/apple-touch-icon.png" alt=""/>
                                </span>
                            </div>
                            <div className="d-block">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum massa in ligula euismod
                                </p>
                                <small>1h ago</small>
                            </div>
                        </Link>
                        <Link to={''} className="dropdown-item notif-new">
                            <div className="notif-icon">
                                <span className="notif-iconmask">
                                    <img src="/apple-touch-icon.png" alt=""/>
                                </span>
                            </div>
                            <div className="d-block">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum massa in ligula euismod
                                </p>
                                <small>2h ago</small>
                            </div>
                        </Link>
                        <Link to={''} className="dropdown-item">
                            <div className="notif-icon">
                                <span className="notif-iconmask">
                                    <img src="/apple-touch-icon.png" alt=""/>
                                </span>
                            </div>
                            <div className="d-block">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                                <small>3h ago</small>
                            </div>
                        </Link>
                        <Link to={''} className="dropdown-item">
                            <div className="notif-icon">
                                <span className="notif-iconmask">
                                    <img src="/apple-touch-icon.png" alt=""/>
                                </span>
                            </div>
                            <div className="d-block">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                                <small>3h ago</small>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Notifications;