import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import { FinIcon } from "../components/Common";

const Header = () => {

    // const navigate = useNavigate()

    useEffect(() => {

    }, []);

    const handleSignOut = () => {
        window.localStorage.removeItem('isLogedIn')
        window.location.href = "/"
    };

    return (<>
        <header className="fin-header">
            <div className="fin-headerleft">
                <span>Good Morning,</span>
                <strong className="inter-800">John</strong>
            </div>
            <div className="fin-headerright">
                <div className="global-search">
                    <FinIcon name="search" className="search-icon" />
                    <input type="text" className="form-control" defaultValue="" placeholder="Search here..." />
                    <kbd className="command-button">
                        <FinIcon name="command" className="command-icon" />
                        <span>K</span>
                    </kbd>
                </div>
                <Notifications />
                <div className="dropdown dropdown-profile">
                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                        <div className="avatar-mask">
                            <img src="images/avatar.png" alt="avatar" />
                        </div>
                        <strong>John D.</strong>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end shadow">
                        <div className="d-flex gap-2 border-bottom p-20px">
                            <img src="images/avatar.png" className="mmwh-48 rounded-pill" alt="avatar" />
                            <div className="ps-1 d-block align-self-center">
                                <h6 className="inter-500 mb-0">John Doe</h6>
                                <a className="text-b100 text-hover-primary fs-12px text-decoration-none mb-0" href="mailto:johndoe123@gmail.com">johndoe123@gmail.com</a>
                            </div>
                        </div>
                        <div className="pt-2 pb-3">
                            <Link to={'./settings'} className="dropdown-item arrow">
                                <FinIcon name="settings" />
                                <span>Account Settings</span>
                            </Link>
                            <Link onClick={handleSignOut} className="dropdown-item">
                                <FinIcon name="bell" />
                                <span>Sign Out</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>)
}
export default Header;