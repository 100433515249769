import Axios from "axios";


export const fetcher = async (config) => {
  const { url, method, data, headers } = config;
  // const { access_token } = getAuthValue()

   
  return await Axios.request({
    baseURL: process.env.REACT_APP_API,
    url,
    method: method ?? 'GET',
    data,
    ...config,
    headers: {
      // Authorization: undefined,
      ...config?.headers,
      ...headers,
    },
    withCredentials : true,
  });
};