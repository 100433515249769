import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";
import { FinIcon } from '../components/Common';
import useEditInvestment from "../hooks/investment/edit-investment";

const stockSchema = Yup.object().shape({
    name: Yup.string().required('Please enter Name.'),
    quantity: Yup.number()
        .typeError('Please enter a valid number')
        .integer('Please enter an integer value')
        .min(1, 'Quantity must be at least 1')
        .required('Please enter integer value.'),
    buyingPrice: Yup.number().required('Please enter Buying Price.'),
    purchaseDate: Yup.string()
        .required('Please enter Purchase Date.')
        .test('valid-date', 'Invalid date', (value) => {
            // Check if the date is valid
            if (!value) return false;
            const date = new Date(value);
            return !isNaN(date.getTime());
        })
        .test('valid-year', 'Year must be a 4-digit number starting with a non-zero digit', (value) => {
            if (!value) return false;

            const year = parseInt(value.split('-')[0], 10);
            return year >= 1000 && year <= 9999 && year.toString()[0] !== '0';
        }),

});

const StockEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state?.item || {};
    const { mutate } = useEditInvestment();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            investmentId: item.investmentId || '',
            name: item?.issuer?.name || '',
            quantity: item?.quantity || '',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: item?.purchasedDate?.split('T')[0] || new Date().toISOString().split('T')[0],
        },
        validationSchema: stockSchema,
        onSubmit: (values) => {
            const payload = {
                companyId: item?.issuer?.id,
                investmentName: values.name,
                buyingPrice: Number(values.buyingPrice),
                purchaseDate: values.purchaseDate,
                quantity: values.quantity,

            };

            const dataToSend = {
                payload,
                investmentId: values.investmentId,
            };

            mutate(dataToSend, {
                onSuccess: (data) => {
                    formik.resetForm();
                    console.log('Successfully submitted:', data);
                    navigate('/investment');
                },
                onError: (error) => {
                    console.log('Submission error:', error.response?.data || error.message);
                    console.error('Full error object:', error);
                },
            });
        },
    });

    const handleBackToInvestment = () => {
        navigate('/investment');
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });
        }, 100);
    };

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Stock</h3>
                <p className="text-b200 small">Update Stock basic details.</p>
            </div>
            <div className="col-auto">
                <button onClick={handleBackToInvestment} className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </button>
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">

                            {/* Stock Name (Read-Only) */}
                            <div className="col">
                                <label className="small inter-500 text-b200 mb-2">Stock Name</label>
                                <input
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    value={formik.values.name}
                                    readOnly
                                />
                            </div>

                            {/* Quantity */}
                            <div className="col">
                                <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00"
                                    min="0"
                                    id="quantity"
                                    name="quantity"
                                    onChange={formik.handleChange}
                                    value={formik.values.quantity}
                                />
                                {formik.errors.quantity && formik.touched.quantity && (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.quantity}
                                    </p>
                                )}
                            </div>

                            {/* Buying Price */}
                            <div className="col">
                                <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00.00"
                                    min="0"
                                    id="buyingPrice"
                                    name="buyingPrice"
                                    onChange={formik.handleChange}
                                    value={formik.values.buyingPrice}
                                />
                                {formik.errors.buyingPrice && formik.touched.buyingPrice && (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.buyingPrice}
                                    </p>
                                )}
                            </div>


                            {/* Purchase Date */}
                            <div className="col">
                                <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="purchaseDate"
                                    name="purchaseDate"
                                    value={formik.values.purchaseDate}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        const dateParts = value.split('-');
                                        if (dateParts[0] && dateParts[0].length > 4) {
                                            e.target.value = `${dateParts[0].substring(0, 4)}-${dateParts[1] || '01'}-${dateParts[2] || '01'}`;
                                        }

                                        formik.handleChange(e);
                                    }} />
                                {formik.errors.purchaseDate && formik.touched.purchaseDate && (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.purchaseDate}
                                    </p>
                                )}
                            </div>

                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                        >
                            <FinIcon name="save" className="ts-1p5 me-3" />
                            Update Stock
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default StockEdit;
