import { fetcher } from "../utils/fetcher";

class PlanningService {
  queryKeys = {
    savePlanningEstimates: 'savePlanningEstimates',
    savePlanningGoals : 'savePlanningGoals',
    getPlanningGoals : 'getPlanningGoals',
    getPlanningEstimates : 'getPlanningEstimates',
    getPlanningExpenses : 'getPlanningExpenses',
    getPlanningSavings : 'getPlanningSavings',
    getPlanningRetirmentTotalAsset : 'getPlanningRetirmentTotalAsset',
    getPlanningRetirmentGraph : 'getPlanningRetirmentGraph',
    getPlanningSimulatorGraph : 'getPlanningSimulatorGraph',
    getPlanningScore : 'getPlanningScore',
    PutPlanningGoal : 'PutPlanningGoal',
    PutLinkGoal : 'PutLinkGoal',
    getEmergencyFund:'getEmergencyFund',
    deleteLinkGoal:'deleteLinkGoal',
  };


  PutPlanningGoal = async (data) => {
    const { payload, goalId } = data;
    return fetcher({
      url: `/planning/goals/${goalId}`, 
      method: 'PUT', 
      data: payload 
    });
  }
  
   PutLinkGoal = async (data) => {
    console.log("data",data)
    const { payload, goalId } = data;
    console.log("data",data)
    return fetcher({
      url: `/planning/goals/${goalId}/link`,
      method: 'POST',
      data: payload,
    });
};

 
 deleteLinkGoal = async (data) => {
  console.log("data",data);
  const { investId, goalId } = data;

  return fetcher({
    url: `/planning/goals/${goalId}/unlink/${investId}`, 
    method: 'DELETE', 
  });
};

  

  savePlanningEstimates = async (data) => {

    return fetcher({
      url: `/planning/estimates`,
      method: 'POST',
      data
    });

  }

  savePlanningGoals = async (data) => {

    return fetcher({
      url: `/planning/goals`,
      method: 'POST',
      data
    });

  }

  getPlanningScore = async () => {

    return fetcher({
      url: `/planning/score`,
      method: 'GET',
    });

  }

  getEmergencyFund = async (data) => {

    return fetcher({
      url: `/planning/emergency-funds`,
      method: 'GET',
    });

  }
  getPlanningGoals = async (data) => {

    return fetcher({
      url: `/planning/goals?linkedInvestments=true`,
      method: 'GET',
    });

  }

  getPlanningEstimates = async (data) => {

    return fetcher({
      url: `planning/estimates?fy=${data}`,
      method: 'GET',
    });

  }

  getPlanningExpenses = async (data) => {

    return fetcher({
      url: `banking/accounting/aggregates?fy=${data}`,
      method: 'GET',
    });

  }

    getPlanningSavings = async (data) => {

    return fetcher({
      url: `investments/savings?fy=${data}`,
      method: 'GET',
    });

  }

  getPlanningRetirmentTotalAsset = async (data) => {

    return fetcher({
      url: `/investments/assets-value`,
      method: 'GET',
    });

  }

  getPlanningRetirmentGraph = async (data) => {
    const {age,simulate}  = data

    return fetcher({
      url: `/planning/retirement-plan?age=${age}&simulate=${simulate}`,
      method: 'GET',
    });
    // &r_age=${r_age}&le=${le}&incm=${incm}&ivst=${ivst}&pre_roi=${pre_roi}&post_roi=${post_roi}&ir=${ir}&est_re=${est_re}
  }

  getPlanningSimulatorGraph = async (data) => {
    const {age,simulate,r_age,le, incm, ivst, pre_roi, post_roi, ir, est_re}  = data

    return fetcher({
      url: `/planning/retirement-plan?age=${age}&simulate=${simulate}&r_age=${r_age}&le=${le}&incm=${incm}&ivst=${ivst}&pre_roi=${pre_roi}&post_roi=${post_roi}&ir=${ir}&est_re=${est_re}`,
      method: 'GET',
    });
  }

  


}

const PlanningServiceInstance = new PlanningService();
export default PlanningServiceInstance;
