import React, {useEffect, useState} from "react";
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

const TimelineChart = ({...props}) => {

    const [loading, setLoading] = useState(true);
    const [checker, setChecker] = useState(false);
    const [data, setData] = useState([]);

    const bankDetailsRecord = {
        series: [
            {
              data: [
                {
                    x: 'Monthly Expenses 8',
                    y: [
                      new Date('2019-03-14').getTime(),
                      new Date('2019-03-16').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 7',
                    y: [
                      new Date('2019-03-12').getTime(),
                      new Date('2019-03-14').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 6',
                    y: [
                      new Date('2019-03-10').getTime(),
                      new Date('2019-03-12').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 5',
                    y: [
                      new Date('2019-03-10').getTime(),
                      new Date('2019-03-12').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 4',
                    y: [
                      new Date('2019-03-08').getTime(),
                      new Date('2019-03-10').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 3',
                    y: [
                      new Date('2019-03-06').getTime(),
                      new Date('2019-03-08').getTime()
                    ]
                },
                {
                    x: 'Monthly Expenses 2',
                    y: [
                        new Date('2019-03-04').getTime(),
                        new Date('2019-03-06').getTime()
                    ]
                }, 
                {
                    x: 'Monthly Expenses 1',
                    y: [
                      new Date('2019-03-02').getTime(),
                      new Date('2019-03-04').getTime()
                    ]
                },
              ]
            }
        ],
        options: {
            chart: {
                height: 120,
                type: 'rangeBar',
                parentHeightOffset: 0,
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              xaxis: {
                type: 'datetime'
            },
            colors:['#6CA1EF'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 0,
                colors: ['#fff']
            },
            grid: {
                show: false,
                padding: { left: 0, right: 0, top: -30, bottom: 0 },
            },
        }
    };

    return (
        <Chart type="rangeBar" 
            width={props.width} 
            height={props.height}
            options={bankDetailsRecord.options} 
            series={bankDetailsRecord.series}
        />
    )    
}

export default TimelineChart;