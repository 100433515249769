import React, { useEffect, useState } from "react";
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import { fetcher } from "../utils/fetcher";

const BankingDonutChart = ({ ...props }) => {

    const [loading, setLoading] = useState(true);
    const [checker, setChecker] = useState(false);
    const [data, setData] = useState([]);

    const bankDetailsRecord = {
        options: {
            chart: {
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: ['A/C Balance', 'Credit Cards', 'Fixed Deposits'],
            colors: ['#F4C678', '#6CA1EF', '#8DEDE2'],
            plotOptions: {
                pie: {
                    donut: {
                        size: '70',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000",
                            },
                            value: {
                                offsetY: 8,
                                show: true,
                                fontSize: "18px",
                                fontWeight: "700",
                                prefix: "Rp ",
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Bank Details",
                                fontSize: "13.5px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;


                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr';
                                        }

                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        }

                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
                                        }

                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`;
                                    }

                                    return ''
                                }
                            }
                        }
                    },
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                colors: ['#fff']
            },
            responsive: [{
                // breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: false
        }
    };

    const acBalanceRecord = {
        // series: [180, 190, 160, 140],
        options: {
            chart: {
                id: 'acBalance_Donut',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: [],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "18px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "A/C Balance",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;


                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr';
                                        }

                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        }

                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
                                        }

                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`;
                                    }
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#F4C678',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    const creditCardRecord = {
        options: {
            chart: {
                id: 'creditCard_Donut',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: [],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "18px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Credit Cards",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;


                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr';
                                        }

                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        }

                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
                                        }

                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`;
                                    }
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#6CA1EF',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    const fixedDepositRecord = {
        options: {
            chart: {
                id: 'fixedDeposit_Donut',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            labels: [],
            plotOptions: {
                pie: {
                    customScale: 1,
                    expandOnClick: true,
                    size: 160,
                    offsetX: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                color: "#000"
                            },
                            value: {
                                offsetY: 1,
                                show: true,
                                fontSize: "18px",
                                fontWeight: "700"
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: "Fixed Deposits",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#777E89",
                                formatter: function (val, chart) {
                                    if (val) {
                                        let num = val.config.series.reduce((a, b) => a + b, 0);
                                        let finalResult;


                                        if (Math.abs(num) >= 10000000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'Cr';
                                        }

                                        else if (Math.abs(num) >= 100000) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
                                        }

                                        else if (Math.abs(num) > 999) {
                                            finalResult = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
                                        }

                                        else {
                                            finalResult = Math.sign(num) * Math.abs(num);
                                        }

                                        return `₹${finalResult}`;
                                    }
                                    return ''
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,
                //colors: ['#fff']
            },
            theme: {
                mode: 'light',
                monochrome: {
                    enabled: true,
                    color: '#8DEDE2',
                    shadeTo: 'light', //dark
                    shadeIntensity: 0.90
                },
            },
            legend: false
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await fetcher({
                    url: `/banking/aggregates`,
                    method: 'GET',
                });
                setData(response);
                setChecker(true);
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();

    }, []);


    useEffect(() => {
        if (checker) {
            ApexCharts.exec('acBalance_Donut', 'updateOptions', {
                labels: data.result.banking.breakup.map((itm) => itm.bankName)
            });
            ApexCharts.exec('creditCard_Donut', 'updateOptions', {
                labels: data.result.creditCard.breakup.map((itm) => itm.issuerName)
            });
            ApexCharts.exec('fixedDeposit_Donut', 'updateOptions', {
                labels: data.result.fixedDeposit.breakup.map((itm) => itm.institution)
            });
        }
    }, [checker]);


    return (<div className="row justify-content-xl-between g-2">
        <div className="col-12 col-md-auto">
            <div className="fin-donutchart">
                {
                    props.chartName === 'Bank Details' &&
                    <Chart type="donut" width={props.height} height={props.height}
                        options={bankDetailsRecord.options}
                        series={
                            loading ? [250, 250, 250] : [
                                data.result.banking.total,
                                data.result.creditCard.total,
                                data.result.fixedDeposit.total]
                        }
                    />
                }
                {
                    props.chartName === 'Credit Cards' &&
                    <Chart type="donut" width={props.height} height={props.height}
                        options={creditCardRecord.options}
                        series={
                            loading ? [250, 250, 250] : (
                                data && data.result.creditCard.breakup.map((itm) => Number(itm.amount))
                            )
                        }
                    />
                }
                {
                    props.chartName === 'Fixed Deposits' &&
                    <Chart type="donut" width={props.height} height={props.height}
                        options={fixedDepositRecord.options}
                        series={
                            loading ? [250, 250, 250] : (
                                data && data.result.fixedDeposit.breakup.map((itm) => Number(itm.amount))
                            )
                        }
                    />
                }
            </div>
        </div>
        <div className="col-12 col-md-auto align-self-center">
            <div className="fin-donutdivider"></div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={acBalanceRecord.options}
                    series={
                        loading ? [250, 250, 250] : (
                            data && data.result.banking.breakup.map((itm) => Number(itm.amount))
                        )
                    }
                />
            </div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={creditCardRecord.options}
                    series={
                        loading ? [250, 250, 250] : (
                            data && data.result.creditCard.breakup.map((itm) => Number(itm.amount))
                        )
                    }
                />
            </div>
        </div>
        <div className="col-6 col-md-auto align-self-center">
            <div className="fin-allfillchart">
                <Chart type="donut" width={162} height={162}
                    options={fixedDepositRecord.options}
                    series={
                        loading ? [250, 250, 250] : (
                            data && data.result.fixedDeposit.breakup.map((itm) => Number(itm.amount))
                        )
                    }
                />
            </div>
        </div>
    </div>
    )
}

export default BankingDonutChart;