import { keepPreviousData, useQuery } from '@tanstack/react-query';

import PlanningServiceInstance from '../../services/planning-service';

function useGetPlanningScore() {
  return useQuery({
    queryKey: [PlanningServiceInstance.queryKeys.getPlanningScore],
    queryFn: PlanningServiceInstance.getPlanningScore,
    refetchOnWindowFocus: false

    // placeholderData : keepPreviousData,
    // enabled: !!data,
  });
}

export default useGetPlanningScore;